import { reactive, toRefs } from 'vue';

const state = reactive({ assets: new Map(), urls: new Map() });

/**
 * Gets the url of the asset, this is needed for dynamic assets names cases, such as DashboardCardSchemes.vue
 * @param {*} path
 * @returns {string}
 */
const getAssetUrl = (path) => {
    if (!state.urls.has(path)) {
        state.urls.set(
            path,
            new URL(`/resources/common/Build/assets/${path}`, import.meta.url)
                .href
        );
    }
    return state.urls.get(path);
};

const fetchAsset = async (path) => {
    const url = getAssetUrl(path);
    const assetValue = {
        isLoading: false,
        content: undefined,
        error: false,
    };
    try {
        const response = await fetch(url);
        if (response.ok) {
            assetValue.content = await response.text();
        } else {
            assetValue.error = true;
        }
    } catch {
        assetValue.error = true;
    }
    state.assets.set(path, assetValue);

    return assetValue;
};

/**
 * Loads the assets from the common assets.
 * The icons are loaded as raw
 * @example
 * // loads /resources/common/Build/assets/icons/search.svg
 * load('icons/search')
 * @example
 * // loads /resources/common/Build/assets/images/address-foodics.png
 * load('images/address-foodics.png')
 * @example
 * // loads /resources/common/Build/assets/images/card-schemes/mastercard.png
 * load('images/card-schemes/mastercard.png')
 *
 * @param {string} path - The asset path.
 * @returns {void}
 */
const loadAsset = async (path) => {
    const asset = state.assets.get(path);
    if (asset?.error) {
        // keep it empty
        return;
    }
    if (asset?.content) {
        return asset;
    }

    if (asset?.isLoading) {
        return asset.isLoading;
    }

    const promise = fetchAsset(path);
    state.assets.set(path, { isLoading: promise });

    return promise;
};

export const useAsset = () => {
    return {
        ...toRefs(state),
        loadAsset,
        getAssetUrl,
    };
};

<script setup>
import { onMounted, ref } from 'vue';

defineProps({ title: { type: String, default: '' } });
const detailsCard = ref();
const hasOddList = ref(false);
onMounted(() => {
    hasOddList.value = Boolean(
        detailsCard.value?.querySelectorAll('.details-card-item').length % 2
    );
});
</script>

<template>
    <div
        ref="detailsCard"
        class="details-card"
        :class="hasOddList ? 'has-odd-list' : 'has-even-list'">
        <div class="card">
            <div class="card-body rounded-lg">
                <h1 v-if="title" class="text-title-1 mb-4 text-gray-1000">
                    {{ title }}
                </h1>
                <div class="flex flex-wrap">
                    <slot />
                </div>
                <div>
                    <slot name="footer" />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.details-card.has-even-list .details-card-item:nth-last-child(-n + 2) {
    @apply lg:pb-0 lg:mb-0 lg:border-0;
}

.details-card-item:nth-last-child(1) {
    @apply pb-0 mb-0 border-0;
}
</style>

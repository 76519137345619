const TerminalReconList = () =>
    import('../Screens/TerminalRecon/List/TerminalReconList.vue');
const TerminalReconView = () =>
    import('../Screens/TerminalRecon/View/TerminalReconView.vue');

export default [
    {
        path: '/terminal-recon',
        meta: {
            activeSidebarItemId: 'terminal-recon',
            requireAuth: true,
            requireApproval: true,
        },
        children: [
            {
                path: '',
                name: 'terminal_recon',
                component: TerminalReconList,
                meta: {
                    mixpanelPageName: 'Terminal Reconciliations List',
                    pageTitle: 'terminal_recon_list',
                },
            },
            {
                path: ':id',
                name: 'terminal-recon-view',
                component: TerminalReconView,
                props: true,
                meta: {
                    mixpanelPageName: 'Terminal Reconciliation Details',
                    pageTitle: 'terminal_recon_details',
                },
            },
        ],
    },
];

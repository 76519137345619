import { getEnumObject } from '../utilities.js';

export const mmsDashboardTransactionStatuses = {
    NEW: 1,
    PENDING: 2,
    CANNOT_BE_SETTLED: 3,
    SETTLEMENT_FAILED: 4,
    SETTLED: 5,
    SUBMITTED: 6,
};

export default getEnumObject(mmsDashboardTransactionStatuses);

import { getEnumObject } from '../utilities.js';

export const merchantBankAccountStatuses = {
    PENDING_VERIFICATION: 1,
    VERIFICATION_IN_PROGRESS: 2,
    VERIFIED: 3,
    UNVERIFIED: 4,
};
export const bankAccountStatusClass = {
    [merchantBankAccountStatuses.PENDING_VERIFICATION]:
        'border-blue-500 text-blue-500',
    [merchantBankAccountStatuses.VERIFICATION_IN_PROGRESS]:
        'border-yellow-500 text-yellow-500',
    [merchantBankAccountStatuses.VERIFIED]: 'border-green-500 text-green-500',
    [merchantBankAccountStatuses.UNVERIFIED]: 'border-red-500 text-red-500',
};

export const statusTextMap = {
    [merchantBankAccountStatuses.PENDING_VERIFICATION]: 'Pending',
    [merchantBankAccountStatuses.VERIFICATION_IN_PROGRESS]: 'In Progress',
    [merchantBankAccountStatuses.VERIFIED]: 'Verified',
    [merchantBankAccountStatuses.UNVERIFIED]: 'Unverified',
};

export const statusTooltip = {
    [merchantBankAccountStatuses.PENDING_VERIFICATION]:
        'This account has never been verified before',
    [merchantBankAccountStatuses.VERIFICATION_IN_PROGRESS]: 'Check back later',
    [merchantBankAccountStatuses.VERIFIED]: 'Last Verified ',
    [merchantBankAccountStatuses.UNVERIFIED]: 'IBAN and CR/UNN are not linked',
};

export default getEnumObject(merchantBankAccountStatuses);

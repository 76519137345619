<script setup>
import { computed, ref, watch } from 'vue';
import { useAsset } from '@/common/Composables/useAsset';
import FdxSkeletonElement from './FdxSkeletonElement.vue';

const props = defineProps({
    icon: {
        type: String,
        default: null,
    },
    classes: {
        type: String,
        default: 'svg-container flex items-center justify-center',
    },
});

const { loadAsset } = useAsset();
const asset = ref({});

const svgIconName = computed(() => `icons/${props.icon}.svg`);

const svgContent = computed(() => asset.value?.content);

watch(
    svgIconName,
    (newValue) => {
        loadAsset(newValue).then((assetResponse) => {
            asset.value = assetResponse;
        });
    },
    { immediate: true }
);
</script>

<template>
    <!-- eslint-disable vue/no-v-html -->

    <FdxSkeletonElement v-if="!svgContent" :class="[classes]" />
    <span v-else :class="[classes, 'svg-icon-container']" v-html="svgContent" />
</template>

<style scoped>
.svg-container {
    display: inline-flex;
}
</style>

<script>
import { camelCase } from 'lodash';
import FdxFormItemContainer from './FdxFormItemContainer.vue';

export default {
    name: 'FdxCheckbox',

    components: {
        FdxFormItemContainer,
    },

    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        labelLink: {
            type: String,
            default: '',
        },
        largeSize: {
            type: Boolean,
        },
        helpText: {
            type: String,
            default: '',
        },
        validations: {
            type: Object,
            default: null,
        },
        isDisabled: {
            type: Boolean,
        },
    },

    emits: ['update:modelValue'],

    methods: {
        toggleSelection() {
            if (this.isDisabled) {
                return;
            }
            this.$emit('update:modelValue', !this.modelValue);
        },
        camelCase,
    },
};
</script>

<template>
    <fdx-form-item-container
        v-slot="{ isFieldNotValidAndTouched }"
        :field-validations="validations"
        :is-disabled
        hide-required-asterisk>
        <div class="flex items-center">
            <label
                class="flex items-center cursor-pointer"
                :class="{ invalid: isFieldNotValidAndTouched }"
                @click.stop="toggleSelection">
                <div
                    class="checkbox-item border bg-white rounded cursor-pointer hover:border-primary p-1"
                    :class="{
                        'border-primary': modelValue,
                        'border-gray-400': !modelValue,
                    }"
                    :data-testid="camelCase(label)">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        class="fill-primary"
                        :class="{
                            invisible: !modelValue,
                            'w-3 h-3': !largeSize,
                            'w-6 h-6': largeSize,
                        }">
                        <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"></path>
                    </svg>
                </div>

                <a
                    v-if="labelLink"
                    class="mx-2 underline font-normal hover:text-primary"
                    :href="labelLink"
                    target="_blank">
                    {{ $t(label) }}
                </a>
                <span v-else-if="label" class="mx-2">
                    {{ $t(label) }}
                </span>
            </label>

            <tooltip v-if="helpText" class="mx-2" :help-text />
        </div>
    </fdx-form-item-container>
</template>

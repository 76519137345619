import { getEnumObject } from '../utilities.js';

export const auditableTypes = {
    ACCOUNT_STATEMENT: 'AccountStatement',
    ADJUSTMENT: 'Adjustment',
    BANK_CHANNEL: 'BankChannel',
    BANK: 'Bank',
    BRANCH: 'Branch',
    CARD_SCHEME_FEE: 'CardSchemeFee',
    CHANNEL: 'Channel',
    DEVICE: 'Device',
    FOODICS_USER: 'FoodicsUser',
    MERCHANT_BANK_ACCOUNT: 'MerchantBankAccount',
    MERCHANT: 'Merchant',
    MERCHANT_USER: 'MerchantUser',
    PAYMENT: 'Payment',
    SUBSCRIPTION: 'Subscription',
    TERMINAL: 'Terminal',
    TRANSACTION: 'Transaction',
};

export default getEnumObject(auditableTypes);

<template>
    <section class="main-section">
        <slot />
    </section>
</template>

<style scoped>
.main-section {
    padding: var(--cp-main-section-padding);
}
</style>

import { computed } from 'vue';

/**
 * checks if the current locale is arabic
 * @returns Ref<boolean>
 *
 * @example
 * ```js
 * const isArabic = useIsArabic();
 * console.log(isArabic.value);
 * ```
 */
export const useIsArabic = () => {
    return computed(() => {
        return localStorage.getItem('locale') === 'ar';
    });
};

<script setup>
import { onMounted, onUnmounted, useTemplateRef } from 'vue';
const observerEl = useTemplateRef('observer-el');
const { observerOptions, disabled } = defineProps({
    observerOptions: {
        type: Object,
        default: () => ({
            rootMargin: '1px',
            threshold: 1.0,
        }),
    },
    disabled: {
        type: Boolean,
    },
});
const emit = defineEmits(['intersected']);
const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting && !disabled) {
            emit('intersected');
        }
    });
}, observerOptions);
onMounted(() => {
    if (observerEl.value) {
        observer.observe(observerEl.value);
    }
});
onUnmounted(() => {
    observer.disconnect();
});
</script>
<template>
    <div ref="observer-el">
        <slot></slot>
    </div>
</template>

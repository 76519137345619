import { getEnumObject } from '../utilities.js';

export const deviceVendors = {
    INTERSOFT: 1,
    ITS: 2,
    ALHAMRANI: 3,
    MOMKN: 4,
    FOODICS_ONLINE: 5,
    NEARPAY: 6,
    NEOLEAP: 7,
};

export default getEnumObject(deviceVendors);

<script>
import TimeSlotFields from './TimeSlotFields.vue';
export default {
    components: { TimeSlotFields },
    props: {
        modelValue: {
            type: [Array, String],
            default: null,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            slots: [],
            isSlotBeingAdded: false,
            slotIndexBeingEdited: null,
        };
    },
    watch: {
        slots() {
            this.$emit('update:modelValue', this.slots);
        },
        modelValue(value) {
            if (value) {
                this.slots = value;
            }
        },
    },
};
</script>
<template>
    <div class="input p-2">
        <div class="flex justify-between items-center">
            <span v-if="slots.length === 0 && !isSlotBeingAdded">
                {{ $t('Add Available Reservation Times') }}
            </span>
            <div v-else class="flex">
                <div
                    v-for="(slot, index) in slots"
                    :key="index"
                    @click="
                        () => {
                            slotIndexBeingEdited = index;
                            isSlotBeingAdded = false;
                        }
                    ">
                    <div v-if="slotIndexBeingEdited === index">
                        <time-slot-fields
                            :edit-slot="slots[slotIndexBeingEdited]"
                            @added="
                                (payload) => {
                                    slots.splice(
                                        slotIndexBeingEdited,
                                        1,
                                        payload
                                    );
                                    slotIndexBeingEdited = null;
                                }
                            "
                            @delete="
                                () => {
                                    slots.splice(slotIndexBeingEdited, 1);
                                    slotIndexBeingEdited = null;
                                }
                            "
                            @cancel="() => (slotIndexBeingEdited = null)" />
                    </div>
                    <div
                        v-else
                        class="bg-white border border-primary px-2 py-1 rounded-lg mx-1 inline-block cursor-pointer">
                        {{ slot }}
                    </div>
                </div>
                <time-slot-fields
                    v-if="isSlotBeingAdded"
                    @added="
                        (payload) => {
                            slots = [...slots, payload];
                            isSlotBeingAdded = false;
                        }
                    "
                    @delete="() => (isSlotBeingAdded = false)"
                    @cancel="() => (isSlotBeingAdded = false)" />
            </div>
            <div class="flex">
                <button
                    v-if="slots.length < 3"
                    class="btn-sm btn-white ms-auto"
                    @click="() => (isSlotBeingAdded = true)">
                    +
                </button>
            </div>
        </div>
    </div>
</template>

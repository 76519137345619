import { getEnumObject } from '../utilities.js';

export const businessTypes = {
    FREELANCER: 1,
    INDIVIDUAL_RETAILER: 2,
    COMPANY: 3,
    FOREIGN_INVESTMENT: 4,
};

export default getEnumObject(businessTypes);

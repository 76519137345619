<script>
export default {
    props: {
        selected: {
            type: Boolean,
        },
        largeSize: {
            type: Boolean,
        },
    },
};
</script>

<template>
    <div
        class="checkbox-item border bg-white rounded cursor-pointer hover:border-primary p-1"
        :class="{ 'border-primary': selected, 'border-gray-400': !selected }">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            class="fill-primary"
            :class="{
                invisible: !selected,
                'w-3 h-3': !largeSize,
                'w-6 h-6': largeSize,
            }">
            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"></path>
        </svg>
    </div>
</template>

<style>
.checkbox-item {
    display: flex;
    transition: all 0.1s ease-in-out;
}
</style>

<template>
    <div class="relative overflow-hidden">
        <div ref="scroll-container-ref" v-bind="props.containerAttributes">
            <span
                class="absolute z-[2] top-0 start-[-2px] h-full max-h-full w-1/5 duration-300 from-white via-to-transparent to-transparent select-none pointer-events-none"
                :class="[
                    {
                        'opacity-100': fadeStartVisible,
                        'opacity-0': !fadeStartVisible,
                    },
                    isRTL ? 'bg-gradient-to-l' : 'bg-gradient-to-r',
                ]"></span>
            <span
                class="absolute z-[2] top-0 end-[-2px] h-full max-h-full w-1/5 duration-300 from-white to-transparent via-transparent select-none pointer-events-none"
                :class="[
                    {
                        'opacity-100': fadeEndVisible,
                        'opacity-0': !fadeEndVisible,
                    },
                    isRTL ? 'bg-gradient-to-r' : 'bg-gradient-to-l',
                ]"></span>

            <slot />
        </div>
    </div>
</template>
<script setup>
import {
    ref,
    onMounted,
    onUnmounted,
    useTemplateRef,
    unref,
    useSlots,
    computed,
} from 'vue';
import { useIsArabic } from '@/common/Composables/useIsArabic';
import { throttle } from 'lodash';

const props = defineProps({
    /**
     * Additional attributes for the container element
     */
    containerAttributes: {
        type: Object,
        default: () => ({
            class: 'flex gap-4 items-center overflow-x-auto max-w-full w-full',
        }),
    },
    maxIterations: {
        type: Number,
        default: 100,
    },
});
const slots = useSlots();

const defaultScrollContainer = useTemplateRef('scroll-container-ref');
const fadeStartVisible = ref(false);
const fadeEndVisible = ref(false);
const isRTL = unref(useIsArabic());
const defaultSlot = slots.default?.()?.[0];
let scrollTargetChild = defaultSlot;
let iterationCount = 0;
const scrollContainer = computed(() => {
    // the loop should only run once
    while (
        scrollTargetChild &&
        !scrollTargetChild.props?.['scroll-container-ref'] &&
        iterationCount < props.maxIterations
    ) {
        scrollTargetChild = scrollTargetChild.children?.find(
            (child) => child.props?.['scroll-container-ref']
        );
        iterationCount++;

        if (iterationCount === props.maxIterations) {
            throw new Error(
                'Max iterations reached while searching for scroll-container-ref'
            );
        }
    }

    if (!scrollTargetChild) {
        return defaultScrollContainer.value;
    }

    return document.querySelector(
        `[scroll-container-ref="${scrollTargetChild.props?.['scroll-container-ref']}"]`
    );
});

const updateFadeVisibility = throttle(() => {
    if (!scrollContainer.value) {
        return;
    }

    const maxScrollPosition = Math.max(
        0,
        scrollContainer.value.scrollWidth - scrollContainer.value.clientWidth
    );
    const currentScrollPosition = Math.min(
        Math.abs(scrollContainer.value.scrollLeft),
        maxScrollPosition
    ); // in arabic, the scrollLeft is negative

    fadeStartVisible.value = currentScrollPosition > 5; // 5px is the threshold to start fading (nicer UX)
    fadeEndVisible.value = !(currentScrollPosition + 5 >= maxScrollPosition);
}, 1000 / 60);

onMounted(() => {
    window.addEventListener('resize', updateFadeVisibility);
    scrollContainer.value?.addEventListener('scroll', updateFadeVisibility);
    updateFadeVisibility();
});
onUnmounted(() => {
    window.removeEventListener('resize', updateFadeVisibility);
    scrollContainer.value?.removeEventListener('scroll', updateFadeVisibility);
});
</script>

<script>
import moment from 'moment';
import { Bus } from '../bus';
export default {
    props: {
        editSlot: {
            type: String,
            default: '',
        },
    },
    emits: ['added', 'delete', 'cancel'],
    data() {
        return {
            slot1Hour: '00',
            slot1Min: '00',
            slot2Hour: '00',
            slot2Min: '00',
            shouldWatch: true,
            firstClickOutside: true,
        };
    },
    watch: {
        slot1Hour(value) {
            this.slotWatcher(
                value,
                true,
                () => {
                    this.selectText('slot1Min');
                },
                () => {
                    this.slot1Hour = '00';
                    this.selectText('slot1Hour');
                }
            );
        },
        slot1Min(value) {
            this.slotWatcher(
                value,
                false,
                () => {
                    this.selectText('slot2Hour');
                },
                () => {
                    this.slot1Min = '00';
                    this.selectText('slot1Min');
                }
            );
        },
        slot2Hour(value) {
            this.slotWatcher(
                value,
                true,
                () => {
                    this.selectText('slot2Min');
                },
                () => {
                    this.slot2Hour = '00';
                    this.selectText('slot2Hour');
                }
            );
        },
        slot2Min(value) {
            this.slotWatcher(
                value,
                false,
                () => {
                    if (
                        this.isTimeValid(
                            `${this.slot1Hour}:${this.slot1Min}`,
                            `${this.slot2Hour}:${this.slot2Min}`
                        )
                    ) {
                        this.saveSlotBeingAdded();
                    } else {
                        this.selectText('slot2Min');
                    }
                },
                () => {
                    this.slot2Min = '00';
                    this.selectText('slot2Min');
                }
            );
        },
    },
    created() {
        if (this.editSlot) {
            this.shouldWatch = false;
            this.editSlot.split(' - ').forEach((part, index) => {
                [this[`slot${index + 1}Hour`], this[`slot${index + 1}Min`]] =
                    part.split(':');
            });
            this.$nextTick(() => (this.shouldWatch = true));
        }
        this.selectText('slot1Hour');
        Bus.$on('reservations_save', this.attemptToSave);
    },
    methods: {
        isSlotValid(value, isHour) {
            value = parseInt(value);
            if (
                isNaN(value) ||
                value < 0 ||
                (isHour && value > 23) ||
                (!isHour && value > 59)
            ) {
                return false;
            }
            return true;
        },

        slotWatcher(value, isHour, validCallback, invalidCallback) {
            if (value.length < 2 || !this.shouldWatch) {
                return;
            }
            if (value.length === 2 && this.isSlotValid(value, isHour)) {
                validCallback();
            } else {
                invalidCallback();
            }
        },

        selectText(ref) {
            this.$nextTick(() => {
                if (this.$refs[ref]) {
                    this.$refs[ref].select();
                }
            });
        },

        isTimeValid(start, end) {
            const startMoment = moment(start, 'HH:mm', true);
            const endMoment = moment(end, 'HH:mm', true);

            // if (['00', '01', '02', '03', '04'].includes(endMoment.format('HH'))){ // handle day overlap
            //     endMoment.add(1, 'days');
            // }

            // if (['00', '01', '02', '03', '04'].includes(startMoment.format('HH'))){ // handle day overlap
            //     startMoment.add(1, 'days');
            // }

            return (
                startMoment.isValid() &&
                endMoment.isValid() &&
                startMoment.isBefore(endMoment) &&
                !startMoment.isSame(endMoment)
            );
        },

        attemptToSave() {
            if (
                this.isTimeValid(
                    `${this.slot1Hour}:${this.slot1Min}`,
                    `${this.slot2Hour}:${this.slot2Min}`
                )
            ) {
                this.saveSlotBeingAdded();
                this.firstClickOutside = true;
            }
        },
        saveSlotBeingAdded() {
            this.$emit(
                'added',
                `${this.slot1Hour}:${this.slot1Min} - ${this.slot2Hour}:${this.slot2Min}`
            );
            this.$nextTick(() => {
                this.slot1Hour = '00';
                this.slot1Min = '00';
                this.slot2Hour = '00';
                this.slot2Min = '00';
            });
        },

        clickOutside() {
            if (this.firstClickOutside) {
                this.firstClickOutside = false;
                return;
            }
            this.attemptToSave();
        },
    },
};
</script>
<template>
    <div
        v-click-outside="clickOutside"
        class="bg-white border border-secondary px-2 py-1 rounded-lg mx-1 inline-block cursor-pointer relative">
        <button
            class="time-slot-close hover:text-red-600"
            @click.stop="
                () => {
                    $emit('delete');
                }
            ">
            X
        </button>
        <input
            ref="slot1Hour"
            v-model="slot1Hour"
            class="time-slot-input"
            type="text"
            @focus="$event.target.select()"
            @keydown.enter.stop="attemptToSave"
            @keydown.esc.stop="$emit('cancel')"
            @keydown.right="selectText('slot1Min')"
            @keydown.left="selectText('slot2Min')" />
        <span>:</span>
        <input
            ref="slot1Min"
            v-model="slot1Min"
            class="time-slot-input"
            type="text"
            @focus="$event.target.select()"
            @keydown.enter.stop="attemptToSave"
            @keydown.esc.stop="$emit('cancel')"
            @keydown.right="selectText('slot2Hour')"
            @keydown.left="selectText('slot1Hour')" />

        <span>-</span>

        <input
            ref="slot2Hour"
            v-model="slot2Hour"
            class="time-slot-input"
            type="text"
            @focus="$event.target.select()"
            @keydown.enter.stop="attemptToSave"
            @keydown.esc.stop="$emit('cancel')"
            @keydown.right="selectText('slot2Min')"
            @keydown.left="selectText('slot1Min')" />
        <span>:</span>
        <input
            ref="slot2Min"
            v-model="slot2Min"
            class="time-slot-input"
            type="text"
            @focus="$event.target.select()"
            @keydown.enter.stop="attemptToSave"
            @keydown.esc.stop="$emit('cancel')"
            @keydown.right="selectText('slot1Hour')"
            @keydown.left="selectText('slot2Hour')" />
    </div>
</template>

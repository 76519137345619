const AccountStatementContainer = () =>
    import('../Screens/AccountStatement/AccountStatementContainer.vue');

export default [
    {
        path: '/account-statement',
        name: 'account-statement',
        component: AccountStatementContainer,
        meta: {
            requireAuth: true,
            requireApproval: true,
            mixpanelPageName: 'Account Statement',
            activeSidebarItemId: 'account_statement',
            pageTitle: 'account_statement',
        },
    },
];

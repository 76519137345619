<script>
import { pick, isEmpty, debounce, cloneDeep } from 'lodash';
import NProgress from 'nprogress';
import useVuelidate from '@vuelidate/core';
import { scrollToTop } from '../utils/dom';
import { getTopLevelDiffKeys } from '../utils/object';
import { Bus } from '../bus';

export default {
    props: {
        // this is the full object of entity data. Used specially for edit
        formValues: {
            type: Object,
            default() {
                return {};
            },
        },
        // To add pre-selected values to the form fields to avoid removing when comparing to get differences
        // and if there are data in the preselected object, submit button will be enabled by default when form modal is open
        preSelected: {
            type: Object,
            default() {
                return {};
            },
        },
        // add more additional Data to request payload before submit
        supplementRequestData: {
            type: Object,
            default() {
                return {};
            },
        },
        // used to format any field value, example:trim, casting, etc
        fieldValueFormatter: {
            type: Function,
            default(fields) {
                return fields;
            },
        },
        requestType: {
            type: String,
            default: 'post',
        },

        title: { type: String, default: '' },
        widthClass: {
            default: 'max-w-lg',
            type: String,
        },
        url: { type: [String, Function], default: '' },
        namespace: { type: String, default: '' },
        showSaveButton: {
            type: Boolean,
            default: true,
        },
        forceHideTooltip: {
            type: Boolean,
        },
        saveButtonText: {
            type: String,
            default: 'button_label.save',
        },
        propErrors: {
            type: Object,
            default() {
                return {};
            },
        },
        errorsFormatter: {
            type: Function,
            default(errors) {
                return errors;
            },
        },
        validator: {
            type: Function,
            default() {
                return true;
            },
        },
        validationsRules: {
            type: Object,
            default() {
                return {};
            },
        },
        // Used only to force loading state
        loading: {
            type: Boolean,
        },
        saveButtonCentered: { type: Boolean },
        saveButtonEnd: { type: Boolean },
        saveButtonWide: { type: Boolean },
        saveButtonWideFull: { type: Boolean },
        disableSaveBtn: { type: Boolean },
        payloadMapper: {
            type: Function,
            default: (payload) => payload,
        },
    },
    emits: ['fields-updated', 'submit', 'success'],
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            fields: {
                ...this.validationsRules,
            },
        };
    },
    data() {
        return {
            fields: {},
            errors: {},
            saving: false,
        };
    },
    computed: {
        allErrors() {
            return { ...this.errors, ...this.propErrors };
        },
        changedFields() {
            return pick(
                this.fieldValueFormatter({ ...this.fields }),
                getTopLevelDiffKeys(this.formValues, this.fields)
            );
        },
        showTooltip() {
            return isEmpty(this.changedFields);
        },
    },
    watch: {
        fields: {
            deep: true,
            handler: debounce(function (value) {
                this.$emit('fields-updated', { payload: value });
            }, 300),
        },
    },
    created() {
        this.fields = {
            ...cloneDeep(this.formValues),
            ...this.preSelected,
        };
    },
    mounted() {
        if (this.namespace) {
            Bus.$on(this.namespace + ':errors', ({ errors }) => {
                this.saving = false;
                NProgress.done();
                this.errors = errors;
                scrollToTop('form-box-body');
            });
        }
        Bus.$on('enter-key-down-on-input', () => this.save());
        Bus.$on('form-modal:stop-saving', () => {
            this.saving = false;
            NProgress.done();
        });
        Bus.$on('form-modal:start-saving', () => {
            this.saving = true;
        });
        Bus.$on('form-modal:clear-errors', () => {
            this.errors = {};
        });
    },
    beforeUnmount() {
        Bus.$off('enter-key-down-on-input');
        Bus.$off('form-modal:stop-saving');
        Bus.$off('form-modal:start-saving');
        Bus.$off('form-modal:clear-errors');
    },
    methods: {
        async save() {
            const result = await this.v$.$validate();
            this.v$.$touch();
            if (this.saving || !result) {
                return;
            }

            const payload = {
                ...this.changedFields,
                ...this.supplementRequestData,
            };

            if (!this.validator(payload)) {
                return;
            }

            if (!this.url) {
                this.$emit('submit', payload);
                return;
            }

            NProgress.start();
            this.saving = true;
            const http = this.http();
            return http[this.requestType](`${this.url}`, {
                ...this.payloadMapper(payload),
            })
                .then((response) => {
                    this.errors = {};
                    this.$emit('success', response);
                })
                .catch((error) => {
                    this.errors = error.response?.data?.errors ?? {
                        message: error.response?.data?.message,
                    };
                })
                .finally(() => {
                    this.saving = false;
                    NProgress.done();
                });
        },
    },
};
</script>
<template>
    <div
        id="form-box-body"
        data-testid="formBoxBody"
        class="w-full"
        :title="$t(title)">
        <div>
            <error-messages :errors="allErrors" />

            <slot name="content" :fields :vuelidate-obj="v$" />
        </div>

        <div
            :class="{
                'flex justify-center': saveButtonCentered,
                'flex justify-end': saveButtonEnd,
            }">
            <tooltip
                v-if="showTooltip && showSaveButton && !forceHideTooltip"
                :help-text="$t('required_add_or_updated_one_field')"
                disabled>
                <button
                    v-if="showSaveButton"
                    class="btn btn-primary"
                    :class="{
                        'btn-invalid': v$.$invalid,
                        'w-40': saveButtonWide,
                        'w-full': saveButtonWideFull,
                    }"
                    :disabled="saving || loading || disableSaveBtn">
                    <div
                        v-if="saving || loading"
                        class="flex flex-col items-center justify-center h-6">
                        <svg-icon class="w-6 stroke-gray" icon="loader" />
                    </div>
                    <span v-else> {{ $t(saveButtonText) }} </span>
                </button>
            </tooltip>
            <template v-else>
                <button
                    v-if="showSaveButton"
                    class="btn btn-primary"
                    :class="{
                        'btn-invalid': v$.$invalid,
                        'w-40': saveButtonWide,
                        'w-full': saveButtonWideFull,
                    }"
                    :disabled="saving || loading || disableSaveBtn"
                    @click="save">
                    <div
                        v-if="saving || loading"
                        class="flex flex-col items-center justify-center h-6">
                        <svg-icon class="w-6 stroke-gray" icon="loader" />
                    </div>
                    <span v-else> {{ $t(saveButtonText) }} </span>
                </button>
            </template>
        </div>
    </div>
</template>

import { getEnumObject } from '../utilities.js';

export const subAdjustmentStatuses = {
    LINKED: 1,
    APPLIED: 2,
};

export const subAdjustmentStatusesColors = {
    [subAdjustmentStatuses.LINKED]: 'bg-amber-500',
    [subAdjustmentStatuses.APPLIED]: 'bg-green-700',
};

export default getEnumObject(subAdjustmentStatuses);

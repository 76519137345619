import moment from 'moment-timezone';
import months from '../Enums/months';

export function stringifyDate(time, format = 'YYYY-MM-DD') {
    const year = time.getFullYear();
    const month = time.getMonth() + 1;
    const date = time.getDate();
    const monthName = months[time.getMonth()];

    const map = {
        YYYY: year,
        MMM: monthName,
        MM: ('0' + month).slice(-2),
        M: month,
        DD: ('0' + date).slice(-2),
        D: date,
    };
    return format.replace(/Y+|M+|D+/g, function (str) {
        return map[str];
    });
}

export function formatDate(value, format = 'YYYY-MM-DD', local = false) {
    if (!value) {
        return '—';
    }

    if (local) {
        return moment(value).format(format);
    }

    const UTCDate = moment.utc(value).toDate();
    return moment(UTCDate).local().format(format);
}

const TransactionsList = () =>
    import('../Screens/Transactions/List/TransactionsList.vue');
const TransactionView = () =>
    import('../Screens/Transactions/View/TransactionView.vue');

export default [
    {
        path: '/transactions',
        meta: {
            requireAuth: true,
            requireApproval: true,
            activeSidebarItemId: 'transactions',
        },
        children: [
            {
                path: '',
                name: 'transactions',
                component: TransactionsList,
                meta: {
                    mixpanelPageName: 'Transactions List',
                    pageTitle: 'transactions_list',
                },
            },
            {
                path: ':id',
                name: 'transaction-view',
                component: TransactionView,
                props: true,
                meta: {
                    mixpanelPageName: 'Transaction Details',
                    pageTitle: 'transaction_details',
                },
            },
        ],
    },
];

import enums from '@/common/Enums/index.js';
import { useI18n } from 'vue-i18n';

export const useMapEnumValue = () => {
    const { t } = useI18n();
    return {
        mapEnumValue: (value, type) => {
            if (value === null || value === undefined || !type) {
                return '—';
            } else {
                const enumKey = enums[type]?.[value];
                return enumKey ? t(`enums.${type}.${enumKey}`) : value;
            }
        },
    };
};

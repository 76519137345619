<script>
import moment from 'moment';
import TimePicker from './TimePicker.vue';
import { extendMoment } from 'moment-range';
import { clone } from 'lodash';
import { alertError } from '../utils/alert';
import { stringifyDate } from '../utils/date';
import { Bus } from '../bus';
import { useIsArabic } from '../Composables/useIsArabic';
import enums from '../Enums/index.js';
/**
 * src: https://github.com/8788/vue-date-picker
 */
export default {
    components: { TimePicker },
    props: {
        readonly: { type: Boolean, default: true },
        modelValue: { type: [String, Date], default: '' },
        format: { type: String, default: 'YYYY-MM-DD' },
        name: { type: String, default: '' },
        inputClass: { type: String, default: 'input p-2' },
        disabledDate: { type: Function, default: () => false },
        triggerElement: { type: String, default: 'input' },
        isRange: { type: Boolean },
        hasPreselected: { type: Boolean },
        isDateTime: { type: Boolean },
        groupBy: { type: String, default: 'day' },
        shouldRestLeft: { type: Boolean },
        onlyFutureDates: { type: Boolean },
        initialTime: { type: String, default: '' },
        placeholder: { type: String, default: '' },
        customStyle: {
            type: Object,
            default: null,
        },
        setMiddlePosition: {
            // when true the date picker will align in the middle of the trigger
            type: Boolean,
        },
        setLeftPosition: {
            // when true the date picker will align to the left
            type: Boolean,
        },
        timeTravel: Boolean, // when true, year and month will be clickable for faster going back in time
        isFieldNotValid: {
            type: Boolean,
        },
        id: {
            type: String,
            default: '',
        },
        startAfter: {
            type: String,
            default: '',
        },
        maxDays: {
            type: Number,
            default: 0,
        },
        hidePresets: {
            type: Boolean,
        },
        placement: {
            type: String,
            default: 'bottom_start',
        },
        hideClearIcon: {
            type: Boolean,
        },
    },
    emits: ['update:modelValue', 'time-updated'],

    setup() {
        const isArabic = useIsArabic();

        return {
            isArabic,
        };
    },

    data() {
        return {
            show: false,
            days: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            date: [],
            now: new Date(),
            pickedValue: '',

            //for range
            date_start: '',
            date_end: '',
            mouseOverDate: '',
            showPreselected: this.hasPreselected,

            //for date time
            time: '',

            isMobileView: false,
            editingMonths: false,
            editingYears: false,
            yearBase: new Date(), // used in navigating the through years pages when time traveling
            enums,
        };
    },
    computed: {
        arrowSymbol() {
            return '-';
        },
        displayPickedValue() {
            const split = this.pickedValue.split(` ${this.arrowSymbol} `);
            if (split.length !== 2) {
                return this.pickedValue;
            }
            return split[0] === split[1] ? split[0] : this.pickedValue;
        },
        formattedMonths() {
            let counter = 0;
            const formatted = {};
            for (let i = 1; i <= 4; i++) {
                for (let j = 1; j <= 3; j++) {
                    formatted[`${i}_${j}`] = enums.months[counter];
                    counter++;
                }
            }
            return formatted;
        },
        formattedWeeks() {
            return {
                '1_1': 1,
                '1_2': 2,
                '2_1': 3,
                '2_2': 4,
            };
        },
        numOdWeeksInCurrentMonth() {
            const m = extendMoment(moment);

            let firstDay = m(this.now).startOf('month').startOf('week');
            if (!firstDay.isSame(m(this.now), 'month')) {
                firstDay = firstDay.add(7, 'days').startOf('week');
            }
            const endDay = m(this.now).endOf('month').endOf('week');
            const monthRange = m.range(firstDay, endDay);
            const weeks = [];
            for (const mday of monthRange.by('days')) {
                if (weeks.indexOf(mday.week()) === -1) {
                    weeks.push(mday.week());
                }
            }
            return weeks.length;
        },

        enumYears() {
            return Array(12)
                .fill(1)
                .map((i, index) =>
                    moment(this.yearBase).subtract(index, 'years').format('Y')
                )
                .reverse();
        },
    },

    watch: {
        now() {
            this.update();
        },

        show() {
            this.update();
        },

        modelValue(val) {
            this.pickedValue = val;

            if (val && this.isRange) {
                this.date_start = new Date(
                    val.split(` ${this.arrowSymbol} `)[0] + ' 00:00:00'
                );
                this.date_end = new Date(
                    val.split(` ${this.arrowSymbol} `)[1] + ' 00:00:00'
                );
            } else {
                this.date_start = '';
                this.date_end = '';
            }
        },

        pickedValue(value) {
            let payload;

            if (this.isRange) {
                payload =
                    value.split(` ${this.arrowSymbol} `).length === 2
                        ? value
                        : undefined;
            } else {
                payload = value;
            }

            if (this.validateDueAtTime(payload, this.time)) {
                this.$emit('update:modelValue', payload);
                if (this.time) {
                    this.$emit('time-updated', this.time);
                }
                this.now = this.parse(this.pickedValue) || new Date();
            }
        },
        mouseOverDate() {
            if (this.isRange) {
                this.update();
            }
        },
        time(value, oldValue) {
            if (this.validateDueAtTime(this.pickedValue, value, oldValue)) {
                this.$emit('time-updated', value);
            }
        },
        groupBy(value) {
            this.now = new Date();

            const index = this.getWeekCalendar().findIndex(
                (range) =>
                    range.start.isBefore(moment()) &&
                    range.end.isAfter(moment())
            );

            switch (value) {
                case 'day':
                    if (this.isRange) {
                        this.pickDataRange();
                    } else {
                        this.pickSingleData();
                    }
                    break;
                case 'week':
                    if (index !== -1) {
                        this.pickWeek(index + 1);
                    } else {
                        this.pickWeek(1);
                    }
                    break;
                case 'month':
                    this.pickMonth(moment().format('MMM'));
                    break;
            }
        },
        initialTime() {
            // this.time = this.initialTime;
            // this.Bus.$emit('redjustdueAtTime');
        },
    },
    created() {
        this.isMobileView = this.detectMobileView();

        window.addEventListener('orientationchange', this.setMobileView, false);
    },
    mounted() {
        this.time = clone(this.initialTime);
        this.pickedValue =
            typeof this.modelValue === 'object'
                ? stringifyDate(this.modelValue)
                : this.modelValue;
        Bus.$on('close-dropdowns', () => (this.show = false));
        this.$nextTick(() => {
            this.now =
                this.pickedValue && this.parse(this.pickedValue)
                    ? this.parse(this.pickedValue)
                    : new Date();

            document.addEventListener('click', this.leave, false);
        });
    },

    beforeUnmount() {
        document.removeEventListener('click', this.leave, false);
        window.removeEventListener(
            'orientationchange',
            this.setMobileView,
            false
        );
    },

    methods: {
        detectMobileView() {
            const vw = Math.max(
                document.documentElement.clientWidth || 0,
                window.innerWidth || 0
            );
            return vw < 640;
        },
        setMobileView() {
            this.isMobileView = this.detectMobileView();
            this.positionCalendar();
        },
        close() {
            this.show = false;
        },

        update() {
            const arr = [];
            const time = new Date(this.now);

            time.setMonth(time.getMonth(), 1); // the first day

            let curFirstDay = time.getDay();
            curFirstDay === 0 && (curFirstDay = 7);
            time.setDate(0); // the last day

            const lastDayCount = time.getDate();

            for (let i = curFirstDay; i > 0; i--) {
                const tmpTime = new Date(
                    time.getFullYear(),
                    time.getMonth(),
                    lastDayCount - i + 1
                );
                arr.push({
                    text: lastDayCount - i + 1,
                    time: tmpTime,
                    status: 'date-pass',
                    disabled: this.disabledDate(tmpTime),
                    isInRange: this.isDateInRange(
                        tmpTime,
                        this.mouseOverDate.time,
                        true
                    ),
                    isInSelectedRange: this.isDateInRange(
                        tmpTime,
                        this.date_end
                    ),
                    isToday: this.isToday(tmpTime),
                });
            }

            time.setMonth(time.getMonth() + 2, 0);

            const curDayCount = time.getDate();

            time.setDate(1); // fix bug when month change

            const value = this.pickedValue || stringifyDate(new Date());

            for (let i = 0; i < curDayCount; i++) {
                const tmpTime = new Date(
                    time.getFullYear(),
                    time.getMonth(),
                    i + 1
                );
                let status = '';
                stringifyDate(tmpTime) === value && (status = 'date-active');
                arr.push({
                    text: i + 1,
                    time: tmpTime,
                    status: status,
                    disabled: this.disabledDate(tmpTime),
                    isInRange: this.isDateInRange(
                        tmpTime,
                        this.mouseOverDate.time,
                        true
                    ),
                    isInSelectedRange: this.isDateInRange(
                        tmpTime,
                        this.date_end
                    ),
                    isToday: this.isToday(tmpTime),
                });
            }

            let j = 1;
            while (arr.length < 42) {
                const tmpTime = new Date(
                    time.getFullYear(),
                    time.getMonth() + 1,
                    j
                );
                arr.push({
                    text: j,
                    time: tmpTime,
                    status: 'date-future',
                    disabled: this.disabledDate(tmpTime),
                    isInRange: this.isDateInRange(
                        tmpTime,
                        this.mouseOverDate.time,
                        true
                    ),
                    isInSelectedRange: this.isDateInRange(
                        tmpTime,
                        this.date_end
                    ),
                    isToday: this.isToday(tmpTime),
                });
                j++;
            }
            this.date = arr;
        },

        monthClick(flag) {
            this.now.setMonth(this.now.getMonth() + flag, 1);
            this.now = new Date(this.now);
        },

        yearClicked(flag) {
            this.now.setYear(this.now.getFullYear() + flag, 1);
            this.now = new Date(this.now);
        },

        yearBaseClicked(flag) {
            this.yearBase.setYear(this.yearBase.getFullYear() + flag, 1);
            this.yearBase = new Date(this.yearBase);
        },

        pickDate(index) {
            this.now = new Date(this.date[index].time);
            if (this.isRange) {
                this.pickDataRange();
            } else {
                this.pickSingleData(true);
            }
        },

        pickMonth(monthName) {
            const index = enums.months.findIndex(
                (month) => month === monthName
            );

            if (this.isRange) {
                this.date_start = moment([
                    this.now.getFullYear(),
                    index,
                ]).toDate();

                this.date_end = moment(this.date_start).endOf('month').toDate();
                this.applyDateRange();
            } else {
                this.now = moment([this.now.getFullYear(), index]).toDate();
                this.pickedValue = stringifyDate(this.now, 'YYYY-MM');
                this.show = false;
            }
        },
        pickWeek(weekIndex) {
            const week = this.getWeekCalendar()[weekIndex - 1];
            this.date_start = week.start.toDate();

            this.date_end = week.end.toDate();
            this.applyDateRange();
        },
        isWeekSelected(weekIndex) {
            const week = this.getWeekCalendar()[weekIndex - 1];
            return (
                moment(this.date_start).isSame(
                    moment(week.start.toDate()),
                    'day'
                ) &&
                moment(this.date_end).isSame(moment(week.end.toDate()), 'day')
            );
        },
        getDisplayWeek(weekIndex) {
            const week = this.getWeekCalendar()[weekIndex - 1];
            // return `${week.start.format('DD MMM')} - ${week.end.format('DD MMM')}`;
            return `${week.start.format('DD')} ${this.$t(
                week.start.format('MMM')
            )} - ${week.end.format('DD')} ${this.$t(week.end.format('MMM'))}`;
        },
        getWeekCalendar() {
            moment.updateLocale('en', {
                week: {
                    dow: 1, // First day of week is Monday
                    doy: 4, // First week of year must contain 4 January (7 + 1 - 4)
                },
            });
            const m = extendMoment(moment);

            let firstDay = m(this.now).startOf('month').startOf('week');
            if (!firstDay.isSame(m(this.now), 'month')) {
                firstDay = firstDay.add(7, 'days').startOf('week');
            }
            const endDay = m(this.now).endOf('month').endOf('week');
            const monthRange = m.range(firstDay, endDay);
            const weeks = [];
            for (const mday of monthRange.by('days')) {
                if (weeks.indexOf(mday.week()) === -1) {
                    weeks.push(mday.week());
                }
            }
            const calendar = [];

            for (let index = 0; index < weeks.length; index++) {
                const weeknumber = weeks[index];
                let firstWeekDay = m(firstDay)
                    .week(weeknumber)
                    .year(m(firstDay).year())
                    .day(1);
                if (firstWeekDay.isBefore(firstDay)) {
                    firstWeekDay = firstDay;
                }
                let lastWeekDay =
                    m(firstDay).year() === m(endDay).year()
                        ? m(endDay).week(weeknumber).day(0)
                        : index + 1 === weeks.length
                          ? m(endDay)
                          : m(endDay).week(weeknumber).day(0);
                if (lastWeekDay.isAfter(endDay)) {
                    lastWeekDay = endDay;
                }
                const weekRange = m.range(firstWeekDay, lastWeekDay);
                calendar.push(weekRange);
            }

            return calendar;
        },

        pickSingleData(skipCheck = false) {
            this.show = false;
            this.pickedValue = stringifyDate(this.now);
            if (!skipCheck) {
                if ([0, 1, 2, 3, 4].includes(parseInt(moment().format('H')))) {
                    this.pickedValue = moment()
                        .subtract(1, 'days')
                        .format('YYYY-MM-DD');
                } else {
                    this.pickedValue = moment().format('YYYY-MM-DD');
                }
            }
        },
        pickDataRange() {
            if (this.date_start === '') {
                this.date_start = clone(this.now);
            } else if (this.date_end === '') {
                this.date_end = clone(this.now);
            } else {
                this.date_start = clone(this.now);
                this.date_end = '';
            }
            this.applyDateRange();
        },
        applyDateRange() {
            if (this.date_start && this.date_end) {
                let startDateString = stringifyDate(this.date_start),
                    endDateString = stringifyDate(this.date_end);

                if (
                    this.isRange &&
                    moment(endDateString).isBefore(startDateString)
                ) {
                    const temp = startDateString;
                    startDateString = endDateString;
                    endDateString = temp;
                }
                this.pickedValue = `${startDateString} ${this.arrowSymbol} ${endDateString}`;
                this.show = false;
            }
        },

        parse(str) {
            if (!str) {
                return null;
            }
            const date =
                str.split(` ${this.arrowSymbol} `).length === 2
                    ? str.split(` ${this.arrowSymbol} `)[0]
                    : str;
            const time = new Date(date);
            return isNaN(time.getTime()) ? null : time;
        },

        leave(e) {
            if (
                typeof e.target.className === 'string' &&
                e.target.className.indexOf('dont-leave') !== -1
            ) {
                return;
            }
            if (!this.$el.contains(e.target)) {
                this.close();
            }
        },

        isDateInRange(date, endDate, isHover = false) {
            if (!this.isRange) {
                return false;
            }
            if (isHover && this.date_end) {
                return false;
            }
            return this.date_start && endDate
                ? date.getTime() >= this.date_start.getTime() &&
                      date.getTime() <= endDate.getTime()
                : false;
        },

        setDate(range) {
            const startDate = new Date(new Date().setHours(0, 0, 0, 0));
            const endDate = new Date(new Date().setHours(0, 0, 0, 0));
            const day = startDate.getDay();
            const oneWeekAgoStart = new Date(new Date().setHours(0, 0, 0, 0));
            const oneWeekAgoEnd = new Date(new Date().setHours(0, 0, 0, 0));
            switch (range) {
                case 'today':
                    this.date_start = startDate;
                    this.date_end = startDate;
                    break;
                case 'yesterday':
                    startDate.setDate(startDate.getDate() - 1);
                    this.date_start = startDate;
                    this.date_end = startDate;
                    break;
                case 'this_week':
                    if (day !== 0) {
                        startDate.setHours(-24 * day);
                    }
                    if (day !== 6) {
                        endDate.setHours(24 * (6 - day));
                    }
                    this.date_start = startDate;
                    this.date_end = endDate;
                    break;
                case 'last_week':
                    oneWeekAgoStart.setDate(oneWeekAgoStart.getDate() - 7);
                    oneWeekAgoEnd.setDate(oneWeekAgoEnd.getDate() - 7);
                    if (day !== 0) {
                        oneWeekAgoStart.setHours(-24 * day);
                    }
                    if (day !== 6) {
                        oneWeekAgoEnd.setHours(24 * (6 - day));
                    }
                    this.date_start = oneWeekAgoStart;
                    this.date_end = oneWeekAgoEnd;
                    break;
                case 'this_month':
                    this.date_start = new Date(
                        startDate.getFullYear(),
                        startDate.getMonth(),
                        1
                    );
                    this.date_end = new Date(
                        endDate.getFullYear(),
                        endDate.getMonth() + 1,
                        0
                    );
                    break;
                case 'last_month':
                    this.date_start = new Date(
                        startDate.getMonth() === 0
                            ? startDate.getFullYear() - 1
                            : startDate.getFullYear(),
                        startDate.getMonth() - 1 === -1
                            ? 11
                            : startDate.getMonth() - 1,
                        1
                    );
                    this.date_end = new Date(
                        endDate.getFullYear(),
                        endDate.getMonth(),
                        0
                    );
                    break;
            }
            this.applyDateRange();
        },
        isToday(date) {
            return this.pickedValue && !this.isRange
                ? ''
                : moment().startOf('day').diff(moment(date)) === 0;
        },
        positionCalendar() {
            const coordinates = this.$refs.trigger.getBoundingClientRect();
            const calendar = this.$refs.calendar;
            if (
                coordinates.y + (this.isMobileView ? 550 : 365) >
                window.innerHeight
            ) {
                calendar.style.bottom = `${window.innerHeight - coordinates.y}px`;
                calendar.style.top = 'auto';
            } else {
                calendar.style.top = coordinates.y + 40 + 'px';
                calendar.style.bottom = 'auto';
            }

            switch (this.placement) {
                case 'bottom_start':
                    calendar.style.insetInlineStart = this.isArabic
                        ? `${window.innerWidth - coordinates.right}px`
                        : `${coordinates.left}px`;
                    calendar.style.insetInlineEnd = 'auto';
                    break;
                case 'bottom_end':
                    calendar.style.insetInlineEnd = `${this.isArabic ? coordinates.left : window.innerWidth - coordinates.right}px`;
                    calendar.style.insetInlineStart = 'auto';
                    break;
            }
        },
        isMonthSelected(monthName) {
            return (
                moment(this.now).format('YYYY-MM') === this.pickedValue &&
                moment(this.now).format('MMM') === monthName
            );
        },

        validateDueAtTime(date, time) {
            if (!date || !time) {
                return true;
            }
            if (!this.onlyFutureDates) {
                return true;
            }
            if (
                moment().isBefore(
                    moment(date + ' ' + time + ':00'),
                    'minute'
                ) ||
                moment().isSame(moment(date + ' ' + time + ':00'), 'minute')
            ) {
                return true;
            }
            // if(oldTime){
            //     this.time = '00:00';
            //     this.time = clone(oldTime);
            // }
            alertError(this.$t('The due at date must be a future date.'));
            return false;
        },
        clear() {
            this.pickedValue = '';
            this.$emit('update:modelValue', undefined);
            this.show = false;
        },
        shouldDisableDay(date) {
            if (this.maxDays) {
                return (
                    (date >
                        moment(this.date_start).add(this.maxDays - 1, 'days') ||
                        date < this.date_start) &&
                    this.date_end === ''
                );
            } else if (this.date_start) {
                return date < this.date_start && this.date_end === '';
            } else if (this.startAfter) {
                return date < new Date(this.startAfter + ' 00:00:00');
            }
            return false;
        },
        toggleCalendar() {
            this.positionCalendar();
            this.show = !this.show;
        },
    },
};
</script>

<template>
    <div class="datetime-picker">
        <template v-if="triggerElement === 'input'">
            <input
                :id
                ref="trigger"
                v-model="pickedValue"
                type="text"
                :name
                :class="inputClass + (isFieldNotValid ? ' invalid' : '')"
                :readonly
                :placeholder="$t(placeholder)"
                @keydown.enter="
                    update();
                    close();
                "
                @click="toggleCalendar" />
            <svg-icon
                v-if="pickedValue && !hideClearIcon"
                class="absolute top-[3px] ltr:right-4 rtl:left-4 fill-gray-550 cursor-pointer"
                icon="cross"
                @click="clear" />

            <span
                v-else
                class="absolute inset-y-0 end-0 flex items-center pe-3">
                <svg-icon
                    class="w-5 h-5 cursor-pointer"
                    icon="calendar"
                    @click="toggleCalendar" />
            </span>
        </template>

        <button
            v-else-if="triggerElement === 'button'"
            ref="trigger"
            class="btn btn-white ms-2 trigger"
            @click="toggleCalendar">
            <span v-if="!displayPickedValue"> {{ $t('Date Picker') }} </span>
            <span v-else> {{ displayPickedValue }} </span>
        </button>

        <div v-else ref="trigger" @click="toggleCalendar">
            <slot name="customTrigger" />
        </div>

        <div
            v-show="show"
            ref="calendar"
            class="picker-wrap fixed"
            :class="{
                'date-range': isRange,
                'set-middle': setMiddlePosition,
                'no-presets': hidePresets,
                'max-w-80': editingYears,
            }">
            <div v-if="hasPreselected && showPreselected" class="text-center">
                <div class="p-3 flex flex-col h-full justify-around">
                    <button
                        class="btn btn-white text-sm my-1"
                        @click="setDate('today')">
                        {{ $t('date_picker.today') }}
                    </button>
                    <button
                        class="btn btn-white text-sm my-1"
                        @click="setDate('yesterday')">
                        {{ $t('date_picker.yesterday') }}
                    </button>
                    <button
                        class="btn btn-white text-sm my-1 dont-leave"
                        @click="
                            showPreselected = false;
                            show = true;
                        ">
                        {{ $t('date_picker.custom') }}
                    </button>
                </div>
            </div>
            <div
                v-else-if="!editingMonths && !editingYears"
                class="flex flex-col-reverse md:flex-row py-6">
                <div
                    v-if="!hidePresets && isRange && groupBy === 'day'"
                    class="w-full md:w-1/2 border-r hidden md:block">
                    <div class="px-4 flex flex-col h-full">
                        <button
                            class="btn-outline text-footnote my-1 text-start whitespace-nowrap"
                            @click="setDate('today')">
                            {{ $t('date_picker.today') }}
                        </button>
                        <button
                            class="btn-outline text-footnote my-1 text-start whitespace-nowrap"
                            @click="setDate('yesterday')">
                            {{ $t('date_picker.yesterday') }}
                        </button>
                        <button
                            class="btn-outline text-footnote my-1 text-start whitespace-nowrap"
                            @click="setDate('this_week')">
                            {{ $t('date_picker.this_week') }}
                        </button>
                        <button
                            class="btn-outline text-footnote my-1 text-start whitespace-nowrap"
                            @click="setDate('last_week')">
                            {{ $t('date_picker.last_week') }}
                        </button>
                        <button
                            class="btn-outline text-footnote my-1 text-start whitespace-nowrap"
                            @click="setDate('this_month')">
                            {{ $t('date_picker.this_month') }}
                        </button>
                        <button
                            class="btn-outline text-footnote my-1 text-start whitespace-nowrap"
                            @click="setDate('last_month')">
                            {{ $t('date_picker.last_month') }}
                        </button>
                    </div>
                </div>
                <div v-if="groupBy === 'day'" class="cal px-4 day">
                    <div
                        class="flex justify-between md:mb-4 font-semibold items-center"
                        :class="{ 'flex-row-reverse': isArabic }">
                        <svg-icon
                            class="size-5 cursor-pointer"
                            icon="arrow_back_ios"
                            @click="monthClick(-1)" />

                        <span v-if="timeTravel">
                            <a
                                href="#"
                                class="text-primary hover:underline me-1 text-base font-semibold"
                                @click.stop.prevent="editingMonths = true">
                                {{ $t(enums.months[now.getMonth()]) }}
                            </a>
                            <a
                                href="#"
                                class="text-primary hover:underline text-base font-semibold"
                                @click.stop.prevent="editingYears = true">
                                {{ now.getFullYear() }}
                            </a>
                        </span>
                        <span v-else>
                            {{ $t(enums.months[now.getMonth()]) }}
                            {{ now.getFullYear() }}
                        </span>

                        <svg-icon
                            class="size-5 cursor-pointer"
                            icon="arrow_forward_ios"
                            @click="monthClick(1)" />
                    </div>
                    <table class="date-picker">
                        <thead>
                            <tr class="text-gray-500">
                                <th
                                    v-for="day in days"
                                    :key="day"
                                    class="font-regular text-base font-semibold leading-8 size-8 overflow-hidden">
                                    {{ $t(`date_picker.${day}`) }}
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(v, i) in 5" :key="i">
                                <td
                                    v-for="(x, j) in 7"
                                    :key="j"
                                    class="md:pt-4 px-0 pe-2 last:pe-0"
                                    :class="{
                                        disabled: shouldDisableDay(
                                            date[i * 7 + j] &&
                                                date[i * 7 + j].time
                                        ),
                                    }"
                                    :date="
                                        date[i * 7 + j] && date[i * 7 + j].date
                                    "
                                    @click="
                                        date[i * 7 + j] &&
                                            !date[i * 7 + j].disabled &&
                                            pickDate(i * 7 + j)
                                    "
                                    @mouseover="mouseOverDate = date[i * 7 + j]"
                                    @mouseleave="mouseOverDate = ''">
                                    <div
                                        class="font-normal no-align hover:bg-gray-200 hover:text-gray-900 text-base rounded-lg size-8 leading-8"
                                        :class="[
                                            date[i * 7 + j]
                                                ? date[i * 7 + j].status
                                                : '',
                                            {
                                                'date-disabled':
                                                    date[i * 7 + j] &&
                                                    date[i * 7 + j].disabled,
                                            },
                                            {
                                                'date-active':
                                                    date[i * 7 + j] &&
                                                    date[i * 7 + j].isInRange,
                                            },
                                            {
                                                'bg-primary-300 text-white':
                                                    date[i * 7 + j] &&
                                                    date[i * 7 + j]
                                                        .isInSelectedRange,
                                            },
                                            {
                                                'font-bold text-primary':
                                                    date[i * 7 + j] &&
                                                    date[i * 7 + j].isToday,
                                            },
                                        ]">
                                        {{
                                            date[i * 7 + j] &&
                                            date[i * 7 + j].text
                                        }}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-else-if="groupBy === 'week'" class="cal px-4">
                    <div class="flex justify-between pb-2f font-semibold">
                        <span
                            class="px-2 cursor-pointer"
                            @click="monthClick(-1)"
                            >{{ '&lt;' }}</span
                        >
                        <span>
                            {{ $t(enums.months[now.getMonth()]) }}
                            {{ now.getFullYear() }}
                        </span>
                        <span class="px-2 cursor-pointer" @click="monthClick(1)"
                            >></span
                        >
                    </div>
                    <table class="date-picker">
                        <tbody>
                            <tr
                                v-for="r in numOdWeeksInCurrentMonth === 4
                                    ? 2
                                    : 3"
                                :key="r">
                                <td
                                    v-for="w in numOdWeeksInCurrentMonth ===
                                        5 && r === 3
                                        ? 1
                                        : 2"
                                    :key="w"
                                    class="font-normal no-align"
                                    :class="
                                        isWeekSelected(w + r * 2 - 2)
                                            ? 'bg-primary text-white rounded hover:text-black'
                                            : ''
                                    "
                                    @click="pickWeek(w + r * 2 - 2)">
                                    {{ getDisplayWeek(w + r * 2 - 2) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-else-if="groupBy === 'month'" class="cal px-4">
                    <div
                        class="flex justify-between pb-2 font-semibold"
                        :class="{ 'flex-row-reverse': isArabic }">
                        <svg-icon
                            class="size-5 cursor-pointer"
                            icon="arrow_back_ios"
                            @click="yearClicked(-1)" />

                        <span>
                            <a
                                href="#"
                                class="text-primary hover:underline"
                                @click.stop.prevent="editingYears = true">
                                {{ now.getFullYear() }}
                            </a>
                        </span>

                        <svg-icon
                            class="size-5 cursor-pointer"
                            icon="arrow_forward_ios"
                            @click="yearClicked(1)" />
                    </div>
                    <table class="date-picker">
                        <tbody>
                            <tr v-for="r in 4" :key="r">
                                <td
                                    v-for="m in 3"
                                    :key="m"
                                    class="font-normal text-base no-align py-2 px-6"
                                    :class="
                                        isMonthSelected(
                                            formattedMonths[`${r}_${m}`]
                                        )
                                            ? 'bg-primary text-white hover:text-white rounded '
                                            : ''
                                    "
                                    @click="
                                        pickMonth(formattedMonths[`${r}_${m}`])
                                    ">
                                    {{ $t(formattedMonths[`${r}_${m}`]) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-if="editingMonths">
                <div class="flex flex-wrap p-4">
                    <div
                        v-for="(enumMonth, index) in enums.months"
                        :key="index"
                        class="w-1/3 py-2 text-center rounded cursor-pointer"
                        :class="
                            now.getMonth() === index
                                ? 'bg-primary text-white'
                                : 'hover:bg-gray-300'
                        "
                        @click.stop="
                            () => {
                                now.setMonth(index);
                                now = new Date(now);
                                editingMonths = false;
                            }
                        ">
                        {{ $t(enumMonth) }}
                    </div>
                </div>
            </div>
            <div v-else-if="editingYears" class="p-4">
                <div
                    class="flex justify-between font-semibold pb-4 items-center"
                    :class="{ 'flex-row-reverse': isArabic }">
                    <svg-icon
                        v-if="yearBase.getFullYear() > 1900"
                        class="size-5 cursor-pointer"
                        icon="arrow_back_ios"
                        @click="yearBaseClicked(-12)" />

                    <span>
                        {{ yearBase.getFullYear() - 11 }} -
                        {{ yearBase.getFullYear() }}
                    </span>

                    <svg-icon
                        v-if="yearBase.getFullYear() < 2100"
                        class="size-5 cursor-pointer"
                        icon="arrow_forward_ios"
                        @click="yearBaseClicked(12)" />
                </div>
                <div class="flex flex-wrap">
                    <div
                        v-for="(enumYear, index) in enumYears"
                        :key="index"
                        class="w-1/3 py-2 text-center rounded cursor-pointer"
                        :class="
                            now.getFullYear() == enumYear
                                ? 'bg-primary text-white'
                                : 'hover:bg-gray-300'
                        "
                        @click.stop="
                            () => {
                                now.setYear(enumYear);
                                now = new Date(now);
                                editingYears = false;
                            }
                        ">
                        {{ $t(enumYear) }}
                    </div>
                </div>
            </div>
            <div v-if="isDateTime" class="border-t">
                <time-picker v-model="time" />
            </div>
        </div>
    </div>
</template>

import cardSchemes from './cardSchemes.js';
import contractTypes from './contractTypes.js';
import accountTypes from './accountTypes.js';
import accountStatementTransactionLabels from './accountStatementTransactionLabels.js';
import businessTypes from './businessTypes.js';
import countries from './countries.js';
import days from './days.js';
import deviceModels from './deviceModels.js';
import deviceStatuses from './deviceStatuses.js';
import deviceVendors from './deviceVendors.js';
import paymentStatuses from './paymentStatuses.js';
import terminalStatuses from './terminalStatuses.js';
import deAssignTerminalStatuses from './deAssignTerminalStatuses.js';
import genders from './genders.js';
import times from './times.js';
import currencies from './currencies.js';
import roundingMethod from './roundingMethod.js';
import language from './language.js';
import countryNamesByCodes from './countryNamesByCodes.js';
import months from './months.js';
import simCardProviders from './simCardProviders.js';
import pendingRequestsTypes from './pendingRequestsTypes.js';
import mcc from './mcc.js';
import statusColors from './statusColors.js';
import transactionStatuses from './transactionStatuses.js';
import adjustmentTypes from './adjustmentTypes.js';
import adjustmentStatuses from './adjustmentStatuses.js';
import subAdjustmentStatuses from './subAdjustmentStatuses.js';
import communicationLanguages from './communicationLanguages.js';
import ticketTypes from './ticketTypes.js';
import auditLogStatuses from './auditLogStatuses.js';
import auditableTypes from './auditableTypes.js';
import creatorTypes from './creatorTypes.js';
import integrationActions from './integrationActions.js';
import integrationSources from './integrationSources.js';
import mmsDashboardTransactionStatuses from './mmsDashboardTransactionStatuses.js';
import {
    cpTransactionStatusesEnum as cpTransactionStatuses,
    cpDashboardTransactionStatusesEnum as cpDashboardTransactionStatuses,
} from './cpTransactionStatuses.js';
import onboardRequestStatuses from './onboardRequestStatuses.js';
import onBoardRequestSources from './onBoardRequestSources.js';
import notSettleableReasons from './notSettleableReasons.js';
import paymentTypes from './paymentTypes.js';
import samaIntegrationTypes from './samaIntegrationTypes.js';
import colors from './colors.js';
import paymentRequestChangesTypes from './paymentRequestChangesTypes.js';
import transactionRequestChangesTypes from './transactionRequestChangesTypes.js';
import superMerchantTypes from './superMerchantTypes.js';
import businessMonthlyTurnover from './businessMonthlyTurnover.js';
import BusinessNumberOfEmployees from './BusinessNumberOfEmployees.js';
import frequencyTypes from './frequencyTypes.js';
import financialRecordTypes from './financialRecordTypes.js';
import terminalTypes from './terminalTypes.js';
import tidTypes from './tidTypes.js';
import tidStatuses from './tidStatuses.js';
import orderStatuses from './orderStatuses.js';
import productsTypes from './productsTypes.js';
import settlementTypes from './settlementTypes.js';
import merchantAttachmentTypes from './merchantAttachmentTypes.js';
import unparsedRecordsReasons from './unparsedRecordsReasons.js';
import loanStatuses from './loanStatuses.js';
import terminalReconStatuses from './terminalReconStatuses.js';
import merchantBankAccountStatuses from './merchantBankAccountStatuses.js';
import loanTypes from './loanTypes.js';
import terminalActions from './terminalActions.js';
import bankAccountsStatuses from './bankAccountsStatuses.js';
import cpPayoutStatuses from './cpPayoutStatuses.js';
import cpTerminalStatuses from './cpTerminalStatuses.js';

export default Object.freeze({
    accountTypes,
    accountStatementTransactionLabels,
    businessTypes,
    cardSchemes,
    contractTypes,
    countries,
    days,
    deviceModels,
    deviceStatuses,
    deviceVendors,
    paymentStatuses,
    terminalStatuses,
    genders,
    times,
    currencies,
    roundingMethod,
    language,
    countryNamesByCodes,
    months,
    simCardProviders,
    deAssignTerminalStatuses,
    pendingRequestsTypes,
    mcc,
    statusColors,
    transactionStatuses,
    adjustmentTypes,
    adjustmentStatuses,
    subAdjustmentStatuses,
    communicationLanguages,
    ticketTypes,
    auditLogStatuses,
    auditableTypes,
    creatorTypes,
    integrationActions,
    integrationSources,
    mmsDashboardTransactionStatuses,
    cpTransactionStatuses,
    onboardRequestStatuses,
    onBoardRequestSources,
    notSettleableReasons,
    paymentTypes,
    samaIntegrationTypes,
    colors,
    paymentRequestChangesTypes,
    transactionRequestChangesTypes,
    superMerchantTypes,
    businessMonthlyTurnover,
    BusinessNumberOfEmployees,
    frequencyTypes,
    financialRecordTypes,
    terminalTypes,
    tidStatuses,
    tidTypes,
    orderStatuses,
    productsTypes,
    settlementTypes,
    merchantAttachmentTypes,
    unparsedRecordsReasons,
    loanStatuses,
    terminalReconStatuses,
    merchantBankAccountStatuses,
    loanTypes,
    cpDashboardTransactionStatuses,
    terminalActions,
    bankAccountsStatuses,
    cpPayoutStatuses,
    cpTerminalStatuses,
});

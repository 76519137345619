import { getEnumObject } from '../utilities.js';

export const adjustmentTypes = {
    SUBSCRIPTION: 1,
    DEDUCTION: 2,
    COMPENSATION: 3,
    LOAN: 4,
    RMS_SUBSCRIPTION: 5,
    SUPPLY_LOAN: 6,
};

export default getEnumObject(adjustmentTypes);

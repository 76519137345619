import { deviceVendors } from '../../Enums/deviceVendors.js';

export default {
    isValidCheckoutSecretKey: () =>
        'This field must start with sk_, be alphanumeric (including - or _), and range from 20 to 50 characters in length.',
    required: () => 'This field is required',
    email: () => 'Email is invalid',
    onlyLatinCharacters: () => 'Only Latin characters are allowed',
    onlyArabicCharacters: () => 'Only Arabic characters are allowed',
    onlyArabicAndEnglishCharacters: () => 'Only characters are allowed',
    minLength: (params) => `Minimum length is ${params.min}`,
    maxLength: (params) => `Maximum length is ${params.max}`,
    notStartWith: (params) =>
        `This field should not start with ${params.value}`,
    matchedLength: (params) =>
        `This field should be ${params.value} characters long`,
    startWith: (params) =>
        `This field should start with ${
            Array.isArray(params.value)
                ? params.value.join(' or ')
                : params.value
        }`,
    luhnCheck: () =>
        'This field has failed the validation rule. Make sure you enter a correct value.',
    largerThanZero: () => 'This field should be larger than 0',
    numeric: () => 'This field should be number',
    between: (params) =>
        `This field should be between ${params.min} and ${params.max}`,
    lengthBetween: (params) =>
        `This field should be between ${params.value.min} and ${params.value.max} characters long`,
    isValidPhoneNumber: () => 'Please enter a valid phone number',
    isValidIban: () => 'Please enter a valid Saudi IBAN',
    isValidBeneficiaryName: () =>
        'Beneficiary name must contain only Arabic or English letters or spaces or numbers',
    isValidBeneficiaryAddress: () =>
        'Beneficiary address must contain only Arabic or English letters or spaces or digits',
    validFullName: () =>
        'Name should contain first and last names. Each name is 2 letters minimum and can not contain non-alphabetic characters',
    validFoodicsEmail: () => 'Email must belong to the foodics domain',
    notBefore: (params) => `This date should not be before ${params.value}`,
    notAfter: (params) => `This date should not be after ${params.value}`,
    dateBetween: (params) =>
        `This date should be between ${params.value?.minDate?.format(
            'YYYY-MM'
        )} and ${params.value?.maxDate?.format('YYYY-MM')}`,
    notSameOrBefore: (params) =>
        `This date should not be the same or before ${params.value}`,
    isValidDate: () => 'Please enter a valid date in format yyyy-mm-dd',
    age: (params) => `Age should be at least ${params.value} years old`,
    requiredIf: () => `This field is required`,
    validTerminalId: (params) => {
        const terminalIDMessages = {
            [deviceVendors.INTERSOFT]:
                'Terminal ID must start with 81 for Intersoft devices',
            [deviceVendors.ITS]:
                'Terminal ID must start with 476 for ITS devices',
            [deviceVendors.ALHAMRANI]:
                'Terminal ID must start with 55 for AlHamrani devices',
            [deviceVendors.MOMKN]:
                'Terminal ID must start with 137 for Momkn devices',
            [deviceVendors.NEOLEAP]:
                'Terminal ID must start with 81, 55, or 15 for NEOLEAP devices',
        };

        if (terminalIDMessages[params.value]) {
            return terminalIDMessages[params.value];
        }

        return 'Please enter a valid Terminal ID';
    },
    validBusinessName: () =>
        'Name should not start with space, and latin characters, spaces, and digits are only allowed.',
    minValue: (params) => `The minimum value allowed is ${params.value}`,
    decimal: (params) =>
        `This field should have ${params.value} decimals at most`,
    validVatRegistrationNumber: () =>
        `Please enter a valid VAT number, 5-20 digits in length.`,
    isAfterFirstNtDaysAndBeforeNthMonth: (params) => {
        if (params.value && params.value.errorMessage) {
            return params.value.errorMessage;
        }
        return `The document for this month will be available on the 10th of the upcoming month `;
    },
    allowedExtensions: (params) => {
        return `Uploaded file should be in ${
            params.value && params.value.allowedExtensions
                ? params.value.allowedExtensions.join(', ')
                : 'JPEG, JPG, PNG, or PDF'
        } format`;
    },
    maxFileSize: (params) =>
        `File size should not exceed ${params.value || 1} MB`,
    validateEmailList: (params) =>
        `Please enter a maximum of ${params.maxEmails} valid emails seperated by commas`,
    maxDateRangeInDays: (params) =>
        `Date range should not exceed ${params.value.maxDays} days`,
};

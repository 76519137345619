<script>
import notificationTypes from '../Enums/notificationTypes';
import { useIsArabic } from '../Composables/useIsArabic';

export default {
    props: {
        type: {
            type: String,
            default: 'bg-primary',
        },
        message: {
            type: [Array, String],
            default: '',
        },
        autoClose: {
            type: Number,
            default: 5000,
        },
    },
    emits: ['expired'],
    setup() {
        const isArabic = useIsArabic();

        return {
            isArabic,
        };
    },

    data() {
        return {
            timeout: null,
        };
    },
    computed: {
        typeClasses() {
            const types = {
                [notificationTypes.SUCCESS]: 'bg-[#248A3D]',
                [notificationTypes.INFO]: 'bg-gray-600',
                [notificationTypes.WARNING]: 'bg-yellow',
                [notificationTypes.ERROR]: 'bg-red-500',
            };
            return types[this.type] || 'bg-primary';
        },
    },

    mounted() {
        this.setCloseTimeout();
    },

    beforeUnmount() {
        this.clearCloseTimeout();
    },

    methods: {
        setCloseTimeout() {
            this.timeout = setTimeout(() => {
                this.close();
            }, this.autoClose);
        },
        clearCloseTimeout() {
            clearTimeout(this.timeout);
        },
        close() {
            this.clearCloseTimeout();
            this.$emit('expired');
        },
    },
};
</script>

<template>
    <div
        class="notificationBody text-white px-8 py-4 shadow"
        :class="[
            isArabic ? 'notificationBody-ar' : 'notificationBody-en',
            typeClasses,
        ]">
        <template v-if="Array.isArray(message)">
            <span v-for="(error, index) in message" :key="index" class="block">
                {{ error }}
            </span>
        </template>
        <template v-else> {{ message }} </template>
        <svg-icon
            class="absolute top-[20px] ltr:right-4 rtl:left-4 fill-[#fff] cursor-pointer"
            icon="cross"
            @click="close" />
    </div>
</template>

<style lang="scss">
.notificationBody {
    z-index: 999999;
    position: fixed;
    bottom: 0;
    width: calc(100% - var(--sidebar-width));
    @media (max-width: 1024px) {
        width: 100%;
    }
}

.notificationBody-ar {
    left: 0;
}

.notificationBody-en {
    right: 0;
}
</style>

import { get } from 'lodash';

export function localizeAttribute(object, path) {
    if (!object) {
        return null;
    }
    if (
        localStorage.getItem('locale') === 'ar' &&
        get(object, `${path}_localized`)
    ) {
        return get(object, `${path}_localized`) ?? '—';
    } else {
        return get(object, path) ?? '—';
    }
}

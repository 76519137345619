<script setup>
import FdxCheckbox from '@/common/Components/FdxCheckbox.vue';
import { ref, watch } from 'vue';
import {
    useUnreconciledTerminals,
    setIsModalShownBefore,
} from '../Composables/useUnreconciledTerminals.js';
import { useRouter } from 'vue-router';

const closeModal = () => {
    setIsModalShownBefore();
};

const { count, routePath, setReminderEnabled } = useUnreconciledTerminals();
const doNotRemindMeAgain = ref(false);
watch(doNotRemindMeAgain, () => {
    setReminderEnabled(!doNotRemindMeAgain.value);
});

const router = useRouter();
const goToUnreconciledTerminalsRoute = () => {
    router.push(routePath.value);
    closeModal();
};
</script>

<template>
    <decorated-modal
        :title="$t('unreconciled_terminals.unreconciled_terminals')"
        width-class="max-w-[630px]"
        footer-classes="justify-between"
        body-container-class="p-0">
        <template #body>
            <img
                src="@assets/images/unreconciled_terminals.svg"
                class="mx-auto size-[130px]"
                :alt="$t('unreconciled_terminals.unreconciled_terminals')" />

            <div class="max-w-96 text-center mx-auto">
                <h1 class="text-xl font-bold leading-normal text-[#1C1C1E]">
                    {{ $t('unreconciled_terminals.reconcile_now') }}
                </h1>
                <p class="text-sm font-normal leading-6">
                    {{
                        $t(
                            'unreconciled_terminals.terminals_unreconciled_for_24_hours_reconcile_to_settle'
                        )
                    }}
                </p>
            </div>
        </template>
        <template #footer>
            <div
                class="flex items-center sm:justify-between flex-col sm:flex-row w-full">
                <fdx-checkbox
                    v-model="doNotRemindMeAgain"
                    class="sm:!mb-0"
                    :label="
                        $t('unreconciled_terminals.do_not_remind_me_again')
                    " />

                <div class="m-auto sm:m-0">
                    <button class="btn btn-white me-2" @click="closeModal">
                        {{ $t('button_label.close') }}
                    </button>
                    <button
                        class="btn btn-primary px-4 py-2"
                        @click="goToUnreconciledTerminalsRoute">
                        <span class="text-sm font-normal">
                            {{
                                $t(
                                    'unreconciled_terminals.view_x_unreconciled_terminals',
                                    {
                                        unreconciled_terminals_count: count,
                                    }
                                )
                            }}
                        </span>
                    </button>
                </div>
            </div>
        </template>
    </decorated-modal>
</template>

<script>
export default {
    props: {
        helpText: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
        },
        isAbsolute: {
            type: Boolean,
        },
        isCustom: {
            type: Boolean,
        },
        customBodyClasses: {
            type: String,
            default: '',
        },
        customContainerClasses: {
            type: String,
            default: '',
        },
        labelText: {
            type: String,
            default: '',
        },
        notInline: {
            type: Boolean,
        },
    },
    data() {
        return {
            triggerWidth: 0,
            triggerHeight: 0,
        };
    },
    mounted() {
        this.positionTooltip();
    },
    methods: {
        positionTooltip() {
            if (this.$refs.tt && !this.$refs.tt.classList.contains('abs')) {
                const targetRect = this.$refs.tooltip.getBoundingClientRect();
                const tooltipWidth = this.$refs.tt.offsetWidth;
                const tooltipHeight = this.$refs.tt.offsetHeight;

                let tooltipLeft =
                    targetRect.left + targetRect.width / 2 - tooltipWidth / 2;
                let tooltipTop = targetRect.top - tooltipHeight - 10; // 10 is the distance between the tooltip and the target element

                if (tooltipLeft < 0) {
                    tooltipLeft = 0;
                } else if (tooltipLeft + tooltipWidth > window.innerWidth) {
                    tooltipLeft = window.innerWidth - tooltipWidth;
                }

                if (tooltipTop < 0) {
                    tooltipTop = 0;
                } else if (tooltipTop + tooltipHeight > window.innerHeight) {
                    tooltipTop = window.innerHeight - tooltipHeight;
                }

                this.$refs.tt.style.top = tooltipTop + 'px';
                this.$refs.tt.style.left = tooltipLeft + 'px';
            }
        },
        showTooltip() {
            this.positionTooltip();
            this.$refs.tt?.classList?.add('hover');
        },
        hideTooltip() {
            this.$refs.tt?.classList?.remove('hover');
        },
    },
};
</script>
<template>
    <div :class="{ 'flex items-center': labelText }">
        <div v-if="labelText" class="me-2 whitespace-nowrap">
            {{ labelText }}
        </div>
        <div
            v-if="helpText || $slots.body"
            ref="tooltip"
            class="tooltip fade"
            :class="[
                {
                    '!cursor-default': disabled,
                },
                customContainerClasses,
            ]"
            @mouseenter="showTooltip"
            @mouseleave="hideTooltip">
            <label
                class="!opacity-100"
                :class="{ disabled: disabled, 'inline-flex': !notInline }">
                <div :class="{ 'opacity-60': disabled }">
                    <slot>
                        <div class="tooltip-svg-container">
                            <svg-icon icon="info" />
                        </div>
                    </slot>
                </div>

                <div
                    ref="tt"
                    class="body"
                    :class="{
                        abs: isAbsolute,
                        ['custom ' + customBodyClasses]: isCustom,
                    }">
                    <slot name="body">
                        {{ helpText }}
                    </slot>
                </div>
            </label>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import enums from '../Enums/index.js';

export default {
    props: {
        name: {
            type: String,
            default: '',
        },
        modelValue: {
            type: String,
            default: '',
        },
        isFieldNotValid: {
            type: Boolean,
        },
        id: {
            type: String,
            default: '',
        },
        placeholder: { type: String, default: '' },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            show: false,
            editingFromYear: false,
            editingToYear: false,
            pickedValue: '',

            fromDate: new Date(new Date().setDate(1)),
            toDate: new Date(new Date().setDate(1)),

            fromYearBase: new Date(),
            toYearBase: new Date(),
            enums,
        };
    },
    computed: {
        formattedValue() {
            return [
                moment(this.fromDate).format('YYYY-MM'),
                moment(this.toDate).format('YYYY-MM'),
            ].join(' - ');
        },
        fromEnumYears() {
            return Array(12)
                .fill(1)
                .map((i, index) =>
                    moment(this.fromYearBase)
                        .subtract(index, 'years')
                        .format('Y')
                )
                .reverse();
        },
        toEnumYears() {
            return Array(12)
                .fill(1)
                .map((i, index) =>
                    moment(this.toYearBase).subtract(index, 'years').format('Y')
                )
                .reverse();
        },
    },
    watch: {
        modelValue(val) {
            this.pickedValue = val;
            if (val) {
                const currentValues = val.split(' - ');
                this.fromDate = new Date(currentValues[0]);
                this.toDate = new Date(currentValues[1]);
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            document.addEventListener('click', this.leave, false);
        });
    },

    beforeUnmount() {
        document.removeEventListener('click', this.leave, false);
    },
    methods: {
        leave(e) {
            if (
                typeof e.target.className === 'string' &&
                e.target.className.indexOf('dont-leave') !== -1
            ) {
                return;
            }
            if (!this.$el.contains(e.target)) {
                this.show = false;
            }
        },
        clear() {
            this.fromDate = new Date(new Date().setDate(1));
            this.toDate = new Date(new Date().setDate(1));
            this.$emit('update:modelValue', undefined);
            this.show = false;
        },
        fromYearClicked(flag) {
            this.fromYearBase.setYear(
                this.fromYearBase.getFullYear() + flag,
                1
            );
            this.fromYearBase = new Date(this.fromYearBase);
        },
        toYearClicked(flag) {
            this.toYearBase.setYear(this.toYearBase.getFullYear() + flag, 1);
            this.toYearBase = new Date(this.toYearBase);
        },
        getMonthNumber(month) {
            return Number(moment().month(month).format('M')) - 1;
        },
        increaseOrDecreaseFromYear(value) {
            this.fromDate = new Date(
                this.fromDate.setYear(this.fromDate.getFullYear() + value)
            );

            if (this.toDate.getFullYear() < this.fromDate.getFullYear()) {
                this.toDate.setFullYear(this.fromDate.getFullYear());
            }

            this.$emit('update:modelValue', this.formattedValue);
        },
        increaseOrDecreaseToYear(value) {
            this.toDate = new Date(
                this.toDate.setYear(this.toDate.getFullYear() + value)
            );

            this.$emit('update:modelValue', this.formattedValue);
        },
        setFromYear(enumYear) {
            this.fromDate.setYear(enumYear);
            this.fromDate = new Date(this.fromDate);
            this.editingFromYear = false;

            this.$emit('update:modelValue', this.formattedValue);
        },
        setToYear(enumYear) {
            this.toDate.setYear(enumYear);
            this.toDate = new Date(this.toDate);
            this.editingToYear = false;

            this.$emit('update:modelValue', this.formattedValue);
        },
        setFromDate(index) {
            this.fromDate.setMonth(index);
            this.fromDate = new Date(this.fromDate);

            if (
                this.toDate.getFullYear() < this.fromDate.getFullYear() ||
                (this.toDate.getFullYear() === this.fromDate.getFullYear() &&
                    this.toDate.getMonth() < this.fromDate.getMonth())
            ) {
                this.toDate = new Date(this.fromDate);
            }

            this.$emit('update:modelValue', this.formattedValue);
        },
        setToDate(index) {
            this.toDate.setMonth(index);
            this.toDate = new Date(this.toDate);

            this.$emit('update:modelValue', this.formattedValue);
            this.show = false;
        },
    },
};
</script>

<template>
    <div class="datetime-picker inline flex flex-wrap">
        <div>
            <input
                :id
                ref="trigger"
                v-model="pickedValue"
                type="text"
                :name
                class="input p-2"
                :class="isFieldNotValid ? ' invalid' : ''"
                readonly
                :placeholder="$t(placeholder)"
                @click="show = !show" />
            <svg-icon
                v-if="pickedValue"
                class="absolute top-[12px] ltr:right-4 rtl:left-4 fill-gray-550 cursor-pointer"
                icon="cross"
                @click="clear" />

            <span
                v-else
                class="absolute inset-y-0 end-0 flex items-center pe-3">
                <svg-icon class="w-5 h-5" icon="calendar" />
            </span>
        </div>
        <div
            v-show="show"
            ref="calendar"
            class="flex flex-col picker-wrap fixed date-range p-4">
            <div class="flex">
                <div class="border-e border-gray-300 pe-2">
                    <div v-if="editingFromYear" data-testid="fromYearPicker">
                        <div class="flex justify-between pb-2 font-semibold">
                            <span
                                v-if="fromYearBase.getFullYear() > 1900"
                                class="px-2 cursor-pointer"
                                @click="fromYearClicked(-12)">
                                {{ '&lt;' }}
                            </span>
                            <span></span>
                            <span
                                v-if="fromYearBase.getFullYear() < 2100"
                                class="px-2 cursor-pointer"
                                @click="fromYearClicked(12)">
                                {{ '&gt;' }}
                            </span>
                        </div>
                        <div class="flex flex-wrap">
                            <div
                                v-for="(enumYear, index) in fromEnumYears"
                                :key="index"
                                class="w-1/3 py-2 text-center rounded cursor-pointer"
                                :class="
                                    fromDate.getFullYear() === enumYear
                                        ? 'bg-primary text-white'
                                        : 'hover:bg-gray-300'
                                "
                                @click.stop="setFromYear(enumYear)">
                                {{ $t(enumYear) }}
                            </div>
                        </div>
                    </div>
                    <div
                        v-else
                        data-testid="fromMonthPicker"
                        class="flex flex-wrap">
                        <div
                            class="flex w-full justify-between pb-2 font-semibold">
                            <span
                                data-testid="fromMonthPreviousYear"
                                class="px-2 cursor-pointer text-primary hover:underline"
                                @click="increaseOrDecreaseFromYear(-1)">
                                {{ '&lt;' }}
                            </span>
                            <span>
                                <a
                                    href="#"
                                    class="text-primary hover:underline"
                                    @click.stop.prevent="
                                        editingFromYear = true
                                    ">
                                    {{ fromDate.getFullYear() }}
                                </a>
                            </span>
                            <span
                                data-testid="fromMonthNextYear"
                                class="px-2 cursor-pointer text-primary hover:underline"
                                @click="increaseOrDecreaseFromYear(1)">
                                {{ '&gt;' }}
                            </span>
                        </div>
                        <div
                            v-for="(enumMonth, index) in enums.months"
                            :key="index"
                            data-testid="fromMonthCell"
                            class="w-1/3 py-2 text-center rounded cursor-pointer"
                            :class="
                                fromDate.getMonth() === index
                                    ? 'bg-primary text-white'
                                    : 'hover:bg-gray-300'
                            "
                            @click.stop="setFromDate(index)">
                            {{ $t(enumMonth) }}
                        </div>
                    </div>
                </div>
                <div class="ps-2">
                    <div v-if="editingToYear" data-testid="toYearPicker">
                        <div class="flex justify-between pb-2 font-semibold">
                            <span
                                v-if="toYearBase.getFullYear() > 1900"
                                class="px-2 cursor-pointer"
                                @click="toYearClicked(-12)">
                                {{ '&lt;' }}
                            </span>
                            <span></span>
                            <span
                                v-if="toYearBase.getFullYear() < 2100"
                                class="px-2 cursor-pointer"
                                @click="toYearClicked(12)">
                                {{ '&gt;' }}
                            </span>
                        </div>
                        <div class="flex flex-wrap">
                            <div
                                v-for="(enumYear, index) in toEnumYears"
                                :key="index"
                                class="w-1/3 py-2 text-center rounded cursor-pointer"
                                :class="[
                                    toDate.getFullYear() === enumYear
                                        ? 'bg-primary text-white'
                                        : 'hover:bg-gray-300',
                                    enumYear < fromDate.getFullYear()
                                        ? 'disabled'
                                        : '',
                                ]"
                                @click.stop="setToYear(enumYear)">
                                {{ $t(enumYear) }}
                            </div>
                        </div>
                    </div>
                    <div
                        v-else
                        data-testid="toMonthPicker"
                        class="flex flex-wrap">
                        <div
                            class="flex w-full justify-between pb-2 font-semibold">
                            <span
                                data-testid="toMonthPreviousYear"
                                class="px-2 cursor-pointer text-primary hover:underline"
                                :class="{
                                    disabled:
                                        fromDate.getFullYear() ===
                                        toDate.getFullYear(),
                                }"
                                @click="increaseOrDecreaseToYear(-1)">
                                {{ '&lt;' }}
                            </span>
                            <span>
                                <a
                                    href="#"
                                    class="text-primary hover:underline"
                                    @click.stop.prevent="editingToYear = true">
                                    {{ toDate.getFullYear() }}
                                </a>
                            </span>
                            <span
                                data-testid="toMonthNextYear"
                                class="px-2 cursor-pointer text-primary hover:underline"
                                @click="increaseOrDecreaseToYear(1)">
                                {{ '&gt;' }}
                            </span>
                        </div>
                        <div
                            v-for="(enumMonth, index) in enums.months"
                            :key="index"
                            data-testid="toMonthCell"
                            class="w-1/3 py-2 text-center rounded cursor-pointer"
                            :class="[
                                toDate.getMonth() === index
                                    ? 'bg-primary text-white'
                                    : 'hover:bg-gray-300',
                                fromDate.getFullYear() ===
                                    toDate.getFullYear() &&
                                getMonthNumber(enumMonth) < fromDate.getMonth()
                                    ? 'disabled'
                                    : '',
                            ]"
                            @click.stop="setToDate(index)">
                            {{ $t(enumMonth) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

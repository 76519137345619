const ForgetPasswordContainer = () =>
    import('../Screens/Auth/ForgetPassword/ForgetPasswordContainer.vue');

export default [
    {
        path: '/password/forget',
        name: 'forget-password',
        component: ForgetPasswordContainer,
        meta: {
            mixpanelPageName: 'Forgot Password',
            transition: 'slide-down',
            pageTitle: 'forget_password',
        },
    },
];

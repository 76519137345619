export const Environments = {
    PRODUCTION: 'prod',
    DEVELOPMENT: 'dev',
    ALL: 'all',
};

const scripts = [
    {
        name: 'chatFoodicsZendeskWidget',
        src: 'https://static.zdassets.com/ekr/snippet.js?key=c7717c4f-baf8-454e-8b18-7e3a94262aa2',
        id: 'ze-snippet',
        envs: [Environments.ALL],
        onScriptLoaded: () => {
            let localeInterval;
            let maxTries = 3;
            const trySetZendeskLocale = () => {
                if (window.zE) {
                    window.zE(
                        'messenger:set',
                        'locale',
                        localStorage.getItem('locale') || 'en'
                    );
                    clearInterval(localeInterval);
                } else {
                    maxTries--;
                    if (maxTries === 0) {
                        clearInterval(localeInterval);
                    }
                }
            };

            // Use requestIdleCallback if available, else fallback to setTimeout
            const startLocaleCheck = () => {
                localeInterval = setInterval(trySetZendeskLocale, 1000);
            };

            if ('requestIdleCallback' in window) {
                requestIdleCallback(startLocaleCheck);
            } else {
                setTimeout(startLocaleCheck, 0); // Fallback for browsers without requestIdleCallback
            }
        },
    },
];

const finalScripts = {};

export const buildScriptsThenLoad = (currentEnv) => {
    buildScripts(currentEnv);
    loadAllScripts();
};

const buildScripts = (currentEnv) => {
    scripts.forEach((script) => {
        if (
            script.isDisabled !== true &&
            (script.envs.includes(Environments.ALL) ||
                script.envs.some(
                    (env) =>
                        env.toLocaleLowerCase() ===
                        currentEnv.toLocaleLowerCase()
                ))
        ) {
            if (script.intializeFunction) {
                script.intializeFunction();
            }
            finalScripts[script.name] = {
                loaded: false,
                src: script.src,
                id: script.id,
                onScriptLoaded: script.onScriptLoaded,
            };
        }
    });
};

const loadAllScripts = async () => {
    for (const key in finalScripts) {
        await loadScript(key);
        if (finalScripts[key].onScriptLoaded) {
            finalScripts[key].onScriptLoaded();
        }
    }
};

const loadScript = (name) => {
    return new Promise((resolve) => {
        // load script
        const newScript = document.createElement('script');
        newScript.type = 'text/javascript';
        newScript.src = finalScripts[name].src;
        if (finalScripts[name].id) {
            newScript.id = finalScripts[name].id;
        }
        if (newScript.readyState) {
            // IE
            newScript.onreadystatechange = () => {
                if (
                    newScript.readyState === 'loaded' ||
                    newScript.readyState === 'complete'
                ) {
                    newScript.onreadystatechange = null;
                    finalScripts[name].loaded = true;
                    resolve({ script: name, loaded: true, status: 'Loaded' });
                }
            };
        } else {
            // Others
            newScript.onload = () => {
                finalScripts[name].loaded = true;
                resolve({ script: name, loaded: true, status: 'Loaded' });
            };
        }
        newScript.onerror = () =>
            resolve({ script: name, loaded: false, status: 'Failed' });

        document.body.appendChild(newScript);
    });
};

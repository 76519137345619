export default {
    mounted(el, binding) {
        const handler = (event) => {
            if (!el.contains(event.target) && el !== event.target) {
                binding.value(event);
            }
        };

        el.__vueClickOutside__ = handler;

        document.addEventListener('click', handler);
    },

    unmounted(el) {
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    },
};

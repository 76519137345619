import { getEnumObject } from '../utilities.js';

export const deAssignTerminalStatuses = {
    PULL_OUT_REQUESTED: 5,
    PULLED_OUT: 6,
    WRITE_OFF: 10,
    LOST: 11,
};

export default getEnumObject(deAssignTerminalStatuses);

import { getEnumObject } from '../utilities.js';

export const notSettleableReasons = {
    BANK_FEE_DISCREPANCY: 1,
    NO_CARD_SCHEME_FEE: 2,
    AWAITING_AMEX: 3,
    MANY_ACTIVE_TERMINALS: 4,
    UNKNOWN_BANK_ACCOUNT: 5,
    AWAITING_ACCOUNT_STATEMENT_CONFIRMATION: 6,
    ACCOUNT_STATEMENT_MISMATCH: 7,
    TERMINAL_NOT_RESOLVED: 8,
    AWAITING_SABB: 9,
    AWAITING_CHECKOUT: 10,
    MERCHANT_NOT_RESOLVED: 11,
    BANK_NOT_RESOLVED: 12,
    TRANSACTION_AND_SUBSCRIPTION_BANKS_ARE_DIFFERENT: 13,
    AWAITING_BANK: 14,
    UNKOWN_CARD_SCHEME: 15,
    MIGRATED_TRANSACTION: 16,
    MISSING_BANK_CHANNEL: 17,
    DECLINED_TRANSACTION: 19,
    PENDING_CAPTURE: 20,
};

export default getEnumObject(notSettleableReasons);

<script>
import { camelCase } from 'lodash';

export default {
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        icon: {
            type: String,
            default: 'external-link',
        },
        title: {
            type: String,
            required: true,
        },
        single: {
            type: Boolean,
        },
    },
    emits: ['click'],

    methods: {
        onClickHandler(withCtrl) {
            this.$emit('click', withCtrl);
        },
        camelCase,
    },
};
</script>

<template>
    <div
        class="details-card-item w-full pb-4 mb-4 border-b border-gray-400"
        :class="{ 'lg:w-1/2': !single }">
        <div
            :id="title"
            class="details-card items-center grid grid-cols-[1fr_1fr] gap-2">
            <div class="text-gray-800 text-callout">
                {{ $t(title) }}
            </div>
            <slot>
                <fdx-link
                    :id="`${title}-action`"
                    :data-testid="camelCase(title)"
                    :value
                    @click="onClickHandler($event)" />
            </slot>
        </div>
    </div>
</template>

<script>
import MainSection from '@/common/Components/MainSection.vue';

export default {
    components: {
        MainSection,
    },
    props: {
        disableFormScroll: {
            type: Boolean,
        },
    },
};
</script>

<template>
    <main-section>
        <div class="full-height-cp flex gap-12">
            <div
                data-testid="firstCol"
                class="hidden md:flex items-center lg:basis-7/12 md:basis-6/12 basis-full">
                <div>
                    <img
                        src="@assets/images/Illustration.png"
                        data-testid="illustrationImg"
                        alt="illustration" />
                </div>
            </div>
            <div
                class="flex flex-col bg-[#f4f3f8] rounded-xl lg:basis-5/12 md:basis-6/12 basis-full relative"
                data-testid="secCol">
                <slot name="stepIndicator"></slot>
                <div class="flex flex-col flex-1 justify-between">
                    <div>
                        <div
                            class="flex justify-center items-center px-6 pt-12 pb-6">
                            <img
                                src="@assets/images/logo-foodics.png"
                                class="w-1/2"
                                data-testid="logoImg"
                                alt="logo" />
                        </div>
                        <div
                            id="form-container"
                            :class="[
                                !disableFormScroll
                                    ? !$slots['actions']
                                        ? 'form-container-no-actions overflow-y-auto'
                                        : 'form-container overflow-y-auto'
                                    : '',
                            ]"
                            data-testid="formContent"
                            class="px-8">
                            <slot name="content" />
                        </div>
                    </div>
                    <div
                        v-if="$slots['actions']"
                        class="flex flex-col justify-end w-full p-6 flex-1">
                        <slot name="actions" />
                    </div>
                </div>
            </div>
        </div>
    </main-section>
</template>

<style scoped></style>

import { getEnumObject } from '../utilities.js';

export const accountStatementTransactionLabels = {
    CREDIT_CARD_SETTLEMENT: 1,
    CREDIT_CARD_SETTLEMENT_REFUND: 2,
    MADA_CARD_SETTLEMENT: 3,
    MADA_SETTLEMENT_FEES: 4,
    MADA_SETTLEMENT_VAT: 5,
    MADA_CARD_SETTLEMENT_REFUND: 6,
    AMEX_SETTLEMENT: 7,
    MAESTRO_SETTLEMENT: 8,
    SETTLEMENT_OUT: 9,
    SARIE_FEES: 10,
    RETURNED_PAYMENT: 11,
    UNKNOWN: null,
};

export default getEnumObject(accountStatementTransactionLabels);

import { getEnumObject } from '../utilities.js';

export const terminalStatuses = {
    NEW: 1,
    PENDING_INSTALLATION: 2,
    PENDING_BANK_REGISTRATION: 3,
    INSTALLED: 4,
    PULL_OUT_REQUESTED: 5,
    PULLED_OUT: 6,
    BANK_CHANGED: 7,
    CANCELLED: 8,
    PENDING_TMS_REGISTRATION: 9,
    WRITE_OFF: 10,
    LOST: 11,
};

export default getEnumObject(terminalStatuses);

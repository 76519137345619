export default {
    mounted(el, binding) {
        const originalText = el.innerHTML;
        const spinnerElement = `<svg width="21" height="21" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#1877F2">
            <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
                <circle stroke-opacity=".8" cx="18" cy="18" r="18"/>
                <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="1s"
                    repeatCount="indefinite"/>
                </path>
            </g>
            </g>
        </svg>`;

        el.loading = {
            originalText,
            spinnerElement,
            isLoading: false,
        };

        if (binding.value) {
            toggleLoading(el, binding);
        }
    },
    updated(el, binding) {
        if (el.loading.originalText !== el.innerHTML && !el.loading.isLoading) {
            el.loading.originalText = el.innerHTML;
        }

        if (binding.value !== binding.oldValue) {
            toggleLoading(el, binding);
        }
    },
    unmounted(el) {
        el.innerHTML = el.loading.originalText;
    },
};

function toggleLoading(el, binding) {
    if (binding.value) {
        el.setAttribute('disabled', 'disabled');
        el.innerHTML = el.loading.spinnerElement;
        el.loading.isLoading = true;
    } else {
        el.removeAttribute('disabled');
        el.innerHTML = el.loading.originalText;
        el.loading.isLoading = false;
    }
}

import { getEnumObject } from '../utilities.js';

export const ticketTypes = {
    TERMINAL_ISSUE: 1,
    APP_ISSUE: 2,
    SUBSCRIPTION: 3,
    INQUIRY: 4,
    COMPLAINT: 5,
    SUGGESTION: 6,
    OTHER: 7,
};

export default getEnumObject(ticketTypes);

<script>
import { useNavigateToPage } from '@/common/Composables/useNavigateToPage';
import { closeAllDropdowns } from '@/common/utils/dom';

export default {
    setup() {
        const { navigateToPage } = useNavigateToPage();
        return {
            navigateToPage,
        };
    },
    methods: {
        closeDropDown() {
            closeAllDropdowns();
        },
        openUrl(url) {
            this.navigateToPage(url, true);
            closeAllDropdowns();
        },
    },
};
</script>
<template>
    <drop-down
        class="relative"
        :prevent-propagate="false"
        :hide-on-scroll="false"
        dropdown-content-classes="!p-4">
        <template #trigger>
            <button
                class="flex justify-center items-center border border-gray-70 rounded-full h-11 w-11">
                <svg-icon icon="apps-menu" />
            </button>
        </template>
        <template #content>
            <div>
                <div class="flex items-start justify-between mb-4">
                    <h1 class="text-xl font-bold">
                        {{ $t('my_apps') }}
                    </h1>
                    <button>
                        <svg-icon
                            class="w-6 h-6 cursor-pointer"
                            icon="close-2"
                            @click="closeDropDown" />
                    </button>
                </div>
                <div class="flex sm:flex-row flex-col gap-4">
                    <a
                        class="bg-gray-50 rounded border border-primary hover:cursor-not-allowed hover:bg-gray-60 flex flex-col items-center py-4 gap-2"
                        href="#"
                        @click.stop.prevent>
                        <svg-icon icon="pay-foodics" class="w-12 h-12 mx-5" />
                        <span class="font-medium">{{ $t('pay') }}</span>
                    </a>
                    <a
                        class="bg-gray-50 rounded hover:bg-gray-60 flex flex-col items-center py-4 gap-2"
                        href="#"
                        @click.stop.prevent="
                            openUrl('https://console.foodics.com/')
                        ">
                        <svg-icon
                            icon="console-foodics"
                            class="w-12 h-12 mx-5" />
                        <span class="font-medium">{{ $t('console') }}</span>
                    </a>
                    <a
                        class="bg-gray-50 rounded hover:bg-gray-60 flex flex-col items-center py-4 gap-2"
                        href="#"
                        @click.stop.prevent="
                            openUrl('https://online.foodics.com/login')
                        ">
                        <svg-icon
                            icon="online-foodics"
                            class="w-12 h-12 mx-5" />
                        <span class="font-medium">{{ $t('online') }}</span>
                    </a>
                </div>
            </div>
        </template>
    </drop-down>
</template>
<style scoped>
a {
    font-size: small !important;
    line-height: 1 !important;
}
</style>

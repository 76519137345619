import { isEqual, omitBy, isUndefined, isNull } from 'lodash';

export function removeEmptyProperties(obj) {
    return Object.entries(obj).reduce(
        (a, [k, v]) => (v === null || v === undefined ? a : ((a[k] = v), a)),
        {}
    );
}

export function getTopLevelDiffKeys(obj1, obj2) {
    if (!obj1) {
        obj1 = {};
    }

    return Object.keys(obj1).reduce((result, key) => {
        if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
            result.push(key);
        } else if (isEqual(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key);
            result.splice(resultKeyIndex, 1);
        }
        return result;
    }, Object.keys(obj2));
}

export function removeAllEmptyValues(obj) {
    return omitBy(
        obj,
        (v) =>
            isUndefined(v) ||
            isNull(v) ||
            v === '' ||
            (v && v instanceof Object && Object.keys(v).length === 0)
    );
}

export function isObject(obj) {
    return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
}

export function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
}

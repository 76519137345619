const OnboardingContainer = () =>
    import(
        '../Screens/Auth/Register/Onboarding/Containers/OnboardingContainer.vue'
    );

export default [
    {
        path: '/onboard',
        name: 'onboard',
        component: OnboardingContainer,
        meta: {
            mixpanelPageName: 'Foodics Pay Onboarding',
            pageTitle: 'onboarding',
        },
    },
];

const LoginContainer = () => import('../Screens/Auth/Login/LoginContainer.vue');
const RMSLogin = () => import('../Screens/Auth/RMSLogin/RMSLogin.vue');

export default [
    {
        path: '/login',
        name: 'login',
        component: LoginContainer,
        meta: {
            mixpanelPageName: 'Log in',
            transition: 'slide-down',
            pageTitle: 'login',
        },
    },
    {
        path: '/rms-login',
        name: 'RMSLogin',
        component: RMSLogin,
        meta: {
            mixpanelPageName: 'RMS Log in',
            mixpanelName: 'RMSLogin',
            transition: 'slide-down',
        },
    },
];

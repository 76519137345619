import { getEnumObject } from '../utilities.js';

export const deviceStatuses = {
    ASSIGNED: 1,
    FREE: 2,
    DAMAGED_OR_LOST: 3,
    TEMPORARILY_UNAVAILABLE: 4,
};

export default getEnumObject(deviceStatuses);

import { getEnumObject } from '../utilities.js';

export const productsTypes = {
    FOODICS_ONE: 'foodics_one',
    STANDARD_POS_TERMINAL: 'standard_pos_terminal',
    FOODICS_ONLINE: 'foodics_online',
    CUSTOMER_DISPLAY_PAYMENTS: 'customer_display_payments',
};

export default getEnumObject(productsTypes);

<script setup>
import { closeAllDropdowns } from '../utils/dom';

defineProps({
    title: {
        type: String,
        default: '',
    },
    widthClass: {
        type: String,
        default: 'max-w-sm',
    },
    closeButton: {
        type: Boolean,
    },
    bodyContainerClass: {
        type: String,
        default: '',
    },
    bodyColor: {
        type: String,
        default: 'bg-white',
    },
    appliedTitleClasses: {
        type: String,
        default: '',
    },
    hideFooter: {
        type: Boolean,
    },
    appliedClasses: {
        type: String,
        default: '',
    },
    footerClasses: {
        type: String,
        default: '',
    },
    drawer: {
        type: Boolean,
    },
});

const emit = defineEmits(['close']);
</script>

<template>
    <modal :width-class :drawer @close="emit('close')">
        <div
            class="bg-white shadow-lg"
            :class="[
                appliedClasses,
                drawer
                    ? 'max-h-[80vh] md:max-h-full md:h-screen overflow-hidden flex flex-col md:rounded-s-lg rounded-t-lg md:rounded-t-none'
                    : 'rounded-lg',
            ]">
            <div
                v-if="Boolean(title)"
                class="flex items-center justify-between"
                :class="[
                    'px-6 py-4 text-title-3 font-bold border-b',
                    appliedTitleClasses,
                ]">
                <span> {{ title }}</span>

                <button
                    v-if="closeButton"
                    data-testid="headerCloseButton"
                    class="size-6"
                    @click="emit('close')">
                    <svg-icon class="w-6 h-6" icon="cross-in-circle" />
                </button>
                <slot name="modalTitleRight"></slot>
            </div>

            <div
                id="decorated-modal-body"
                data-testid="decoratedModalBody"
                :class="[
                    'p-6 decorated-modal-body',
                    bodyColor,
                    bodyContainerClass,
                    { 'rounded-lg': hideFooter },
                    { 'max-h-[calc(85vh-9rem)]': !drawer },
                    { 'flex-grow': drawer },
                ]"
                @scroll="closeAllDropdowns">
                <slot name="body" />
            </div>

            <div
                v-if="!hideFooter"
                :class="[
                    'px-6 py-4 border-t flex items-center justify-end',
                    footerClasses,
                ]">
                <slot name="footer" />
                <button
                    v-if="closeButton"
                    data-testid="closeButton"
                    class="btn btn-white ms-2 hidden md:block"
                    @click="emit('close')">
                    {{ $t('button_label.close') }}
                </button>
                <div v-if="$slots.footerRight" class="ms-1">
                    <slot name="footerRight" />
                </div>
            </div>
        </div>
    </modal>
</template>
<style>
/* 9rem is the padding for header and footer plus outer margin  */
.decorated-modal-body {
    overflow-y: auto;
}
</style>

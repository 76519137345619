<script>
export default {
    props: {
        isPositionAbsolute: { type: Boolean, default: true },
        switchLocalePosition: { type: Boolean },
    },
    data() {
        return {
            locale: 'en',
        };
    },
    created() {
        const locale = localStorage.getItem('locale');
        this.locale = locale ? locale : 'en';
    },
    methods: {
        switchLocale() {
            if (this.locale === 'ar') {
                localStorage.setItem('locale', 'en');
            } else {
                localStorage.setItem('locale', 'ar');
            }

            window.location.reload();
        },
    },
};
</script>

<template>
    <div
        class="switcher-container"
        :class="{
            'absolute-ar':
                isPositionAbsolute && locale === 'ar' && !switchLocalePosition,
            'absolute-en':
                isPositionAbsolute && locale === 'en' && !switchLocalePosition,
            'absolute-ar-opposite ': switchLocalePosition && locale === 'ar',
            'absolute-en-opposite ': switchLocalePosition && locale === 'en',
        }">
        <a
            id="arBtn"
            data-testid="arBtn"
            lines="none"
            class="locale-switcher"
            @click.stop.prevent="switchLocale()">
            <span :class="`font-${locale === 'en' ? 'ar' : 'en'}`">
                {{ $t('other_lang_long') }}
            </span>
        </a>
    </div>
</template>

import { getEnumObject } from '../utilities.js';

export const BusinessNumberOfEmployees = {
    // From 1 - 5
    LEVEL_ONE: 1,
    // From 6 - 10
    LEVEL_TWO: 2,
    // From 11 - 20
    LEVEL_THREE: 3,
    // From 21 - above
    LEVEL_FOUR: 4,
};

export default getEnumObject(BusinessNumberOfEmployees);

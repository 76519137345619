import { getEnumObject } from '../utilities.js';

export const terminalActions = {
    REPLACEMENT: 3,
    MAINTENANCE: 4,
    PAPER_ROLL: 5,
    MANAGE_CARD_SCHEMES: 6,
};

export default getEnumObject(terminalActions);

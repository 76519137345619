let http = undefined;
const setHttp = (instance) => {
    http = instance;
};

export const useHttp = () => {
    return {
        setHttp,
        /**
         * @returns {import('axios').AxiosInstance}
         * @description Returns the axios instance
         * @example
         * ```js
         * const { http } = useHttp();
         * http().get('/api/v1/users');
         * http().post('/api/v1/users', { name: 'John Doe' });
         * // ...etc
         * ```
         */
        http,
    };
};

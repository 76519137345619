import { useHttp } from '@/common/Composables/useHttp.js';
import { mixpanelTrackEvent } from '@/common/Composables/useMixpanel.js';
import { Bus } from '@/common/bus';
import { alertError } from '@/common/utils/alert';
import { scrollToTop } from '@/common/utils/dom';
import axios from 'axios';
import * as Sentry from '@sentry/vue';

export default {
    computed: {
        // dummy auth to prevent errors in components that check for authorities
        auth() {
            return {
                hasAuthority: () => {
                    return true;
                },
            };
        },
    },
    created() {
        const { setHttp } = useHttp();
        setHttp(this.http);
    },
    methods: {
        http(options = {}) {
            const instance = axios.create({
                baseURL: this.$root.mainStore.appBaseUrl,
            });
            const token = document.head.querySelector(
                'meta[name="csrf-token"]'
            );
            instance.defaults.headers.common['X-Requested-With'] =
                'XMLHttpRequest';
            instance.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
            instance.defaults.headers.common['Accept-Language'] =
                localStorage.getItem('locale') || 'en';

            instance.interceptors.response.use(
                (response) => {
                    if (
                        response.headers['x-build'] &&
                        response.headers['x-build'] !==
                            window.foodics?.config.build
                    ) {
                        location.reload();
                    }
                    if (
                        response.headers['x-trial-expired'] &&
                        response.headers['x-trial-expired'] == 1 &&
                        this.$route.name !== 'trialExpired'
                    ) {
                        this.$router.replace({ path: '/trial-expired' });
                    }
                    return response;
                },
                async (error) => {
                    this.mixpanelErrorEvent(error?.response);

                    if (options.hasCustomErrorHandling) {
                        return Promise.reject(error);
                    }

                    switch (error?.response?.status) {
                        case 503:
                        case 520:
                            alertError(
                                "We couldn't complete your request at the moment, please try again."
                            );
                            Bus.$emit('form-modal:stop-saving');
                            break;

                        case 500:
                            alertError(this.$t('an_error_occurred'));
                            break;

                        case 504:
                            alertError(this.$t('can_not_serve'));
                            break;

                        case 429:
                        case 401:
                            this.$router.replace({ name: 'logout' });
                            break;

                        case 403:
                            alertError(this.$t('not_authorized'));
                            break;

                        case 404:
                            this.$router.replace({ path: '/404' });
                            break;

                        case 423: //locked exception
                            alertError(this.$t('try_again'));
                            return;
                    }
                    scrollToTop();
                    if (error?.response?.status >= 500) {
                        Sentry.captureException(error);
                    }
                    return Promise.reject(error);
                }
            );

            return instance;
        },

        mixpanelErrorEvent(response) {
            const config = response?.config || {};
            const { url = '', method = '' } = config;
            const [baseUrl, query = ''] = url.split('?');
            mixpanelTrackEvent(
                'CP API Error',
                {
                    code: response?.status || '',
                    errorDescription: response?.data?.message || '',
                    requestType: method,
                    fullRequestUrl: url,
                    requestUrl: baseUrl,
                    requestQuery: query,
                    errorCode: response?.headers?.error_code || '',
                },
                this.$route
            );
        },
    },
};

<script>
import moment from 'moment';
import { Bus } from '@/common/bus.js';
import Alert from '@/common/Components/Alert.vue';
import Notification from '@/common/Components/Notification.vue';
import menu from './menu.js';
import { Sidebar } from './Components/Sidebar';
import LocaleSwitcher from './Components/LocaleSwitcher.vue';
import AppHeader from './Components/AppHeader.vue';
import VerifyNafathCard from './Components/VerifyNafathCard.vue';
import { Environments, buildScriptsThenLoad } from '@/common/scriptLoader.js';
import { useUserStore } from '@/customer-portal/js/Composables/useUserStore.js';
import { useAppSettings } from '@/customer-portal/js/Composables/useAppSettings.js';
import UnreconciledTerminalsModal from './Components/UnreconciledTerminalsModal.vue';
import { useUnreconciledTerminals } from '@/customer-portal/js/Composables/useUnreconciledTerminals.js';
import { useIsArabic } from '@/common/Composables/useIsArabic.js';

export default {
    components: {
        Alert,
        Notification,
        Sidebar,
        LocaleSwitcher,
        AppHeader,
        VerifyNafathCard,
        UnreconciledTerminalsModal,
    },
    setup() {
        const { shouldShowModal: shouldShowUnreconciledTerminalsModal } =
            useUnreconciledTerminals();

        buildScriptsThenLoad(
            import.meta.env.DEV
                ? Environments.DEVELOPMENT
                : Environments.PRODUCTION
        );
        const {
            isAuthenticated,
            isNafathVerified,
            user,
            setUserInfo,
            isLoading,
        } = useUserStore();
        const {
            isSideBarDisplayed,
            isLocaleSwitcherDisplayed,
            isHeaderDisplayed,
        } = useAppSettings();

        const isArabic = useIsArabic();

        return {
            isAuthenticated,
            isNafathVerified,
            isSideBarDisplayed,
            user,
            setUserInfo,
            isLocaleSwitcherDisplayed,
            isHeaderDisplayed,
            isLoading,
            shouldShowUnreconciledTerminalsModal,
            isArabic,
        };
    },
    data() {
        return {
            mobileMenuOpen: false,
            menu,
            alert: {
                type: null,
                autoClose: 0,
                message: '',
                subMessage: '',
                confirmationProceed: null,
                confirmationCancel: null,
            },
            notification: {
                type: null,
                autoClose: 0,
                message: '',
            },
        };
    },
    computed: {
        isOwnerAndNafathNotVerified() {
            return Boolean(this.user?.is_owner && !this.isNafathVerified);
        },
        shouldDisplayVerifyNafathCard() {
            return (
                this.isLoaded &&
                this.isOwnerAndNafathNotVerified &&
                this.$route.name !== 'verify-nafath'
            );
        },
        isLoaded() {
            return !this.isLoading;
        },
    },
    watch: {
        '$route.path'() {
            this.mobileMenuOpen = false;
        },
    },
    async created() {
        this.setLocale();
    },
    async mounted() {
        Bus.$on('alert', (data) => {
            this.alert = data;
        });
        Bus.$on('notification', (data) => {
            this.notification = data;
        });
    },
    methods: {
        setLocale() {
            const locale = localStorage.getItem('locale') ?? 'en';
            localStorage.setItem('locale', locale);
            this.$i18n.locale = locale;

            const el = document.getElementsByTagName('html');

            el[0].setAttribute('lang', locale);
            el[0].setAttribute('dir', locale === 'ar' ? 'rtl' : 'ltr');

            this.configureLocaleWeekStart();
        },

        closeNotification() {
            this.notification.type = null;
            this.notification.autoClose = 0;
            this.notification.message = '';
        },

        configureLocaleWeekStart() {
            moment.updateLocale(this.isArabic ? 'ar-ly' : 'en', {
                week: {
                    dow: 1, // Monday is the first day of the week.
                    doy: 1, // The week that contains Jan 1st is the first week of the year.
                },
            });
        },
    },
};
</script>

<template>
    <div>
        <screen-loader v-show="!isLoaded" />
        <div
            v-show="isLoaded"
            class="wrapper"
            :class="isArabic ? 'rtl' : 'ltr'">
            <locale-switcher v-if="isLocaleSwitcherDisplayed" />

            <alert
                v-if="alert.type"
                :message="alert.message"
                :type="alert.type"
                :auto-close="alert.autoClose"
                :sub-message="alert.subMessage"
                :confirmation-proceed="alert.confirmationProceed"
                :confirmation-cancel="alert.confirmationCancel"
                :confirmation-proceed-btn-text="
                    alert.confirmationProceedBtnText
                "
                :confirmation-cancel-btn-text="alert.confirmationCancelBtnText"
                :is-danger="alert.isDanger"
                :title="alert.title" />

            <notification
                v-if="notification.type"
                :message="notification.message"
                :type="notification.type"
                :auto-close="notification.autoClose"
                v-bind="notification.options"
                :class="{ '!w-full ps-24': !isSideBarDisplayed }"
                @expired="closeNotification" />

            <app-header v-if="isHeaderDisplayed">
                <svg-icon
                    class="size-6 cursor-pointer lg:!hidden me-4"
                    icon="menu"
                    @click="mobileMenuOpen = true" />
            </app-header>
            <template v-if="isSideBarDisplayed">
                <div class="lg:hidden">
                    <transition name="show">
                        <sidebar
                            v-if="mobileMenuOpen"
                            :menu
                            class="block w-1/2 lg:w-full"
                            @close="mobileMenuOpen = false" />
                    </transition>
                </div>

                <div class="hidden lg:block">
                    <sidebar :menu></sidebar>
                </div>
            </template>

            <main :id="isSideBarDisplayed ? 'main' : 'main-authorized'">
                <verify-nafath-card v-if="shouldDisplayVerifyNafathCard" />

                <router-view v-slot="{ Component, route }">
                    <transition
                        :name="route.meta.transition || 'fade'"
                        mode="out-in"
                        appear>
                        <component
                            :is="Component"
                            :key="$route.path"
                            class="pb-20" />
                    </transition>
                </router-view>
            </main>

            <unreconciled-terminals-modal
                v-if="shouldShowUnreconciledTerminalsModal" />
        </div>
    </div>
</template>

<style>
body.loggedin {
    @apply bg-white;
}
.locale-switcher-menu {
    @apply font-arabic;
}

.grecaptcha-badge {
    visibility: hidden;
}

body.recaptcha .grecaptcha-badge {
    visibility: visible;
}
</style>

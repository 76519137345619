const RmsSubscriptionsDetails = () =>
    import('../Screens/Subscriptions/view/RmsSubscriptionsDetails.vue');

const SubscriptionsList = () =>
    import('../Screens/Subscriptions/list/SubscriptionsList.vue');

export default [
    {
        path: '/subscriptions',
        meta: {
            requireAuth: true,
            requireApproval: true,
            activeSidebarItemId: 'subscriptions',
        },
        children: [
            {
                path: '',
                name: 'subscriptions',
                component: SubscriptionsList,
                meta: {
                    mixpanelPageName: 'Subscriptions List',
                    pageTitle: 'subscriptions_list',
                },
            },
            {
                path: 'rms/:id',
                name: 'rms-subscription-details',
                component: RmsSubscriptionsDetails,
                props: true,
                meta: {
                    mixpanelPageName: 'RMS Subscription Details',
                    pageTitle: 'rms_subscription_details',
                },
            },
        ],
    },
];

const DeductionView = () =>
    import('../Screens/Deductions/View/DeductionView.vue');
export default [
    {
        path: '/deductions',
        meta: {
            requireAuth: true,
            requireApproval: true,
        },
        children: [
            {
                path: ':id/:type/:prevId?',
                name: 'deduction-view',
                component: DeductionView,
                props: true,
                meta: {
                    mixpanelPageName: 'Deduction Details',
                    pageTitle: 'deduction_details',
                },
            },
        ],
    },
];

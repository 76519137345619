import { reactive, toRefs } from 'vue';
const LOGGED_IN_CLASS = 'loggedin';

const state = reactive({
    isSideBarDisplayed: false,
    isHeaderDisplayed: false,
    isLocaleSwitcherDisplayed: true,
});

const removeLoggedInClassFromBody = () => {
    document.body.classList.remove(LOGGED_IN_CLASS);
};

const addLoggedInClassToBody = () => {
    document.body.classList.add(LOGGED_IN_CLASS);
};

const setAppSettingsState = (updatedValues) => {
    Object.entries(updatedValues).forEach(([key, value]) => {
        state[key] = value;
    });
};

export function useAppSettings() {
    const setAuthenticationStyle = () => {
        addLoggedInClassToBody();
        setAppSettingsState({
            isSideBarDisplayed: true,
            isHeaderDisplayed: true,
            isLocaleSwitcherDisplayed: false,
        });
    };

    const removeAuthenticationStyle = (updatedValues = {}) => {
        setAppSettingsState({
            isSideBarDisplayed: false,
            isHeaderDisplayed: false,
            ...updatedValues,
        });
        removeLoggedInClassFromBody();
    };
    return {
        ...toRefs(state),
        setAuthenticationStyle,
        removeAuthenticationStyle,
    };
}

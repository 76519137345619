import { genders } from '@/common/Enums/genders.js';
import mccEnum from '@/common/Enums/mcc.js';
import { startCase } from 'lodash';

export function initGainsight(user) {
    if (typeof window.aptrinsic === 'undefined') {
        return;
    }
    const userInfo = {
        id: user.id,
        email: user.email,
        username: user.name,
        firstName: (user.name || '').split(' ')[0] || '',
        lastName: (user.name || '').split(' ')[1] || '',
        PreferredLanguage: user.preferred_language,
        language: localStorage.getItem('locale') || 'en',
        is_owner: user.is_owner,
        gender: user.gender === genders.MALE ? 'Male' : 'Female',
        F5Number: user.merchant?.business_reference ?? '',
        MerchantReference: user.merchant?.reference ?? '',
        CreatedAt: user?.created_at
            ? new Date(user.merchant?.created_at).getTime() // Get the Unix timestamp in milliseconds
            : '',
    };

    let formattedMMC = '';
    if (user.merchant?.mcc) {
        const value = `${mccEnum[user.merchant.mcc] || ''}`;
        formattedMMC = startCase(
            value?.replaceAll(/_/g, ' ').replaceAll(/-/g, '')
        );
    }

    const accountInfo = {
        id: user.merchant?.business_reference ?? '',
        name: user.merchant?.business_name ?? '',
        F5Number: user.merchant?.business_reference ?? '',
        MerchantReference: user.merchant?.reference ?? '',
        MerchantReferenceID: user.merchant?.reference ?? '',
        continent: 'As',
        countryCode: 'SA',
        countryName: 'Saudi Arabia',
        timeZone: '+03:00',
        BusinessCountry: 'SA',
        BusinessCreatedAt: user.merchant?.created_at
            ? new Date(user.merchant?.created_at).getTime() // Get the Unix timestamp in milliseconds
            : '',
        MCC: formattedMMC,
    };
    window.aptrinsic('identify', userInfo, accountInfo);
}

export function resetGainsight() {
    if (typeof window.aptrinsic === 'undefined') {
        return;
    }
    window.aptrinsic('reset');
}

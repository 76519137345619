import { getEnumObject } from '../utilities.js';

export const loanStatuses = {
    PENDING: 1,
    COLLECTED: 2,
    CANCELED: 3,
};

export const loanStatusesColors = {
    [loanStatuses.PENDING]: 'bg-blue-400',
    [loanStatuses.COLLECTED]: 'bg-green-700',
    [loanStatuses.CANCELED]: 'bg-red-500',
};

export default getEnumObject(loanStatuses);

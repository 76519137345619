import { getEnumObject } from '../utilities.js';

export const onboardRequestStatuses = {
    NEW: 1,
    SUBMITTED: 2,
    PENDING: 3,
    APPROVED: 4,
    REJECTED: 5,
    FINAL_REJECTED: 6,
};

export default getEnumObject(onboardRequestStatuses);

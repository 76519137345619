<script>
import { useIsArabic } from '../Composables/useIsArabic';

export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        href: {
            type: String,
            required: true,
        },
        forceHref: {
            type: Boolean,
        },
    },
    setup() {
        const isArabic = useIsArabic();

        return {
            isArabic,
        };
    },
    methods: {
        goBack() {
            const historyPathes = [
                ...this.$root.mainStore.fullPathesRouteHistory,
            ];

            if (historyPathes?.length && !this.forceHref) {
                this.$router.push(historyPathes.pop());
                this.$root.mainStore.removePrevFullPath();
            } else {
                this.$router.push(`/${this.href}`);
            }
        },
    },
};
</script>

<template>
    <button
        class="hover:text-primary mb-1 flex items-center breadcrumb"
        @click="goBack">
        <svg-icon
            class="w-3 h-3 fill-current me-2"
            :icon="`arrow-${isArabic ? 'right' : 'left'}`" />

        <span> {{ title }} </span>
    </button>
</template>

const MyAccountView = () => import('../Screens/MyAccount/View/MyAccount.vue');

export default [
    {
        path: '/my-account',
        name: 'my-account',
        component: MyAccountView,
        meta: {
            requireAuth: true,
            mixpanelPageName: 'Account Details',
            activeSidebarItemId: 'business-details',
            pageTitle: 'my_account',
        },
    },
];

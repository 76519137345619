import featureFlagsEnum from '../Enums/featureFlagsEnum';

const CustomerInsights = () =>
    import('../Screens/CustomerInsights/CustomerInsights.vue');

export default [
    {
        path: '/customer-insights',
        name: 'customer-insights',
        component: CustomerInsights,
        meta: {
            requireAuth: true,
            requireApproval: true,
            mixpanelPageName: 'Customer Insights',
            activeSidebarItemId: 'customer_insights',
            pageTitle: 'customer_insights',
            featureFlag: featureFlagsEnum.CUSTOMER_INSIGHTS,
        },
    },
];

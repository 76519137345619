export default {
    customer_insights_menu: 'تحليلات العملاء',
    page_title: {
        customer_insights: 'تحليلات العملاء',
    },
    customer_insights: {
        help: 'تعرض هذه اللوحة بيانات العمليات التي تتم عبر شاشات العملاء فقط. نود أن نلفت انتباهكم إلى أنه قد يظهر العميل نفسه كعدة سجلات إذا استخدم بطاقات أو طرق دفع مختلفة مثل Apple Pay، وذلك بسبب المعرفات الفريدة لكل بطاقة. نحن نعمل حالياً على تطوير حلول لدمج هذه السجلات بهدف منحكم رؤية أشمل لعملائكم، كما نخطط لتوسيع نطاق الخدمة لتشمل أجهزة الدفع الأخرى مستقبلاً.',
        info: 'احصل على رؤى أعمق حول معاملات شاشة العميل. قم بمواكبة نمو العملاء وقياس مستوى الولاء وتحسين استراتيجيات التسويق.',
        timeline: {
            week: 'آخر 7 أيام',
            month: 'آخر 30 يومًا',
            quarter: 'آخر 3 أشهر',
            previous: {
                week: 'الأيام السبعة السابقة',
                month: 'الأيام الثلاثون السابقة',
                quarter: 'الشهور الثلاثة السابقة',
            },
        },
        something_went_wrong: 'حدث خطأ ما!',
        unexpected_error: 'عفواً! يبدو أنه حدث خطأ غير متوقع.',
        not_able_to_fetch: 'نحن غير قادرين على جلب المعلومات.',
        footfall: {
            title: 'العدد اليومي للزوار',
            info: 'يصور هذا المخطط البياني التوجهات اليومية في نشاط العملاء، ويستعرض أنماط سلوك العملاء الجديدة والمتكررة. استخدم هذا المؤشر لتحديد تأثير أيام معينة أو عروض ترويجية محددة.',
        },
        trends: {
            increase: 'الزيادة مقابل {timeline}',
            decrease: 'النقصان مقابل {timeline}',
            steady: 'ثابت مقابل {timeline}',
        },
        stats: {
            overview: {
                title: 'نظرة عامة',
                count: 'إجمالي عدد المعاملات',
                value: 'إجمالي قيمة المعاملة',
                customers: 'إجمالي العملاء',
            },
            breakdown: {
                title: 'بيان تفصيلي',
                segment: 'الشريحة',
                customers: 'العملاء',
                transactions_count: 'إجمالي عدد المعاملات',
                transactions_value: 'إجمالي قيمة المعاملة',
                transactions_avg_value: 'متوسط ​​قيمة المعاملة',
            },
        },
    },
};

import { getEnumObject } from '../utilities.js';

export const simCardProviders = {
    STC: 1,
    MOBILY: 2,
    LIBARA: 3,
    SKYBAND: 4,
};

export default getEnumObject(simCardProviders);

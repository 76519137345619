<script>
import { useIsArabic } from '../Composables/useIsArabic';

export default {
    props: {
        type: {
            type: String,
            required: true,
        },
        message: {
            type: String,
            required: true,
        },
        subMessage: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        autoClose: {
            type: Number,
            default: 0,
        },
        confirmationProceed: {
            type: Function,
            default: null,
        },
        confirmationCancel: {
            type: Function,
            default: null,
        },
        isDanger: {
            type: Boolean,
        },
        confirmationProceedBtnText: {
            type: String,
            default: 'alert_label.yes',
        },
        confirmationCancelBtnText: {
            type: String,
            default: 'alert_label.cancel',
        },
        cancelOnClose: {
            type: Boolean,
            default: true,
        },
    },

    setup() {
        const isArabic = useIsArabic();

        return {
            isArabic,
        };
    },

    data() {
        return {
            timeout: null,
            confirming: false,
        };
    },

    mounted() {
        if (this.autoClose) {
            this.timeout = setTimeout(() => {
                this.close();
            }, this.autoClose);
        }
    },

    methods: {
        /**
         * Handle hitting the escape key.
         */
        handleEscape(e) {
            e.stopPropagation();

            if (e.keyCode == 27) {
                if (this.type == 'error') {
                    this.close();
                } else {
                    this.cancel();
                }
            }
        },

        /**
         * Close the alert.
         */
        close() {
            clearTimeout(this.timeout);

            if (
                this.type == 'confirmation' &&
                this.confirmationCancel &&
                this.cancelOnClose
            ) {
                this.confirmationCancel();
            }

            this.$parent.alert.type = null;
            this.$parent.alert.autoClose = 0;
            this.$parent.alert.message = '';
            this.$parent.alert.subMessage = '';
            this.$parent.alert.confirmationProceed = null;
            this.$parent.alert.confirmationCancel = null;
        },

        /**
         * Confirm and close the alert.
         */
        confirm() {
            if (this.confirming) {
                return;
            }
            this.confirming = true;

            if (this.confirmationProceed) {
                this.confirmationProceed();
            }

            this.close();
        },

        /**
         * Cancel and close the alert.
         */
        cancel() {
            clearTimeout(this.timeout);

            if (this.confirmationCancel) {
                this.confirmationCancel();
            }

            this.$parent.alert.type = null;
            this.$parent.alert.autoClose = 0;
            this.$parent.alert.message = '';
            this.$parent.alert.subMessage = '';
            this.$parent.alert.confirmationProceed = null;
            this.$parent.alert.confirmationCancel = null;
        },
    },
};
</script>

<template>
    <!-- eslint-disable vue/no-v-html -->
    <modal
        v-if="$parent.alert.type"
        style="z-index: 9999999"
        center
        @close="close">
        <div class="bg-white rounded-lg my-5 shadow-lg text-black">
            <div
                class="flex items-start justify-between"
                :class="['px-5 py-4 font-semibold border-b text-lg']">
                <div>{{ $t(title) }}</div>
            </div>
            <div class="p-6">
                <p v-html="message"></p>

                <p v-if="Boolean(subMessage)" class="mt-4 mb-0">
                    {{ subMessage }}
                </p>
            </div>
            <div
                class="px-5 py-4 border-t flex justify-end items-center overflow-hidden md:h-auto"
                :class="[isArabic ? 'text-left' : 'text-right']">
                <button
                    v-if="type == 'error' || type == 'info'"
                    class="btn btn-primary uppercase"
                    @click="confirm()">
                    {{ $t('alert_label.ok') }}
                </button>

                <button
                    v-if="type == 'confirmation'"
                    class="btn btn-white me-2 uppercase"
                    @click="cancel()">
                    {{ $t(confirmationCancelBtnText) }}
                </button>

                <button
                    v-if="type == 'confirmation'"
                    v-loading="confirming"
                    class="btn uppercase"
                    :class="isDanger ? 'btn-danger' : 'btn-primary'"
                    @click="confirm()">
                    {{ $t(confirmationProceedBtnText) }}
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    props: {
        cssClasses: {
            type: String,
            default: 'h-40',
        },
        isPrimary: {
            type: Boolean,
            default: true,
        },
        message: {
            type: String,
            default: '',
        },
    },
};
</script>

<template>
    <div class="flex flex-col items-center justify-center" :class="cssClasses">
        <div v-if="message" class="py-2">
            {{ $t(message) }}
        </div>
        <svg-icon
            class="w-12 h-12"
            :class="isPrimary ? 'stroke-primary' : 'stroke-gray'"
            icon="loader" />
    </div>
</template>

export function formatNumber(value, forceDecimal = false) {
    if (value === null || value === undefined) {
        return '—';
    }
    return Number(parseFloat(value).toFixed(2)).toLocaleString('en', {
        minimumFractionDigits: forceDecimal ? 2 : 0,
        maximumFractionDigits: 2,
    });
}

export function roundDownToNearestThousand(num) {
    if (num < 100) {
        return `${num}`;
    }

    let roundedNum = num;
    if (num < 1000) {
        roundedNum = Math.floor(num / 100) * 100;
    } else {
        roundedNum = Math.floor(num / 1000) * 1000;
    }
    return `${roundedNum}+`;
}

export function formatFloat(x, prec = 3) {
    return isFloat(x) ? parseFloat(parseFloat(x).toFixed(prec)) : x;
}

export function isFloat(num) {
    return num % 1 != 0;
}

export function numberWithCommas(x, prec = 3) {
    if (x === null || x === '') {
        return '—';
    }
    if (isFloat(x)) {
        x = parseFloat(parseFloat(x).toFixed(prec)).toFixed(prec);
    }
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // return isNaN(parts.join(".")) ? x : parts.join(".");
    return parts.length === 2 ? parts.join('.') : parts[0];
}

export function numberFromString(value) {
    return Number(String(value).replaceAll(',', ''));
}

export function symbolizedNumber(
    value,
    precision = 2,
    removeTrailingZeros = true
) {
    // BE can return numbers as formatted strings ex: '123,456,212.00'
    value = numberFromString(value); //remove commas from numbers

    if (isNaN(value)) {
        return NaN;
    }

    const scales = [
        { threshold: 1_000_000_000, suffix: 'B' },
        { threshold: 1_000_000, suffix: 'M' },
        { threshold: 1_000, suffix: 'K' },
        { threshold: 1, suffix: '' },
    ];

    const absValue = Math.abs(value);

    for (const { threshold, suffix } of scales) {
        if (absValue >= threshold) {
            let truncatedValue = `${(value / threshold).toFixed(precision)}`;
            if (removeTrailingZeros) {
                truncatedValue = Number(truncatedValue);
            }
            return `${truncatedValue}${suffix}`;
        }
    }

    return value.toString(); // Fallback if no thresholds match
}

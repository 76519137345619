<script>
import SidebarItem from './SidebarItem.vue';
import {
    useUserStore,
    hasFeatureEnabled,
} from '@/customer-portal/js/Composables/useUserStore.js';
import AccountSwitcher from '../AccountSwitcher.vue';
import { camelCase } from 'lodash';

export default {
    components: {
        SidebarItem,
        AccountSwitcher,
    },
    props: {
        menu: {
            type: Array,
            required: true,
        },
    },
    emits: ['close'],
    setup: () => {
        const userStore = useUserStore();
        const { user, isUserApproved } = userStore;
        return {
            user,
            isUserApproved,
            hasFeatureEnabled: (featureFlag) =>
                hasFeatureEnabled(featureFlag, user.value),
        };
    },
    data() {
        return {
            activeItem: null,
            openedMenuItems: [],
        };
    },
    computed: {
        builtMenu() {
            return this.menu.reduce((accumulator, current) => {
                const item = {
                    ...current,
                    disabled: this.isDisabledItem(current),
                };
                if (item.name === 'deductions') {
                    return this.filterDeductions(item, accumulator);
                }
                if (!item.children) {
                    if (this.isAllowedItem(item)) {
                        accumulator.push(item);
                    }
                } else {
                    const allowed = item.children.filter(this.isAllowedItem);

                    if (allowed.length) {
                        accumulator.push({
                            ...item,
                            children: allowed,
                            open: this.openedMenuItems.includes(item.name),
                        });
                    }
                }
                return accumulator;
            }, []);
        },
        topItems() {
            return this.builtMenu.filter((item) => !item.bottom);
        },
        bottomItems() {
            return this.builtMenu.filter((item) => item.bottom);
        },
        merchantDeductions() {
            const {
                has_foodics_capital,
                has_foodics_supply,
                has_pay_subscription_adjustments,
                has_rms_buy_now_pay_later,
            } = this.user.merchant ?? {};

            return {
                loans: has_foodics_capital || has_foodics_supply,
                subscriptions:
                    has_pay_subscription_adjustments ||
                    has_rms_buy_now_pay_later,
            };
        },
    },
    watch: {
        $route() {
            this.selectActiveItem();
        },
    },
    mounted() {
        this.selectActiveItem();
    },
    methods: {
        onItemClick(item) {
            if (!item.children?.length) {
                return;
            }
            const index = this.openedMenuItems.findIndex(
                (menuItemName) => menuItemName === item.name
            );
            if (index > -1) {
                this.openedMenuItems.splice(index, 1);
            } else {
                this.openedMenuItems.push(item.name);
            }
        },
        filterDeductions(item, accumulator) {
            const shouldShow = Object.values(this.merchantDeductions).some(
                Boolean
            );
            if (!shouldShow) {
                return accumulator;
            }

            accumulator.push({
                ...item,
                children: item.children.filter(
                    (child) =>
                        this.isAllowedItem(child) &&
                        this.merchantDeductions[child.name]
                ),
                open: this.openedMenuItems.includes(item.name),
            });
            return accumulator;
        },
        selectActiveItem() {
            if (!this.$route) {
                return;
            }
            this.activeItem =
                this.$route.query.activeSidebarItemId ??
                this.$route.meta.activeSidebarItemId;
            this.scrollToActiveTab();
        },
        isAllowedItem(item) {
            const meta = item.meta ?? {};

            if (!this.hasFeatureEnabled(meta.featureFlag)) {
                return false;
            }

            if (meta.isOwner && !this.user.is_owner) {
                return false;
            }
            return true;
        },
        isDisabledItem(item) {
            if (item.meta?.requireApproval && !this.isUserApproved) {
                return true;
            }
            return false;
        },
        scrollToActiveTab() {
            setTimeout(() => {
                const element = document.getElementById(
                    this.$route.meta.activeSidebarItemId
                );
                if (element && !this.isInViewport(element)) {
                    element.scrollIntoView({
                        behavior: 'smooth',
                    });
                }
            });
        },
        isInViewport(element) {
            const rect = element.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <=
                    (window.innerHeight ||
                        document.documentElement.clientHeight) &&
                rect.right <=
                    (window.innerWidth || document.documentElement.clientWidth)
            );
        },
        camelCase,
    },
};
</script>

<template>
    <div>
        <div id="sidebar" data-testid="sidebar" class="bg-gray-100">
            <div
                id="sidebar-body"
                data-testid="sidebarBody"
                class="flex flex-col space-y-2">
                <div>
                    <div class="flex items-center h-16 pe-0.5 my-4">
                        <router-link to="/" class="!p-0">
                            <img
                                class="w-[202px]"
                                src="@assets/images/foodics-logo-purple.svg"
                                alt="logo" />
                        </router-link>

                        <svg-icon
                            class="size-6 !p-0 lg:!hidden cursor-pointer m-4 ms-auto"
                            icon="close"
                            @click.stop="$emit('close')" />
                    </div>
                </div>

                <account-switcher @account-switched="$emit('close')" />

                <div class="[overflow:overlay]">
                    <ul class="col">
                        <sidebar-item
                            v-for="item in topItems"
                            :key="item.name"
                            :data-testid="camelCase(item.activeSidebarItemId)"
                            :item
                            :active-item
                            @on-click="onItemClick" />
                    </ul>
                </div>

                <div class="!mt-auto">
                    <ul class="col">
                        <sidebar-item
                            v-for="bottomItem in bottomItems"
                            :key="bottomItem.name"
                            :data-testid="
                                camelCase(bottomItem.activeSidebarItemId)
                            "
                            :item="bottomItem"
                            :active-item
                            @on-click="onItemClick" />
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

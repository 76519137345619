/*
 * This mixin is used when ALL the records of a resource are needed.
 * it takes an array of objects containing the request data, it will send the first request for all of them in parallel and see how many pages there are for each request.
 * then all of the rest of the requests will be sent in parallel.
 */

import axios from 'axios';
import qs from 'qs';
import { flatten } from 'lodash';

export default {
    methods: {
        fetchAll(requestsData) {
            return new Promise((resolve, reject) => {
                const requests = requestsData.map((requestData) => {
                    const filterQuery = qs.stringify(requestData);

                    const fullUrl = requestData.url + '?' + filterQuery;

                    return this.http().get(fullUrl);
                });

                axios.all(requests).then(
                    axios.spread((...args) => {
                        Promise.all(
                            requestsData.map((requestData, index) => {
                                return this.fetchItemsParallel(
                                    args[index].data,
                                    requestData,
                                    requestData.url
                                );
                            })
                        )
                            .then((all) => {
                                resolve(all);
                            })
                            .catch((err) => reject(err));
                    })
                );
            });
        },
        fetchItemsParallel(lastResponse, requestData, uri) {
            return new Promise((resolve) => {
                if (lastResponse.meta.last_page === 1) {
                    resolve(lastResponse.data);
                    return;
                }
                const pagesCount = lastResponse.meta.last_page - 1;

                const requests = Array(pagesCount)
                    .fill(1)
                    .map((i, index) => {
                        return this.http().get(
                            uri +
                                '?' +
                                qs.stringify({
                                    ...requestData,
                                    page: index + 2, // 2 because we already have the first page loaded
                                })
                        );
                    });

                axios.all(requests).then(
                    axios.spread((...args) => {
                        resolve([
                            ...lastResponse.data,
                            ...flatten(
                                args.map((response) => response.data.data)
                            ),
                        ]);
                    })
                );
            });
        },
    },
};

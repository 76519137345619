import { getEnumObject } from '../utilities.js';

export const integrationActions = {
    GET_TRANSACTIONS: 'GET_TRANSACTIONS',
    GET_TRANSACTION: 'GET_TRANSACTION',
    MT940_LIST: 'MT940_LIST',
    MT940_STATEMENT: 'MT940_STATEMENT',
    MT942_STATEMENT: 'MT942_STATEMENT',
    ACCOUNT_BALANCE: 'ACCOUNT_BALANCE',
    TRANSFER_PAYMENT: 'TRANSFER_PAYMENT',
    ACCESS_TOKEN: 'ACCESS_TOKEN',
    GET_PAYMENT_STATUS: 'GET_PAYMENT_STATUS',
    SCREEN_MERCHANT: 'SCREEN_ORGANIZATION',
    SCREEN_INDIVIDUAL: 'SCREEN_INDIVIDUAL',
    SCREEN_PAYMENT: 'SCREEN_PAYMENT',
    SCREEN_TRANSACTION: 'SCREEN_TRANSACTION',
    RISK_SCORE_INDIVIDUAL: 'RISK_SCORE_INDIVIDUAL',
    QUERY_SCREENED_REFERENCE: 'QUERY_SCREENED_REFERENCE',
    MONTHLY_REPORT: 'MONTHLY_REPORT',
    QUARTERLY_REPORT: 'QUARTERLY_REPORT',
    LOAN_INSTALLMENT: 'LOAN_INSTALLMENT',
    ORDER_DETAILS: 'ORDER_DETAILS',
    LOAN_PAYMENT: 'LOAN_PAYMENT',
    CREATE_LOAN: 'CREATE_LOAN',
    CREATE_SUPPLY_INSTALLMENTS: 'CREATE_SUPPLY_INSTALLMENTS',
    VIEW_ACCOUNT: 'VIEW_ACCOUNT',
    CREATE_ACCOUNT: 'CREATE_ACCOUNT',
    UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
    RECEIVE_TRANSACTION_NOTIFICATION: 'RECEIVE_TRANSACTION_NOTIFICATION',
    WATHQ_VALIDATE_CR: 'WATHQ_VALIDATE_CR',
    WATHQ_VALIDATE_MANGER: 'WATHQ_VALIDATE_MANGER',
    WATHQ_FULL_INFO: 'WATHQ_FULL_INFO',
    YAKEEN_VERIFY_PHONE: 'YAKEEN_VERIFY_PHONE',
    NEARPAY_TRANSACTIONS: 'NEARPAY_TRANSACTIONS',
    NAFATH_SEND_REQUEST: 'NAFATH_SEND_REQUEST',
    NAFATH_CHECK_STATUS: 'NAFATH_CHECK_STATUS',
    NEARPAY_CREATE_RECONCILIATION: 'NEARPAY_CREATE_RECONCILIATION',
    NEARPAY_LIST_RECONCILIATIONS: 'NEARPAY_LIST_RECONCILIATIONS',
    NEARPAY_FIND_RECONCILIATION: 'NEARPAY_FIND_RECONCILIATION',
    NEARPAY_LIST_TRANSACTIONS: 'NEARPAY_LIST_TRANSACTIONS',
    NEARPAY_FIND_TRANSACTION: 'NEARPAY_FIND_TRANSACTION',
    TRANSACTIONS_REPORT: 'TRANSACTIONS_REPORT',
    TRANSACTIONS_AGGREGATE_REPORT: 'TRANSACTIONS_AGGREGATE_REPORT',
    PAYMENTS_REPORT: 'PAYMENTS_REPORT',
    VERIFIED_MERCHANTS: 'VERIFIED_MERCHANTS',
    ZATCA_REQUEST_COMPLIANCE_CERTIFICATE: 'REQUEST_COMPLIANCE_CERTIFICATE',
    ZATCA_REQUEST_PRODUCTION_CERTIFICATE: 'REQUEST_PRODUCTION_CERTIFICATE',
    ZATCA_SUBMIT_COMPLIANCE_INVOICE: 'SUBMIT_COMPLIANCE_INVOICE',
    ZATCA_SUBMIT_STANDARD_INVOICE: 'SUBMIT_STANDARD_INVOICE',
    INTERSOFT_SUPPORT: 'INTERSOFT_SUPPORT',
    WHO_AM_I: 'WHO_AM_I',
    REPORT_OVERVIEW: 'REPORT_OVERVIEW',
    REQUEST_LOAN: 'REQUEST_LOAN',
    SUBMIT_TRANSACTIONS: 'SUBMIT_TRANSACTIONS',
    SUBMIT_RECONCILIATION: 'SUBMIT_RECONCILIATION',
};

export default getEnumObject(integrationActions);

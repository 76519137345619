const PaymentsList = () => import('../Screens/Payments/List/PaymentsList.vue');
const PaymentView = () => import('../Screens/Payments/View/PaymentView.vue');

export default [
    {
        path: '/payments',
        meta: {
            requireAuth: true,
            requireApproval: true,
            activeSidebarItemId: 'payments',
        },
        children: [
            {
                path: '',
                name: 'payments',
                component: PaymentsList,
                meta: {
                    mixpanelPageName: 'Payouts List',
                    pageTitle: 'payouts_list',
                },
            },
            {
                path: ':id',
                name: 'payment-view',
                component: PaymentView,
                props: true,
                meta: {
                    mixpanelPageName: 'Payout Details',
                    pageTitle: 'payout_details',
                },
            },
        ],
    },
];

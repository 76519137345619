import { getEnumObject } from '../utilities.js';

export const cardSchemes = {
    UNKNOWN: 0,
    AMERICAN_EXPRESS: 1,
    DISCOVER: 2,
    MAESTRO: 3,
    GCCNET: 4,
    JCB: 5,
    MASTER_CARD: 6,
    MADA: 7,
    SH: 8,
    UNIONPAY: 9,
    VISA_CREDIT: 10,
    VISA_DEBIT: 11,
};

export const tmsCardSchemes = {
    MADA: 1,
    CREDIT_CARD: 2,
    AMERICAN_EXPRESS: 3,
    OTHER: 4,
};

export const cardSchemesImages = {
    [cardSchemes.AMERICAN_EXPRESS]: 'amex.png',
    [cardSchemes.DISCOVER]: 'discover.png',
    [cardSchemes.MAESTRO]: 'maestro.png',
    [cardSchemes.GCCNET]: 'gccnet.png',
    [cardSchemes.JCB]: 'jcb.png',
    [cardSchemes.MASTER_CARD]: 'mastercard.png',
    [cardSchemes.MADA]: 'mada.png',
    [cardSchemes.UNIONPAY]: 'unionpay.png',
    [cardSchemes.VISA_CREDIT]: 'visa.png',
    [cardSchemes.VISA_DEBIT]: 'visa.png',
};

export const otherCardSchemeImages = {
    [cardSchemes.GCCNET]: 'gccnet-icon.png',
    [cardSchemes.MAESTRO]: 'maestro-icon.png',
    [cardSchemes.UNIONPAY]: 'unionpay-icon.png',
    [cardSchemes.DISCOVER]: 'discover-icon.png',
};

export const tmsCardSchemesImages = {
    [tmsCardSchemes.MADA]: 'images/card-schemes/tms/mada.png',
    [tmsCardSchemes.CREDIT_CARD]: 'images/card-schemes/tms/credit.png',
    [tmsCardSchemes.AMERICAN_EXPRESS]: 'images/card-schemes/tms/amex.png',
    [tmsCardSchemes.OTHER]: 'icons/language.svg',
};

export default getEnumObject(cardSchemes);

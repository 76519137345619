class MainStore {
    from;
    to;
    isCustomerPortal;
    appBaseUrl;
    fullPathesRouteHistory;

    constructor() {
        this.from = null;
        this.to = null;
        this.isCustomerPortal = true;
        this.appBaseUrl = '/cp_internal';
        this.fullPathesRouteHistory = [];
    }

    setFromTo(to, from) {
        this.to = to;
        this.from = from;
    }

    cachePrevFullPath() {
        if (this.to.fullPath) {
            this.fullPathesRouteHistory.push(this.to.fullPath);
        }
    }

    removePrevFullPath() {
        this.fullPathesRouteHistory.pop();
    }
}

export default new MainStore();

<script setup>
import { useUserStore } from '@/customer-portal/js/Composables/useUserStore.js';
const userStore = useUserStore();
const { user } = userStore;
</script>
<template>
    <div>
        <div class="btn btn-white items-center p-1 gap-1 me-4 hidden sm:flex">
            <span>{{ $t('current_view') }}:</span>
            <span class="size-3 rounded-full bg-green-700 ms-1"> </span>
            <span class="font-medium">{{ user.merchant.reference }}</span>
        </div>
        <new-tooltip
            has-icon
            icon-name="visibility"
            icon-classes="size-8"
            class="me-4 flex sm:hidden"
            :help-text="`${$t('current_view')}: ${user.merchant.reference}`">
        </new-tooltip>
    </div>
</template>

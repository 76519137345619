<script>
import moment from 'moment';

export default {
    props: {
        modelValue: {
            type: String,
            default: '',
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            currentTimeString: '',
        };
    },
    computed: {
        getMin() {
            const mins = [];
            for (let i = 0; i < 60; i += 5) {
                mins.push(i);
            }
            return mins;
        },
        hour: {
            get() {
                return this.currentTimeString.split(':')[0];
            },
            set(value) {
                this.currentTimeString = `${value}:${this.minutes}`;
            },
        },
        minutes: {
            get() {
                return this.currentTimeString.split(':')[1];
            },
            set(value) {
                this.currentTimeString = `${this.hour}:${value}`;
            },
        },
    },
    watch: {
        currentTimeString(value) {
            this.$emit('update:modelValue', value);
        },
    },
    mounted() {
        this.adjustTime();
    },
    methods: {
        adjustTime() {
            const m = this.modelValue
                ? moment(this.modelValue, 'HH:mm')
                : moment();
            m.add(15, 'minutes');

            const min = m.format('m');
            this.currentTimeString = m.format('HH:mm');

            if (min > 55) {
                this.changeHour(true);
            }

            const adjusted = Math.ceil(min / 5) * 5;
            this.minutes =
                adjusted >= 60
                    ? '00'
                    : adjusted.toString().length === 1
                      ? `0${adjusted}`
                      : adjusted;
        },
        changeHour(isAdd) {
            if (isAdd) {
                this.currentTimeString = moment(
                    moment().format('YYYY-MM-DD ') + this.currentTimeString
                )
                    .add(1, 'hours')
                    .format('HH:mm');
            } else {
                this.currentTimeString = moment(
                    moment().format('YYYY-MM-DD ') + this.currentTimeString
                )
                    .subtract(1, 'hours')
                    .format('HH:mm');
            }
        },
        changeMinutes(isAdd) {
            if (isAdd) {
                this.currentTimeString = moment(
                    moment().format('YYYY-MM-DD ') + this.currentTimeString
                )
                    .add(1, 'minutes')
                    .format('HH:mm');
            } else {
                this.currentTimeString = moment(
                    moment().format('YYYY-MM-DD ') + this.currentTimeString
                )
                    .subtract(1, 'minutes')
                    .format('HH:mm');
            }
        },
        formattedHourValue(hour) {
            hour = hour.toString();
            hour = hour.length === 1 ? `0${hour}` : hour;
            return moment(moment().format('YYYY-MM-DD ') + hour + ':00').format(
                'HH'
            );
        },
        formattedHourText(hour) {
            hour = hour.toString();
            hour = hour.length === 1 ? `0${hour}` : hour;
            return moment(moment().format('YYYY-MM-DD ') + hour + ':00').format(
                'hh A'
            );
        },

        formattedMinutes(min) {
            min = min.toString();
            min = min.length === 1 ? `0${min}` : min;
            return moment(moment().format('YYYY-MM-DD ') + '00:' + min).format(
                'mm'
            );
        },
    },
};
</script>
<template>
    <div>
        <div class="flex justify-around py-4 items-center">
            <div>
                <select v-model="hour" class="bg-white">
                    <option
                        v-for="h in 24"
                        :key="h"
                        :value="formattedHourValue(h - 1)">
                        {{ formattedHourText(h - 1) }}
                    </option>
                </select>
            </div>
            <div>:</div>
            <div>
                <select v-model="minutes" class="bg-white">
                    <option
                        v-for="m in getMin"
                        :key="m"
                        :value="formattedMinutes(m)">
                        {{ formattedMinutes(m) }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import ErrorMessages from '../Validations/ErrorMessages/index.js';

export default {
    name: 'FdxFormItemContainer',
    props: {
        fieldValidations: {
            type: Object,
            default: null,
        },
        isDisabled: {
            type: Boolean,
        },
        label: {
            type: String,
            default: '',
        },
        extraLabelText: {
            type: String,
            default: '',
        },
        helpText: {
            type: String,
            default: '',
        },
        hideRequiredAsterisk: Boolean,
    },

    data() {
        return {
            staticErrorMessages: ErrorMessages,
        };
    },

    computed: {
        errorMessages() {
            if (!this.fieldValidations) {
                return [];
            }

            const silentErrors = this.fieldValidations.$silentErrors;
            const isCustomerPortal = this.$root.mainStore.isCustomerPortal;

            return isCustomerPortal
                ? this.formatErrorMessagesForCP(silentErrors)
                : this.formatErrorMessagesForMMS(silentErrors);
        },
        isFieldNotValidAndTouched() {
            const isTouched = Boolean(this.fieldValidations?.$dirty);
            const hasErrorMessages = Boolean(this.errorMessages.length);

            return isTouched && hasErrorMessages;
        },
        isFieldNotValid() {
            return this.errorMessages.length > 0;
        },
        isRequired() {
            return Boolean(this.fieldValidations?.required);
        },
        showRequiredAsterisk() {
            return this.isRequired && !this.hideRequiredAsterisk;
        },
    },

    methods: {
        formatErrorMessagesForCP(errors) {
            return errors.map((error) => this.$t(error.$message));
        },

        formatErrorMessagesForMMS(errors) {
            return errors.map((error) => {
                if (error.$message) {
                    return error.$message;
                }

                return this.staticErrorMessages[error.$validator](
                    error.$params
                );
            });
        },
    },
};
</script>

<template>
    <div
        class="mb-4 flex flex-wrap items-center form-item-container"
        :class="{ disabled: isDisabled }">
        <div class="mb-1 w-full flex justify-between items-center">
            <div class="flex items-center">
                <slot name="custom_label">
                    <label class="text-footnote me-1">
                        <span v-if="label"> {{ $t(label) }} </span>
                        <span v-if="extraLabelText" v-text="extraLabelText" />
                        <span v-if="showRequiredAsterisk" class="text-red ms-1">
                            *
                        </span>
                    </label>
                </slot>
                <new-tooltip v-if="helpText" has-icon :help-text />
            </div>

            <slot name="labelRight" />
        </div>

        <div class="w-full">
            <slot :is-field-not-valid-and-touched :is-field-not-valid></slot>
        </div>

        <div
            class="transition-[height]"
            :class="isFieldNotValidAndTouched ? 'h-auto' : 'h-0'">
            <transition name="slide-down">
                <template v-if="isFieldNotValidAndTouched">
                    <div class="mt-1 text-red-600">
                        <ul>
                            <li
                                v-for="error in errorMessages"
                                :key="error"
                                v-text="error" />
                        </ul>
                    </div>
                </template>
            </transition>
        </div>
    </div>
</template>

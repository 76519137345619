import { getEnumObject } from '../utilities.js';

export const adjustmentStatuses = {
    NEW: 1,
    APPLIED: 2,
    CANCELLED: 3,
    LINKED: 4,
    PARTIALLY_APPLIED: 5,
};

export const adjustmentStatusesColors = {
    [adjustmentStatuses.NEW]: 'bg-blue-400',
    [adjustmentStatuses.APPLIED]: 'bg-green-700',
    [adjustmentStatuses.CANCELLED]: 'bg-red-500',
    [adjustmentStatuses.LINKED]: 'bg-amber-500',
    [adjustmentStatuses.PARTIALLY_APPLIED]: 'bg-green-400',
};

export default getEnumObject(adjustmentStatuses, adjustmentStatusesColors);

import { inject } from 'vue';
import { useRouter } from 'vue-router';

const navigateToPage = (route, withCtrl, mainStore, router) => {
    if (withCtrl) {
        mainStore?.value?.cachePrevFullPath();
    }
    if (typeof route === 'string' || route instanceof String) {
        window.open(route, withCtrl ? '_blank' : '_self');
    } else if (withCtrl) {
        const routeData = router.resolve(route.path ?? route);
        window.open(routeData.href, '_blank');
    } else {
        router.push(route.path ?? route);
    }
};

export const useNavigateToPage = () => {
    const mainStore = inject('mainStore');
    const router = useRouter();

    return {
        navigateToPage: (route, withCtrl = false) =>
            navigateToPage(route, withCtrl, mainStore, router),
    };
};

<!-- eslint-disable vue/require-default-prop -->
<script>
import qs from 'qs';
import ScrollFixer from '../Mixins/scrollFixer.js';
import fetchAllMixin from '../Mixins/fetchAllMixin.js';
import ErrorMessages from '../Validations/ErrorMessages/index.js';
import {
    get,
    isEqual,
    reject,
    includes,
    cloneDeep,
    pull,
    clone,
    filter,
    find,
    uniqBy,
    chunk,
    flatten,
} from 'lodash';
import { Bus } from '../bus.js';

export default {
    mixins: [ScrollFixer, fetchAllMixin],
    props: {
        modelValue: {
            type: null,
        },
        valueFormatter: {
            type: null,
        },
        url: {
            type: null,
        },
        urlFilters: {
            type: null,
        },
        multiSelect: {
            type: null,
        },
        placeholder: {
            type: null,
        },
        data: {
            type: null,
        },
        ignoredIds: {
            type: null,
        },
        customFilter: {
            type: null,
        },
        hasDeselect: {
            type: null,
        },
        hasSelectAll: {
            type: Boolean,
        },
        include: {
            type: null,
        },
        fullSearchableString: {
            type: null,
        },
        namespace: {
            type: null,
        },
        forceRefetch: {
            type: Boolean,
            default: true,
        },
        forceLtr: {
            type: null,
        },
        isOptionDisabled: {
            type: Function,
        },
        isOptionHidden: {
            type: Function,
        },
        searchTermKey: {
            type: String,
            default: 'text',
        },
        sortKey: {
            type: String,
            default: '',
        },
        allowSearch: {
            type: Boolean,
            default: true,
        },
        property: {
            type: String,
            default: '',
        },
        vuelidateObj: {
            type: Object,
            default: () => ({}),
        },
        showValidationsErrors: {
            type: Boolean,
        },
        isFixed: {
            type: Boolean,
            default: true,
        },
        showCustomErrorMsg: {
            type: Boolean,
        },
        customErrorMsg: {
            type: String,
            default: '',
        },
        hasSearchProperty: {
            type: Boolean,
        },
        supplementRequestData: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['update:modelValue', 'options-ready', 'selected-options'],
    data() {
        return {
            fetching: false,
            options: [],
            selectedOptionsIds: [],
            searchTerm: '',
            selectedOptionIndex: 0,
            isShown: false,
            timeoutId: null,
            filtering: false,
            isFullyLoaded: {}, //indicates the initial load has fetched all items so remote won't be triggered // will be an object to work well with array of urls
            errorMessages: ErrorMessages,
            hiddenLabelsCount: 0,
        };
    },
    computed: {
        isFieldNotValid() {
            return (
                this.vuelidateObj?.fields &&
                get(this.vuelidateObj.fields, this.property) &&
                get(this.vuelidateObj.fields, this.property).$dirty &&
                get(this.vuelidateObj.fields, this.property).$silentErrors
                    ?.length
            );
        },
        optionsToList() {
            const options = this.customFilter
                ? this.customFilter(this.options)
                : this.options;

            const optionsToList = reject(options, (option) => {
                if (includes(this.ignoredIds, option.id)) {
                    return true;
                }

                if (this.searchTerm) {
                    const searchableString = this.fullSearchableString
                        ? this.fullSearchableString(option)
                        : this.valueFormatter(option);
                    return (
                        this.optionIsAdded(option) ||
                        searchableString
                            .toLowerCase()
                            .trim()
                            .indexOf(this.searchTerm.toLowerCase().trim()) ===
                            -1
                    );
                }
                return this.optionIsAdded(option);
            });

            if (this.url) {
                return optionsToList;
            }

            return optionsToList.sort((o1, o2) => {
                if (this.searchTerm) {
                    if (
                        o1.name &&
                        o1.name.toLowerCase() ===
                            this.searchTerm.toLowerCase().trim()
                    ) {
                        return -1;
                    }
                    if (
                        o2.name &&
                        o2.name.toLowerCase() ===
                            this.searchTerm.toLowerCase().trim()
                    ) {
                        return 1;
                    }
                    return 0;
                }
                return 0;
            });
        },
        noOptionIsSelected() {
            return Array.isArray(this.selectedOptionsIds)
                ? this.selectedOptionsIds.length === 0
                : [null, undefined, ''].includes(this.selectedOptionsIds);
        },
        isAllSelected() {
            return (
                Array.isArray(this.selectedOptionsIds) &&
                this.selectedOptionsIds.length === this.optionsToList.length
            );
        },
    },
    watch: {
        urlFilters: {
            deep: true,
            handler(val, oldVal) {
                if (val && oldVal && !isEqual(oldVal, val)) {
                    this.$emit('update:modelValue', '');
                    this.selectInputRefresh();
                }
            },
        },
        modelValue(val) {
            if (val === undefined || val === null || val === '') {
                this.markInputAsDirty();
                this.selectedOptionsIds = this.multiSelect ? [] : '';
                return;
            }
            if (val) {
                this.markInputAsTouch();
            }

            this.selectedOptionsIds = this.multiSelect
                ? Array.isArray(val)
                    ? val
                    : [val]
                : val;
        },
        searchTerm(value, oldValue) {
            if (
                (oldValue && value.trim() === oldValue.trim()) ||
                window.foodics?.guest
            ) {
                // dont trigger call if the change was only whitespaces or user is not logged in
                return;
            }
            this.selectedOptionIndex = 0;
            this.$nextTick(() => {
                if (this.timeoutId) {
                    clearTimeout(this.timeoutId);
                }
                if (value && typeof this.url !== 'undefined') {
                    this.filtering = true;
                    this.timeoutId = setTimeout(() => {
                        if (this.url instanceof Array) {
                            Promise.all(
                                this.url.map((u) =>
                                    this.triggerFilterRequest(u)
                                )
                            ).then(() => {
                                this.$emit('options-ready', {
                                    options: this.options,
                                });
                            });
                        } else {
                            this.triggerFilterRequest(this.url).then(() => {
                                this.$emit('options-ready', {
                                    options: this.options,
                                });
                            });
                        }
                    }, 500);
                }
            });
        },
        data() {
            //to handle the case where select input data are being loaded async
            this.options = cloneDeep(this.data);
        },
        url: {
            deep: true,
            handler(val, oldVal) {
                if (val && oldVal && !isEqual(oldVal, val)) {
                    this.selectedOptionsIds = [];
                    this.selectInputRefresh();
                }
            },
        },
        isShown(val) {
            if (val) {
                this.$nextTick(() => this.adjustSize());
            }
        },
    },
    mounted() {
        if (this.modelValue) {
            this.selectedOptionsIds = this.modelValue;
        } else {
            this.selectedOptionsIds = this.multiSelect ? [] : '';
        }

        if (this.namespace) {
            Bus.$on(`${this.namespace}:select-input-refetch`, () => {
                this.selectInputRefresh();
            });

            Bus.$on(
                `${this.namespace}:reset-search-term`,
                this.resetSearchTerm
            );
        }
        if (this.data) {
            this.options = cloneDeep(this.data);
        } else {
            if (this.url instanceof Array) {
                Promise.all(
                    this.url.map((u) => this.fetchItems(u, this.forceRefetch))
                ).then(() => {
                    this.handleMissingSelectedOption().then(() => {
                        this.fetching = false;
                        this.$emit('options-ready', { options: this.options });
                    });
                });
            } else {
                this.fetchItems(this.url, this.forceRefetch).then(() => {
                    this.handleMissingSelectedOption().then(() => {
                        this.fetching = false;
                        this.$emit('options-ready', { options: this.options });
                    });
                });
            }
        }
        if (this.multiSelect) {
            this.$nextTick(() => {
                this.checkSelectedLabelVisibility();
            });
        }

        window.addEventListener('resize', this.checkSelectedLabelVisibility);
    },
    beforeUnmount() {
        if (this.namespace) {
            Bus.$off(`${this.namespace}:select-input-refetch`);
            Bus.$off(`${this.namespace}:reset-search-term`);
        }

        window.removeEventListener('resize', this.checkSelectedLabelVisibility);
    },
    methods: {
        selectInputRefresh() {
            this.options = [];
            this.fetchItems(this.url, true).then(() => {
                this.handleMissingSelectedOption().then(() => {
                    this.fetching = false;
                    this.$emit('options-ready', { options: this.options });
                });
            });
        },
        markInputAsTouch() {
            if (
                this.vuelidateObj?.fields &&
                get(this.vuelidateObj.fields, this.property)
            ) {
                get(this.vuelidateObj.fields, this.property).$touch();
            }
        },
        markInputAsDirty() {
            if (
                this.vuelidateObj?.fields &&
                get(this.vuelidateObj.fields, this.property)
            ) {
                get(this.vuelidateObj.fields, this.property).$reset();
            }
        },
        fetchItems(url, forced = false) {
            //forced will force a refetch and skip checking the meta cache
            const query = qs.stringify({
                filter: {
                    ...(this.urlFilters ? this.urlFilters : {}),
                },
                sort: `${this.sortKey ? this.sortKey : this.searchTermKey}`,
                page: 1,
                include:
                    this.url instanceof Array
                        ? this.include[url]
                        : this.include,
                ...this.supplementRequestData,
            });
            return new Promise((resolve) => {
                if (
                    this.$route.meta.cache &&
                    this.$route.meta.cache[query] &&
                    !forced
                ) {
                    this.options = this.options.concat(
                        this.$route.meta.cache[query]
                    );
                    this.isFullyLoaded[url] =
                        this.$route.meta.cache[query].length < 50;
                    resolve();
                    return;
                }

                this.fetching = true;

                this.http()
                    .get(`${this.url}?` + query)
                    .then((response) => {
                        if (!this.$route.meta.cache) {
                            this.$route.meta.cache = {};
                        }
                        this.$route.meta.cache[query] = response.data.data;
                        this.options = forced
                            ? response.data.data
                            : this.options.concat(response.data.data);
                        this.isFullyLoaded[url] =
                            this.checkIfResponseFullyLoaded(response.data);
                        resolve();
                    });
            });
        },
        /**
         * Add the selected option.
         */
        addSelection(option) {
            // filter-chips
            if (option.id !== null && this.url) {
                Bus.$emit('select-input:choose-option', {
                    [option.id]: this.valueFormatter(option),
                });
            }

            if (this.multiSelect) {
                if (!this.selectedOptionsIds) {
                    this.selectedOptionsIds = [];
                }

                this.selectedOptionsIds.push(option.id);

                this.$refs.input?.focus();
                this.$nextTick(() => {
                    this.checkSelectedLabelVisibility();
                });

                this.$emit('update:modelValue', this.selectedOptionsIds);
                this.$emit('selected-options', this.selectedOptions());
            } else {
                this.$emit('update:modelValue', option.id);
                Bus.$emit('close-dropdowns');
                this.$emit('selected-options', [option]);
            }
        },
        selectAllOptions() {
            // filter-chips
            if (this.url) {
                Bus.$emit(
                    'select-input:choose-option',
                    this.options.reduce((acc, option) => {
                        acc[option.id] = this.valueFormatter(option);
                        return acc;
                    }, {})
                );
            }

            const filteredOptions = this.optionsToList ?? this.options;
            this.selectedOptionsIds = filteredOptions.map(
                (option) => option.id
            );

            this.$emit('update:modelValue', this.selectedOptionsIds);

            if (this.multiSelect) {
                this.$refs.input.focus();
                this.$nextTick(() => {
                    this.checkSelectedLabelVisibility();
                });
            } else {
                Bus.$emit('close-dropdowns');
            }
        },
        /**
         * Remove the option.
         */
        removeSelection(option) {
            this.selectedOptionsIds = pull(
                clone(this.selectedOptionsIds),
                option.id
            );

            this.$emit('update:modelValue', this.selectedOptionsIds);

            if (this.multiSelect) {
                this.$nextTick(() => {
                    this.checkSelectedLabelVisibility();
                });
            } else {
                Bus.$emit('close-dropdowns');
            }
        },
        removeAllOptions() {
            this.selectedOptionsIds = [];

            this.$emit('update:modelValue', this.selectedOptionsIds);

            if (this.multiSelect) {
                this.$nextTick(() => {
                    this.checkSelectedLabelVisibility();
                });
            } else {
                Bus.$emit('close-dropdowns');
            }
        },
        /**
         * Determine if the option is selected.
         */
        optionIsAdded(option) {
            return this.selectedOptionsIds instanceof Array
                ? false
                : this.selectedOptionsIds === option.id ||
                      this.selectedOptionsIds === option.id?.toString();
        },
        /**
         * Select the next option.
         */
        selectNextOption() {
            if (!this.optionsToList.length) {
                return;
            }

            if (this.selectedOptionIndex + 1 == this.optionsToList.length) {
                return;
            }

            this.selectedOptionIndex = this.selectedOptionIndex + 1;

            this.fixScroll(
                this.$refs.selectInputContent,
                this.selectedOptionIndex
            );
        },
        /**
         * Select the previous option.
         */
        selectPreviousOption() {
            if (!this.optionsToList.length) {
                return;
            }

            if (this.selectedOptionIndex === 0) {
                return;
            }

            this.selectedOptionIndex = this.selectedOptionIndex - 1;

            this.fixScroll(
                this.$refs.selectInputContent,
                this.selectedOptionIndex
            );
        },
        /**
         * Add the selected option to the list.
         */
        addSelectedOption() {
            if (!this.optionsToList[this.selectedOptionIndex]) {
                return;
            }

            this.addSelection(this.optionsToList[this.selectedOptionIndex]);
        },
        /**
         * Runs while the dropdown menu is appearing.
         */
        activatingDropdown() {
            this.selectedOptionIndex = 0;

            this.$nextTick(() => {
                if (this.$refs.input) {
                    this.$refs.input.focus();
                }
            });
        },
        selectedOptions() {
            const options = this.customFilter
                ? this.customFilter(this.options)
                : this.options;

            return this.selectedOptionsIds instanceof Array
                ? filter(options, (option) =>
                      includes(this.selectedOptionsIds, option.id)
                  )
                : [
                      find(
                          options,
                          // to avoid having value as string when it passed from query params
                          (option) =>
                              option.id === this.selectedOptionsIds ||
                              option.id?.toString() === this.selectedOptionsIds
                      ),
                  ];
        },
        selectAll() {
            if (this.isAllSelected) {
                this.removeAllOptions();
                return;
            }
            this.selectAllOptions();
        },
        triggerFilterRequest(url) {
            const queryObject = {
                filter: {
                    ...this.urlFilters,
                },
                sort: `${this.sortKey ? this.sortKey : this.searchTermKey}`,
                page: 1,
                include:
                    this.url instanceof Array
                        ? this.include[url]
                        : this.include,
            };

            if (this.hasSearchProperty) {
                queryObject['search'] = this.searchTerm.trim();
            } else {
                queryObject.filter[this.searchTermKey] = this.searchTerm.trim();
            }

            const query = qs.stringify(queryObject);

            return new Promise((resolve) => {
                if (this.isFullyLoaded[url]) {
                    resolve();
                    this.filtering = false;
                    return;
                }
                this.http()
                    .get(`${this.url}?${query}`)
                    .then((response) => {
                        if (response.data.data && response.data.data.length) {
                            this.options = uniqBy(
                                this.options.concat(response.data.data),
                                'id'
                            );
                        }
                        this.filtering = false;
                        resolve();
                    });
            });
        },
        checkIfResponseFullyLoaded(response) {
            if (response.meta) {
                return response.data.length === response.meta.total;
            }
            return response.data.length < 50;
        },
        handleMissingSelectedOption() {
            // handles the case where the initially loaded data does not contain a previously selected option.
            return new Promise((resolve) => {
                if (!this.modelValue) {
                    resolve();
                    return;
                }
                if (
                    Array.isArray(this.modelValue) ||
                    typeof this.modelValue === 'object'
                ) {
                    const ids = Array.isArray(this.modelValue)
                        ? this.modelValue
                        : Object.keys(this.modelValue).map(
                              (key) => this.modelValue[key]
                          );
                    const optionIds = this.options.map((option) => option.id);
                    const missingOptionIds = ids.filter(
                        (id) => !optionIds.includes(id)
                    );

                    if (missingOptionIds.length) {
                        if (Array.isArray(this.url)) {
                            Promise.all(
                                this.url.map((url) =>
                                    this.fetchAll(
                                        chunk(missingOptionIds, 50).map(
                                            (idsChunk) => ({
                                                url: url,
                                                include:
                                                    this.url instanceof Array
                                                        ? this.include[url]
                                                        : this.include,
                                                filter: {
                                                    id: idsChunk,
                                                },
                                            })
                                        )
                                    )
                                )
                            ).then((all) => {
                                this.options = [
                                    ...this.options,
                                    ...flatten(all.map((arr) => flatten(arr))),
                                ];
                                resolve();
                                this.fetching = false;
                            });
                        } else {
                            this.fetching = true;
                            this.fetchAll(
                                chunk(missingOptionIds, 50).map((idsChunk) => ({
                                    url: this.url,
                                    include:
                                        this.url instanceof Array
                                            ? this.include[this.url]
                                            : this.include,
                                    filter: {
                                        id: idsChunk,
                                    },
                                }))
                            ).then((all) => {
                                this.options = [
                                    ...this.options,
                                    ...flatten(all),
                                ];
                                resolve();
                                this.fetching = false;
                            });
                        }
                    } else {
                        resolve();
                    }
                } else if (
                    this.modelValue &&
                    !this.options.find(
                        (option) => option.id === this.modelValue
                    ) &&
                    this.modelValue !== 'open'
                ) {
                    // open is the ID of an open dicount that should not be considered missing option

                    this.fetching = true;

                    const urls = Array.isArray(this.url)
                        ? this.url
                        : [this.url]; // normalize URL to be array
                    Promise.all(
                        urls.map(() =>
                            this.http().get(`${this.url}/${this.modelValue}`)
                        )
                    )
                        .then((all) => {
                            all.map((response) => {
                                if (response && response.data.data) {
                                    this.options.push(response.data.data);
                                }
                            });
                        })
                        .catch(() => {
                            /** Do nothing */
                        })
                        .finally(() => {
                            resolve();
                            this.fetching = false;
                        });
                } else {
                    resolve();
                }
            });
        },
        checkSelectedLabelVisibility() {
            const container = this.$refs.selectInputContainer;
            const labels = this.$refs.labelRef;
            if (!container || !labels) {
                return;
            }

            let totalWidth = 0;
            this.hiddenLabelsCount = 0;
            // 90 is width of more span and 20 for padding
            const containerWidth = container.offsetWidth - 90 - 20;

            for (let i = 0; i < labels.length; i++) {
                labels[i].style.display = 'inline-block';
                // 10 margin end
                const labelWidth = labels[i].offsetWidth + 10;
                totalWidth += labelWidth;

                if (totalWidth > containerWidth) {
                    this.hiddenLabelsCount++;
                    labels[i].style.display = 'none';
                } else {
                    labels[i].style.display = 'inline-block';
                }
            }
        },
        adjustSize() {
            const containerCoords =
                this.$refs.selectInputContainer?.getBoundingClientRect();
            const ddContent = this.$refs.selectInputContentWrapper;
            const selectInput = this.$refs.selectInputContent;

            if (selectInput) {
                selectInput.style.maxWidth = containerCoords.width + 'px';

                const docHeight = document.documentElement.clientHeight;
                const ddContentCordinates = ddContent.getBoundingClientRect();
                const selectInputCordinates =
                    selectInput.getBoundingClientRect();

                if (
                    docHeight <
                    ddContentCordinates.y + ddContentCordinates.height
                ) {
                    const desiredHight =
                        docHeight +
                        selectInputCordinates.height -
                        (ddContentCordinates.y +
                            ddContentCordinates.height +
                            16);
                    selectInput.style.maxHeight = desiredHight + 'px';
                } else {
                    selectInput.style.maxHeight = '200px';
                }
            }
        },
        silentErrorsList(fields, property) {
            return get(fields, property);
        },
        resetSearchTerm() {
            this.searchTerm = '';
        },
    },
};
</script>

<template>
    <div>
        <drop-down
            class="font-normal"
            dropdown-content-classes="!min-w-0 p-2"
            @showing="activatingDropdown"
            @is-shown="(payload) => (isShown = payload)">
            <template #trigger>
                <div
                    :ref="'selectInputContainer'"
                    class="input p-2 relative"
                    :class="{
                        'border-primary': isShown,
                        'i-ltr': forceLtr,
                        invalid: isFieldNotValid || showCustomErrorMsg,
                        'overflow-hidden whitespace-nowrap': multiSelect,
                    }"
                    style="cursor: default">
                    <template v-if="fetching">
                        <span> {{ $t('select_input.loading') }} </span>
                    </template>

                    <template v-else>
                        <template v-if="noOptionIsSelected">
                            <span>
                                {{ placeholder || $t('select_input.choose') }}
                            </span>
                        </template>

                        <template v-if="selectedOptionsIds !== null">
                            <template v-if="Array.isArray(selectedOptionsIds)">
                                <span
                                    v-for="(option, index) in selectedOptions()"
                                    :key="index"
                                    :ref="'labelRef'"
                                    :class="['dropdown-selected-badge']"
                                    @click.stop="removeSelection(option)">
                                    {{ valueFormatter(option) }}
                                </span>

                                <span
                                    v-if="hiddenLabelsCount > 0"
                                    class="dropdown-selected-more-badge">
                                    {{ `+${hiddenLabelsCount} more...` }}
                                </span>
                            </template>
                            <template v-else>
                                <p class="truncate">
                                    {{
                                        ![null, undefined, ''].includes(
                                            valueFormatter(selectedOptions()[0])
                                        )
                                            ? valueFormatter(
                                                  selectedOptions()[0]
                                              )
                                            : '&nbsp;'
                                    }}
                                </p>
                            </template>
                        </template>
                    </template>
                    <div
                        class="absolute ltr:unset-left ltr:right-2 rtl:unset-right rtl:left-2 top-2">
                        <svg-icon class="w-3 h-3" icon="select-input-arrow" />
                    </div>
                </div>
            </template>
            <template #content>
                <div ref="selectInputContentWrapper">
                    <template v-if="!fetching">
                        <input
                            v-if="allowSearch"
                            ref="input"
                            v-model="searchTerm"
                            type="text"
                            class="w-full bg-white border border-gray-400 outline-none p-2 mb-2 block"
                            :placeholder="$t('select_input.start_searching')"
                            @keydown.arrow-down="selectNextOption"
                            @keydown.arrow-up="selectPreviousOption"
                            @keydown.enter="addSelectedOption" />

                        <div
                            ref="selectInputContent"
                            style="overflow: auto; max-height: 200px">
                            <a
                                v-if="
                                    !multiSelect &&
                                    hasDeselect &&
                                    !filtering &&
                                    (optionsToList.length ||
                                        (optionsToList.length === 0 &&
                                            modelValue))
                                "
                                href="#"
                                class="w-full block p-1 py-2 hover:bg-gray-100"
                                @mouseover="selectedOptionIndex = null"
                                @click.stop.prevent="
                                    addSelection({ id: null })
                                ">
                                {{ $t('select_input.none') }}
                            </a>

                            <a
                                v-if="
                                    optionsToList &&
                                    optionsToList.length &&
                                    hasSelectAll &&
                                    multiSelect
                                "
                                href="#"
                                class="w-full p-1 py-2 hover:bg-gray-100 flex cursor-pointer"
                                :class="{
                                    'bg-gray-100 ': selectedOptionIndex === -1,
                                }"
                                @mouseover="selectedOptionIndex = -1"
                                @click.stop.prevent="selectAll()">
                                <checkbox
                                    class="mx-2"
                                    :selected="isAllSelected" />
                                {{ $t('select_input.select_all') }}
                            </a>

                            <template v-for="(option, index) in optionsToList">
                                <a
                                    v-if="!isOptionHidden(option)"
                                    :key="index"
                                    href="#"
                                    class="w-full p-1 py-2 hover:bg-gray-100 flex items-baseline cursor-pointer break-all"
                                    :class="{
                                        'bg-gray-100':
                                            selectedOptionIndex === index,
                                        'i-ltr': forceLtr,
                                        disabled:
                                            option.disabled ||
                                            isOptionDisabled(option),
                                    }"
                                    @mouseover="selectedOptionIndex = index"
                                    @click.prevent="
                                        multiSelect
                                            ? Array.isArray(
                                                  selectedOptionsIds
                                              ) &&
                                              selectedOptionsIds.indexOf(
                                                  option.id
                                              ) !== -1
                                                ? removeSelection(option)
                                                : addSelection(option)
                                            : addSelection(option)
                                    ">
                                    <checkbox
                                        v-if="multiSelect"
                                        class="mx-2"
                                        :selected="
                                            Array.isArray(selectedOptionsIds) &&
                                            selectedOptionsIds.indexOf(
                                                option.id
                                            ) !== -1
                                        " />
                                    {{ valueFormatter(option) }}
                                </a>
                            </template>
                        </div>

                        <div v-if="!optionsToList.length">
                            <span v-if="filtering">
                                {{ $t('select_input.searching') }}
                            </span>
                            <slot
                                v-else
                                name="nothingFound"
                                :data="{ q: searchTerm }">
                                <span
                                    v-if="
                                        !(
                                            !multiSelect &&
                                            hasDeselect &&
                                            !filtering &&
                                            (optionsToList.length ||
                                                (optionsToList.length === 0 &&
                                                    modelValue))
                                        )
                                    "
                                    class="py-1 px-1">
                                    {{ $t('select_input.nothing_found') }}
                                </span>
                            </slot>
                        </div>
                    </template>

                    <template v-else>
                        <span class="py-1 px-4 font-light">
                            {{ $t('select_input.loading') }}
                        </span>
                    </template>
                </div>
            </template>
        </drop-down>

        <template v-if="isFieldNotValid && showValidationsErrors">
            <div class="mt-1 text-red-600">
                <ul>
                    <li
                        v-for="error in silentErrorsList(
                            vuelidateObj.fields,
                            property
                        ).$silentErrors"
                        :key="error.$uid">
                        {{
                            $root.mainStore.isCustomerPortal
                                ? $t(error.$message)
                                : errorMessages[error.$validator](error.$params)
                        }}
                    </li>
                </ul>
            </div>
        </template>
        <template v-if="showCustomErrorMsg && customErrorMsg">
            <div class="mt-1 text-red-600">
                {{ customErrorMsg }}
            </div>
        </template>
    </div>
</template>

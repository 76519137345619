import { getEnumObject } from '../utilities.js';

export const paymentStatuses = {
    NEW: 1,
    REQUESTED: 2,
    PROCESSED: 3,
    FAILED: 4,
    FAILED_NO_RESPONSE: 5,
    REGENERATED: 6,
    RETURNED: 7,
    CANCELLED: 8,
};

export default getEnumObject(paymentStatuses);

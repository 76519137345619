import { getEnumObject } from '../utilities.js';

export const mcc = {
    '742_VETERINARY_SERVICES': 742,
    '763_AGRICULTURAL_CO-OPERATIVE': 763,
    '780_LANDSCAPING_AND_HORTICULTURAL': 780,
    '1520_GENERAL_CONTRACTORS': 1520,
    '1711_HEATING_PLUMBING_AIRCON': 1711,
    '1731_ELECTRICAL_CONTRACTORS': 1731,
    '1740_MASONRY_AND_INSULATION': 1740,
    '1750_CARPENTRY': 1750,
    '1761_ROOFING,_SIDING_&_METAL_WORKS': 1761,
    '1771_CONTRACTORS_-_CONCRETE_WORK': 1771,
    '1799_SPECIAL_TRADE_CONTRACTORS': 1799,
    '2741_MISC_PUBLISHING_&_PRINTING': 2741,
    '2791_TYPESETTING/PLATE_MAKING_ETC': 2791,
    '2842_SPECIALTY_CLEANING/POLISHING': 2842,
    '3000_UNITED_AIRLINES': 3000,
    '3004_TRANS_WORLD_AIRLINES': 3004,
    '3005_BRITISH_AIRWAYS': 3005,
    '3006_JAPAN_AIRLINES': 3006,
    '3007_AIR_FRANCE': 3007,
    '3008_LUFTHANSA': 3008,
    '3009_AIR_CANADA': 3009,
    '3010_KLM': 3010,
    '3012_QANTAS': 3012,
    '3013_ALITALIA_AIRLINES': 3013,
    '3014_SAUDI_ARABIAN_AIRLINES': 3014,
    '3015_SWISSAIR': 3015,
    '3016_SAS': 3016,
    '3020_AIR_INDIA': 3020,
    '3021_AIR_ALGERIA': 3021,
    '3022_PHILIPPINE_AIRLINES': 3022,
    '3024_PAKISTAN_INTERNATIONAL_AIRLINES': 3024,
    '3028_AIR_MALTA': 3028,
    '3031_OLYMPIC_AIRWAYS': 3031,
    '3037_EGYPT_AIRLINES': 3037,
    '3038_KUWAIT_AIRWAYS': 3038,
    '3040_GULF_AIR_(BAHRAIN)': 3040,
    '3048_ROYAL_AIR_MAROC': 3048,
    '3049_TUNIS_AIR': 3049,
    '3051_AUSTRIAN_AIRLINES': 3051,
    '3058_DELTA_AIRLINES': 3058,
    '3060_NORTHWEST': 3060,
    '3061_CONTINENTAL_AIRLINES': 3061,
    '3063_US_AIR': 3063,
    '3075_SINGAPORE_AIRLINES': 3075,
    '3077_THAI_AIRWAYS': 3077,
    '3082_KOREAN_AIRLINES': 3082,
    '3099_CATHAY_PACIFIC_AIRLINES': 3099,
    '3100_MALAYSIAN_AIRLINE_SYSTEM': 3100,
    '3102_IBERIA': 3102,
    '3111_BRITISH_MIDLAND': 3111,
    '3135_SUDAN_AIRWAYS': 3135,
    '3175_MIDDLE_EAST_AIR': 3175,
    '3192_IRAN_AIR': 3192,
    '3282_AIR_DJIBOUTI': 3282,
    '3292_CYPRUS_AIRWAYS': 3292,
    '3294_ETHIOPIAN_AIRLINES': 3294,
    '3295_KENYA_AIRWAYS': 3295,
    '3357_HERTZ_CORPORATION': 3357,
    '3366_BUDGET_RENT-A-CAR': 3366,
    '3387_ALAMO_RENT-A-CAR': 3387,
    '3389_AVIS_RENT-A-CAR': 3389,
    '3390_DOLLAR_RENT-A-CAR': 3390,
    '3393_NATIONAL_CAR_RENTAL': 3393,
    '3395_THRIFTY_RENT-A-CAR': 3395,
    '3501_HOLIDAY_INN': 3501,
    '3503_SHERATON': 3503,
    '3504_HILTON': 3504,
    '3505_TRUSTHOUSE_FORTE': 3505,
    '3509_MARRIOTT': 3509,
    '3512_INTER-CONTINENTAL': 3512,
    '3520_MERIDIAN': 3520,
    '3521_CREST_HOTELS': 3521,
    '3535_HILTON_INTERNATIONAL': 3535,
    '3570_FORUM_HOTELS': 3570,
    '3612_MOVENPICK_HOTELS': 3612,
    '3637_RAMADA_INN': 3637,
    '3640_HYATT': 3640,
    '3642_NOVOTEL': 3642,
    '3649_RADISSON_HOTELS': 3649,
    '4011_RAILROADS': 4011,
    '4111_LOCAL_COMMUTER_TRANSPORT': 4111,
    '4112_PASSENGER_RAILWAYS': 4112,
    '4119_AMBULANCE_SERVICES': 4119,
    '4121_TAXICABS_/_LIMOUSINES': 4121,
    '4131_BUS_LINES,_TOUR_BUSES': 4131,
    '4214_MOTOR_FREIGHT_CARRIERS': 4214,
    '4215_COURIER_SERVICES': 4215,
    '4225_PUBLIC_WAREHOUSING_AND_STORAGE': 4225,
    '4411_STEAMSHIP_/_CRUISE_LINES': 4411,
    '4457_BOAT_RENTALS_&_LEASES': 4457,
    '4468_MARINAS': 4468,
    '4511_AIRLINES,_AIR_CARRIERS': 4511,
    '4582_AIRPORTS,_FLYING_&_TERMINALS': 4582,
    '4722_TRAVEL_AGENCIES': 4722,
    '4761_TRANSPORTATION_ARRANGEMENT': 4761,
    '4784_TOLLS_AND_BRIDGE_FEES': 4784,
    '4789_TRANSPORTATION_SERVICES': 4789,
    '4812_TELECOMMUNICATION_EQUIPMENT': 4812,
    '4814_TELECOMMUNICATION_SERVICES': 4814,
    '4816_COMPUTER_NETWORK_/_INFORMATION': 4816,
    '4821_TELEGRAPH_SERVICES': 4821,
    '4829_MONEY_ORDER_-_REMITTANCE': 4829,
    '4899_CABLE/PAY_TV_SERVICES': 4899,
    '4900_UTILITIES,_ELEC,_GAS_&_WATER': 4900,
    '5013_MOTOR_VEHICLE_SUPPLY/NEW_PARTS': 5013,
    '5021_OFFICE/COMMERCIAL_FURNITURE': 5021,
    '5039_CONSTRUCTION_MATERIALS': 5039,
    '5044_OFFICE/PHOTO_EQUIPMENT': 5044,
    '5045_COMPUTERS/PERIPHERALS/SOFTWARE': 5045,
    '5046_COMMERCIAL_EQUIPMENT': 5046,
    '5047_MEDICAL/DENTAL/LAB_EQUIPMENTS': 5047,
    '5051_METAL_SERVICE_CENTERS/OFFICES': 5051,
    '5065_ELECTRICAL_PARTS_AND_EQUIPMENT': 5065,
    '5072_HARDWARE_EQUIPMENT/SUPPLIES': 5072,
    '5074_PLUMBING/HEATING_EQUIPMENT': 5074,
    '5085_INDUSTRIAL_SUPPLIES_-_DEF': 5085,
    '5094_PRECIOUS_STONES/METALS/JEWELRY': 5094,
    '5099_DURABLE_ODS': 5099,
    '5111_STATIONERY/OFFICE_SUPPLIES': 5111,
    '5122_DRUGS/DRUGGIST_SUNDRIES': 5122,
    '5131_PIECE_ODS/NOTIONS/DRY_ODS': 5131,
    '5137_UNIFORMS_&_COMMERCIAL_CLOTHING': 5137,
    '5139_COMMERCIAL_FOOTWEAR': 5139,
    '5169_CHEMICALS_AND_ALLIED_PRODUCTS': 5169,
    '5172_PETROLEUM_&_PETROLEUM_PRODUCTS': 5172,
    '5192_BOOKS,_PERIODICALS_&_NEWSPAPERS': 5192,
    '5193_FLORISTS_SUPPLIES,_NURSERY_STCK': 5193,
    '5198_PAINTS,_VARNISHES_AND_SUPPLIES': 5198,
    '5199_NONDURABLE_ODS': 5199,
    '5200_HOME_SUPPLY_WAREHOUSE_STORES': 5200,
    '5211_BUILDING_MATERIALS_STORES': 5211,
    '5231_GLASS_PAINTS_WALLPAPER_STORES': 5231,
    '5251_HARDWARE_STORES': 5251,
    '5261_NURSERIES_LAWN_GARDEN_STORES': 5261,
    '5271_MOBILE_HOME_DEALERS': 5271,
    '5300_WHOLESALE_CLUBS': 5300,
    '5309_DUTY_FREE_STORES': 5309,
    '5310_DISCOUNT_STORES': 5310,
    '5311_DEPARTMENT_STORES': 5311,
    '5331_VARIETY_STORES': 5331,
    '5399_MISCELLANEOUS': 5399,
    '5411_GROCERY_STORES,_SUPERMARKETS': 5411,
    '5422_FREEZ&_LOCKER_MEAT_PROVISIONERS': 5422,
    '5441_CANDY_&_CONFECTIONERY_STORES': 5441,
    '5451_DAIRY_PRODUCTS_STORES': 5451,
    '5462_BAKERIES': 5462,
    '5499_MISCELLANEOUS_FOOD_STORES': 5499,
    '5511_AUTOMOBILE_AND_TRUCK_DEALERS': 5511,
    '5521_AUTO_DEALERS_(USED_ONLY)': 5521,
    '5531_AUTO_&_HOME_SUPPLY_STORES': 5531,
    '5532_AUTOMOTIVE_TIRE_STORES': 5532,
    '5533_AUTOMOTIVE_PARTS,_ACCESSORIES': 5533,
    '5541_SERVICE_STATIONS': 5541,
    '5542_AUTOMATED_GASOLINE_DISPENSERS': 5542,
    '5551_BOAT_DEALERS': 5551,
    '5561_RECREATIONAL_&_UTILITY': 5561,
    '5571_MOTORCYCLE_DEALERS': 5571,
    '5592_MOTOR_HOME_DEALERS': 5592,
    '5598_SNOWMOBILE_DEALERS': 5598,
    '5599_MISCELLANEOUS_AUTO_DEALERS': 5599,
    '5611_MENS_CLOTHING_AND_ACCESSORY': 5611,
    '5621_WOMENS_READY-TO-WEAR_STORES': 5621,
    '5631_WOMENS_ACCESSORY_&_SPECIALTY': 5631,
    '5641_CHILDRENS_AND_INFANTS_WEAR': 5641,
    '5651_FAMILY_CLOTHING_STORES': 5651,
    '5655_SPORTS_AND_RIDING_APPAREL_STORE': 5655,
    '5661_SHOE_STORES': 5661,
    '5681_FURRIERS_AND_FUR_SHOPS': 5681,
    '5691_MENS_AND_WOMENS_CLOTHING': 5691,
    '5697_TAILORS_SEAMSTRESSES_MENDING': 5697,
    '5698_WIG_AND_TOUPEE_STORES': 5698,
    '5699_MISC_APPAREL_&_ACCESSORY_SHOPS': 5699,
    '5712_FURNITURE,_EXCEPT_APPLIANCES': 5712,
    '5713_FLOOR_COVERING_STORES': 5713,
    '5714_DRAPERY_&_UPHOLSTERY_STORES': 5714,
    '5718_FIREPLACES_&_ACCESSORIES_STORES': 5718,
    '5719_MISCELLANEOUS_HOME_FURNISHING': 5719,
    '5722_HOUSEHOLD_APPLIANCE_STORES': 5722,
    '5731_USED_MERCHANDISE_STORES': 5731,
    '5732_RADIO_TELEVISION_STEREO_SALES': 5732,
    '5733_MUSIC_STORES': 5733,
    '5734_COMPUTER_SOFTWARE_STORES': 5734,
    '5735_RECORD_SHOPS': 5735,
    '5811_CATERERS': 5811,
    '5812_EATING_PLACES,_RESTAURANTS': 5812,
    '5814_FAST_FOOD_RESTAURANTS': 5814,
    '5912_DRUG_STORES_&_PHARMACIES': 5912,
    '5921_PKG_STORES_-_BEER,_WINE,_LIQUOR': 5921,
    '5931_USED_&_SECONDHAND_MERCHANDISE': 5931,
    '5932_ANTIQUE_SHOPS': 5932,
    '5933_PAWN_SHOPS': 5933,
    '5935_WRECKING_AND_SALVAGE_YARDS': 5935,
    '5937_WRECKING_AND_SALVAGE_YARDS': 5937,
    '5940_BICYCLE_SHOPS': 5940,
    '5941_SPORTING_ODS_STORES': 5941,
    '5942_BOOK_STORES': 5942,
    '5943_STATIONERY_STORES': 5943,
    '5944_JEWELRY_STORES': 5944,
    '5945_HOBBY,_TOY,_&_GAME_SHOPS': 5945,
    '5946_CAMERA_&_PHOTOGRAPHIC_SUPPLY': 5946,
    '5947_GIFT,_CARD,_NOVELTY,_&_SOUVENIR': 5947,
    '5948_LUGGAGE_&_LEATHER_ODS_STORES': 5948,
    '5949_SEWING_AND_PIECE_ODS_STORES': 5949,
    '5950_GLASSWARE/CRYSTAL_STORES': 5950,
    '5960_DIRECT_MARKETING-INSURANCE_SVC': 5960,
    '5962_DIRECT_MKTG-TRAVEL-RELATED_SVC': 5962,
    '5963_DOOR-TO-DOOR_SALES': 5963,
    '5964_CATALOG_MERCHANT': 5964,
    '5965_COMBINATION_CATALOG_&_RETAIL': 5965,
    '5966_OUTBOUND_TELEMARKETING_MERCHANT': 5966,
    '5967_INBOUND_TELEMARKETING_MERCHANT': 5967,
    '5968_CONTINUITY/SUBSCRIPTION_MERCHNT': 5968,
    '5969_DIRECT_MARKETING_/_MARKETEERS': 5969,
    '5970_ARTISTS_SUPPLY_AND_CRAFT_SHOPS': 5970,
    '5971_ART_DEALERS_&_GALLERIES': 5971,
    '5972_STAMP_AND_COIN_STORES': 5972,
    '5973_RELIGIOUS_ODS_STORES': 5973,
    '5975_HEARING_AIDS_-_SALES,_SERVICE': 5975,
    '5976_ORTHOPEDIC_ODS': 5976,
    '5977_COSMETIC_STORES': 5977,
    '5978_TYPEWRITER_STORES': 5978,
    '5983_FUEL_DEALERS': 5983,
    '5992_FLORISTS': 5992,
    '5993_CIGAR_STORES_AND_STANDS': 5993,
    '5994_NEWS_DEALERS_&_NEWSSTANDS': 5994,
    '5995_PET_SHOPS,_FOODS_AND_SUPPLIES': 5995,
    '5996_SWIMMING_POOLS': 5996,
    '5997_ELECTRIC_RAZOR_STORES/SERVICE': 5997,
    '5998_TENT_AND_AWNING_SHOPS': 5998,
    '5999_MISCELLANEOUS&_SPECIALTY_RETAIL': 5999,
    '6010_FINANCIAL_INST/MANUAL_CASH_DISB': 6010,
    '6011_FINANCIAL_INST/AUTO_CASH_DISBRS': 6011,
    '6012_FINANCIAL_INSTITUTIONS-MERCHAND': 6012,
    '6051_NON-FINANCIAL_INST/FC/MO/TC': 6051,
    '6211_SECURITY_BROKERS/DEALERS': 6211,
    '6300_INSURANCE_SERVICES': 6300,
    '6513_REAL_ESTATE_AGENTS_MANAGER-RENT': 6513,
    '7011_HOTELS_&_RESORTS_-_LODGING': 7011,
    '7012_TIMESHARES': 7012,
    '7032_SPORTING_&_RECREATIONAL_CAMPS': 7032,
    '7033_TRAILER_PARKS_AND_CAMPGROUNDS': 7033,
    '7210_LAUNDRY,_CLEANING,_&_GARMENT': 7210,
    '7211_LAUNDRIES-FAMILY_AND_COMMERCIAL': 7211,
    '7216_DRY_CLEANERS': 7216,
    '7217_CARPET_AND_UPHOLSTERY_CLEANING': 7217,
    '7221_PHOTOGRAPHIC_STUDIOS': 7221,
    '7230_BARBER_&_BEAUTY_SHOPS': 7230,
    '7251_SHOE_REPAIR/SHINE/HAT_CLEANING': 7251,
    '7261_FUNERAL_SERVICES_&_CREMATORIES': 7261,
    '7273_DATING_SERVICES': 7273,
    '7276_TAX_PREPARATION_SERVICES': 7276,
    '7277_COUNSELING_SERVICES_-_ALL': 7277,
    '7278_BUYING_&_SHOPPING_SERVICES': 7278,
    '7296_RENTAL_CLOTHING/COSTUMES/UNIFRM': 7296,
    '7297_MASSAGE_PARLORS': 7297,
    '7298_HEALTH_AND_BEAUTY_SPAS': 7298,
    '7299_REAL_ESTATE_INVESTMENT': 7299,
    '7311_ADVERTISING_SERVICES': 7311,
    '7321_CONSUMER_CREDIT_REPORT_AGENCIES': 7321,
    '7333_COMMERCIAL_PHOTO/ART/GRAPHICS': 7333,
    '7338_QUICK_COPY,_REPRODUCTION_AND_BL': 7338,
    '7339_STENOGRAPHIC/SECRETARIAL_SVC': 7339,
    '7342_EXTERMINATING_AND_DISINFECTING': 7342,
    '7349_CLEANING_MAINTENANCE_JANITORIAL': 7349,
    '7361_EMPLOYMENT_AGENCIES_AND_TEMPORA': 7361,
    '7372_COMPUTER_AND_DATA_PROCESSING': 7372,
    '7375_INFORMATION_RETRIEVAL_SERVICES': 7375,
    '7379_COMPUTER_MAINTENANCE_AND_REPAIR': 7379,
    '7392_MANAGEMENT_CONSULTING': 7392,
    '7393_DETECTIVE_&_PROTECTIVE_AGENCIES': 7393,
    '7394_EQUIPMENT_RENTAL_&_LEASING': 7394,
    '7395_PHOTOFINISHING_LABORATORIES': 7395,
    '7399_BUSINESS_SERVICES': 7399,
    '7512_AUTO_RENTALS_&_LEASING': 7512,
    '7513_TRUCK/UTILITY_TRAILER_RENTALS': 7513,
    '7519_MOTOR_HOME/RV_RENTALS': 7519,
    '7523_PARKING_LOTS_METERS_GARAGES': 7523,
    '7531_AUTOMOTIVE_BODY_REPAIR_SHOPS': 7531,
    '7534_TIRE_RETREADING_&_REPAIR_SHOPS': 7534,
    '7535_AUTOMOTIVE_PAINT_SHOPS': 7535,
    '7538_AUTOMOTIVE_REPAIR_SHOPS': 7538,
    '7542_CAR_WASHES': 7542,
    '7549_TOWING_SERVICES': 7549,
    '7622_RADIO_TELEVISION_STEREO_REPAIR': 7622,
    '7623_AIR_CONDITIONING&_REFRIGERATION': 7623,
    '7629_ELECTRICAL_REPAIR_SHOPS': 7629,
    '7631_WATCH,_CLOCK_AND_JEWELRY_REPAIR': 7631,
    '7641_REUPHOLSTERY_&_FURNITURE_REPAIR': 7641,
    '7692_WELDING_SERVICES': 7692,
    '7699_MISCELLANEOUS_REPAIR_SHOPS': 7699,
    '7829_FILMS/VIDEO_PRODUCTION_DISTRBTN': 7829,
    '7832_MOTION_PICTURE_THEATERS': 7832,
    '7841_VIDEO_TAPE_RENTAL_STORES': 7841,
    '7911_DANCE_HALLS/STUDIOS/SCHOOLS': 7911,
    '7922_THEATRICAL_PRODUCERS/TCKT_AGENT': 7922,
    '7929_BANDS/ORCHESTRAS/ENTERTAINERS': 7929,
    '7932_BILLIARD_AND_POOL_ESTABLISHMENT': 7932,
    '7933_BOWLING_ALLEYS': 7933,
    '7941_COMMERCIAL/PRO_SPORTS_&_CLUBS': 7941,
    '7991_TOURIST_ATTRACTIONS_AND_EXHIBIT': 7991,
    '7992_PUBLIC_LF_COURSES': 7992,
    '7993_VIDEO_AMUSEMENT_GAME_SUPPLIES': 7993,
    '7994_VIDEO_GAME_ARCADES/ESTABLISHMNT': 7994,
    '7995_BETTING/TRACK/CASINO/LOTTO': 7995,
    '7996_AMUSEMENT_PARKS': 7996,
    '7997_MEMBERSHIP_CLUBS': 7997,
    '7998_AQUARIUMS,_DOLPHINARIUMS,_ZOOS': 7998,
    '7999_RECREATIONAL_FACILITIES': 7999,
    '8011_DOCTORS,_PHYSICIANS': 8011,
    '8021_DENTISTS,_ORTHODONTISTS': 8021,
    '8031_OSTEOPATHS': 8031,
    '8041_CHIROPRACTORS': 8041,
    '8042_OPTOMETRISTS/OPHTHALMOLOGISTS': 8042,
    '8043_OPTICIANS': 8043,
    '8049_PODIATRISTS_AND_CHIROPODISTS': 8049,
    '8050_CONVALESCENT_HOMES': 8050,
    '8062_HOSPITALS': 8062,
    '8071_MEDICAL_&_DENTAL_LABORATORIES': 8071,
    '8099_MEDICAL_&_HEALTH_SERVICES': 8099,
    '8111_LEGAL_SERVICES,_ATTORNEYS': 8111,
    '8211_ELEMENTARY_AND_SECONDARY_SCHOOL': 8211,
    '8220_COLLEGES/UNIV/JC/PROFESSION_SCH': 8220,
    '8241_CORRESPONDENCE_SCHOOLS': 8241,
    '8244_BUSINESS_AND_SECRETARIAL_SCHOOL': 8244,
    '8249_VOCATIONAL_AND_TRADE_SCHOOLS': 8249,
    '8299_SCHOOLS_&_EDUCATIONAL_SERVICES': 8299,
    '8351_CHILD_CARE_SERVICES': 8351,
    '8398_CHARITABLE_AND_SOCIAL_SERVICE_O': 8398,
    '8641_CIVIC,_SOCIAL,_FRATERNAL_ASSOC': 8641,
    '8651_POLITICAL_ORGANIZATIONS': 8651,
    '8661_RELIGIOUS_ORGANIZATIONS': 8661,
    '8675_AUTOMOBILE_ASSOCIATIONS': 8675,
    '8699_MEMBERSHIP_ORGANIZATIONS': 8699,
    '8734_TESTING_LABS_(NON-MEDICAL)': 8734,
    '8911_ARCHITECTURAL,_ENGINEERING,': 8911,
    '8931_ACCOUNTING_AUDITING_BOOKKEEPING': 8931,
    '8999_PROFESSIONAL_SERVICES': 8999,
    '9211_COURT_COSTS': 9211,
    '9222_FINES': 9222,
    '9223_BAIL_AND_BOND_PAYMENTS': 9223,
    '9311_TAX_PAYMENTS': 9311,
    '9399_VERNMENT_SERVICES': 9399,
    '9402_POSTAL_SERVICES_-_VT_ONLY': 9402,
    '9405_INTRA-VERNMENT_PURCHASES': 9405,
    '9700_AUTOMATED_REFERRAL_SERVICE': 9700,
    '9702_EMERGENCY_SERVICES_(GCAS)': 9702,
    '9950_INTRA-COMPANY_PURCHASES': 9950,
};

export default getEnumObject(mcc);

<script setup>
import { computed } from 'vue';

const props = defineProps({
    account: {
        type: Object,
        required: true,
    },
    showStatus: Boolean,
});

const accountInitials = computed(() => {
    const name = props.account?.business_name;

    if (!name) {
        return '';
    }

    const nameParts = name.split(' ').filter((part) => part);
    const initials = nameParts.slice(0, 2).map((part) => part[0].toUpperCase());

    return initials.join('');
});

const INITIALS_BACKGROUND_COLORS = [
    'bg-orange-400',
    'bg-green-600',
    'bg-blue-400',
    'bg-pink-400',
    'bg-emerald-500',
];

const initialsBackgroundColor = computed(() => {
    const name = props.account?.business_name;
    if (!name) {
        return initialsBackgroundColor.value[0];
    }

    const nameReducedToCode = name
        .split('')
        .reduce((acc, char) => acc + char.charCodeAt(0), 0);

    const idx = nameReducedToCode % INITIALS_BACKGROUND_COLORS.length;

    return INITIALS_BACKGROUND_COLORS[idx];
});

const isActive = computed(() => {
    const account = props.account;

    if (!account) {
        return false;
    }

    return account.is_approved && account.is_active;
});
</script>

<template>
    <div class="flex gap-x-3 overflow-hidden">
        <div
            class="size-10 flex items-center justify-center rounded-full shrink-0 relative"
            :class="initialsBackgroundColor">
            <span class="text-body font-bold text-gray-100">
                {{ accountInitials }}
            </span>

            <div
                v-if="showStatus"
                class="size-3 absolute bottom-0 end-0 rounded-full border border-white"
                :class="isActive ? ' bg-green-500' : ' bg-warning-500'" />
        </div>

        <div class="overflow-hidden">
            <p
                class="font-bold text-body text-gray-1000 text-ellipsis whitespace-nowrap overflow-hidden"
                :title="account.business_name">
                {{ account.business_name }}
            </p>
            <p class="text-footnote text-gray-1000">
                {{ account.commercial_registration }}
            </p>
        </div>
    </div>
</template>

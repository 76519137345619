const NearpayRegistrationContainer = () =>
    import(
        '../Screens/Auth/Register/NearpayRegistration/NearpayRegistrationContainer.vue'
    );

export default [
    {
        path: '/nearpay-register',
        name: 'nearpay-registration',
        component: NearpayRegistrationContainer,
        meta: {
            mixpanelPageName: 'iPhone Tap to Pay Onboarding',
            pageTitle: 'nearpay_registration',
        },
    },
];

<script>
import { useUnreconciledTerminals } from '../Composables/useUnreconciledTerminals.js';
import AppsLauncher from './AppsLauncher.vue';
import { useUserStore } from '@/customer-portal/js/Composables/useUserStore.js';
import impersonationBar from './impersonationBar.vue';
import { closeAllDropdowns } from '@/common/utils/dom';
import { useIsArabic } from '@/common/Composables/useIsArabic';

export default {
    components: { AppsLauncher, impersonationBar },
    setup: () => {
        const {
            count: unreconciledTerminalsCount,
            routePath: unreconciledTerminalsRoute,
        } = useUnreconciledTerminals();
        const userStore = useUserStore();
        const { user, isUserApproved, isImpersonating } = userStore;
        const isArabic = useIsArabic();

        return {
            user,
            isUserApproved,
            unreconciledTerminalsCount,
            unreconciledTerminalsRoute,
            isImpersonating,
            isArabic,
        };
    },
    computed: {
        isActiveUser() {
            return (
                this.isUserApproved &&
                this.user.merchant?.is_approved &&
                this.user.merchant?.is_active
            );
        },
        isImpersonated() {
            return this.isImpersonating;
        },
    },
    methods: {
        goToProfilePage() {
            this.$router.push({
                name: 'my-account',
            });
            closeAllDropdowns();
        },
        switchLocale() {
            const locale = localStorage.getItem('locale');

            if (locale === 'ar') {
                localStorage.setItem('locale', 'en');
            } else {
                localStorage.setItem('locale', 'ar');
            }

            window.location.reload();
        },
        logout() {
            this.$router.replace({
                name: 'logout',
            });
        },
    },
};
</script>

<template>
    <header id="header" data-testid="header" class="relative bg-white shrink-0">
        <div
            :class="[
                'flex items-center px-6 py-1 lg:px-10 2xl:px-16 min-h-14 w-full border-gray-300 border-b',
            ]">
            <slot></slot>

            <div class="flex flex-col sm:flex-row items-start">
                <div class="text-center ms-1 lg:ms-0">
                    <span>
                        {{ $t('product_description.status') }}
                    </span>
                    <template v-if="!isActiveUser">
                        <span class="text-red-500 font-bold">{{
                            $t('inactive')
                        }}</span>
                    </template>
                    <template v-else>
                        <span class="text-green-500 font-bold">{{
                            $t('active')
                        }}</span>
                    </template>
                </div>

                <Transition>
                    <div
                        v-if="unreconciledTerminalsCount"
                        class="ms-0 sm:ms-6 px-1 sm:px-6 border-0 sm:border-s">
                        <span>
                            {{
                                $t(
                                    'unreconciled_terminals.unreconciled_terminals'
                                )
                            }}:
                        </span>
                        <router-link
                            :to="unreconciledTerminalsRoute"
                            class="text-red-500 underline">
                            {{ unreconciledTerminalsCount }}
                            {{ $t('terminals') }}
                        </router-link>
                    </div>
                </Transition>
            </div>

            <div class="flex items-center ms-auto">
                <impersonation-bar v-if="isImpersonated" />

                <div class="flex items-center">
                    <div class="me-2 sm:me-4">
                        <apps-launcher></apps-launcher>
                    </div>
                    <drop-down
                        :prevent-propagate="false"
                        :hide-on-scroll="false">
                        <template #trigger>
                            <button
                                class="flex justify-center items-center border border-gray-70 rounded-full h-11 w-11">
                                <svg-icon icon="user-header" />
                            </button>
                        </template>
                        <template #content>
                            <div>
                                <div class="flex w-full py-2 px-4">
                                    <div
                                        class="flex justify-center items-center border border-gray-70 rounded-full h-11 w-11 me-2">
                                        <svg-icon icon="user-header" />
                                    </div>
                                    <div>
                                        {{ user.name }} <br />
                                        <span class="font-thin">
                                            {{ user.email }}
                                        </span>
                                    </div>
                                </div>
                                <hr />
                                <a
                                    href="#"
                                    class="flex items-center w-full p-4 hover:text-primary"
                                    data-testid="myAccount"
                                    @click.stop.prevent="goToProfilePage">
                                    <svg-icon
                                        class="w-4 h-4 me-2"
                                        icon="user-header" />
                                    {{ $t('my_account.label') }}
                                </a>
                                <a
                                    href="#"
                                    :class="{
                                        'locale-switcher-menu ': !isArabic,
                                    }"
                                    class="flex items-center w-full p-4 font-bold text-lg hover:text-primary"
                                    data-testid="language"
                                    @click.stop.prevent="switchLocale">
                                    <svg-icon
                                        class="w-4 h-4 me-2"
                                        icon="language" />
                                    {{ $t('other_lang_long') }}
                                </a>
                                <a
                                    href="#"
                                    class="w-full block p-4 hover:text-primary"
                                    data-testid="logout"
                                    @click.stop.prevent="logout">
                                    <svg-icon
                                        class="w-4 h-4 me-2"
                                        icon="logout" />{{ $t('logout') }}
                                </a>
                            </div>
                        </template>
                    </drop-down>
                </div>
            </div>
        </div>
    </header>
</template>

import { Bus } from '../bus';

export function closeAllDropdowns(reason = null) {
    Bus.$emit('close-dropdowns', { reason });
}

export function scrollToTop(idOrElement = 'decorated-modal-body') {
    const element =
        typeof idOrElement === 'string'
            ? document.getElementById(idOrElement)
            : idOrElement;

    if (element instanceof HTMLElement) {
        element.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

const VatInvoicesContainer = () =>
    import('../Screens/VatInvoices/VatInvoicesContainer.vue');

export default [
    {
        path: '/vat-invoices',
        name: 'vat-invoices',
        component: VatInvoicesContainer,
        meta: {
            requireAuth: true,
            requireApproval: true,
            mixpanelPageName: 'VAT Invoices',
            activeSidebarItemId: 'vat_invoices',
            pageTitle: 'vat_invoices',
        },
    },
];

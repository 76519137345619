import { useMixpanel } from '@/common/Composables/useMixpanel.js';
import * as Sentry from '@sentry/vue';
import { onboardRequestStatuses } from '@/common/Enums/onboardRequestStatuses.js';
import { useAppSettings } from '@/customer-portal/js/Composables/useAppSettings.js';
import mainStore from '@/customer-portal/js/Store/mainStore.js';
import {
    initGainsight,
    resetGainsight,
} from '@/customer-portal/js/Utils/gainsight.js';
import axios from 'axios';
import { computed, reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { MODAL_SHOWN_IDENTIFIER_IN_LOCAL_STORAGE } from './useUnreconciledTerminals';

const state = reactive({
    user: {},
    isAuthenticated:
        Boolean(localStorage.getItem('token')) ||
        Boolean(sessionStorage.getItem('token')),
    isLoading: false,
    isImpersonating: Boolean(sessionStorage.getItem('token')),
});

// Direct Import (userStoreState): when you need simple, read-only access to the state.
export const userStoreState = computed(() => state);

/**
 * Exported with the user param just in case it's used in another utitlies that are not using composables.
 *
 * @param {string?} featureFlag
 * @param {{ id:string, merchant?: { id: string, supports_customer_insights?:boolean }} | undefined} user
 *
 * @returns {boolean}
 */
export const hasFeatureEnabled = (featureFlag, user) => {
    if (!featureFlag) {
        return true;
    }
    if (!user.merchant) {
        return false;
    }
    return user.merchant[featureFlag];
};

export function useUserStore() {
    const router = useRouter();
    const appSettings = useAppSettings();
    const { resetMixpanel, initMixpanel } = useMixpanel();

    const logout = async () => {
        try {
            state.isLoading = true;
            await axios.post(`${mainStore.appBaseUrl}/logout`);
        } finally {
            appSettings.removeAuthenticationStyle({
                isLocaleSwitcherDisplayed: true,
            });
            clearAuth();
            resetMixpanel();
            resetGainsight();
            state.isLoading = false;
            await router.push({ name: 'login' });
        }
    };

    const setAuthenticationState = (token, isSessionBased) => {
        if (token) {
            if (isSessionBased) {
                sessionStorage.setItem('token', token);
                state.isImpersonating = true;
            } else {
                localStorage.setItem('token', token);
            }
        }

        const currentToken =
            sessionStorage.getItem('token') || localStorage.getItem('token');

        axios.defaults.headers.common['Authorization'] =
            `Bearer ${currentToken}`;
        state.isAuthenticated = true;
    };

    const setUserInfo = async (data = {}) => {
        try {
            const { token, isSessionBased } = data;
            state.isLoading = true;
            setAuthenticationState(token, isSessionBased);
            const {
                data: { user },
            } = await axios.get(`${mainStore.appBaseUrl}/whoami`);
            state.user = { ...user };
            appSettings.setAuthenticationStyle();
            Sentry.setUser({ id: user.id, email: user.email });
            initMixpanel(user);
            initGainsight(user);
            return user;
        } catch (error) {
            await logout();
        } finally {
            state.isLoading = false;
        }
    };

    const setUserState = (data) => {
        state.user = { ...state.user, ...data };
    };

    // if user doesn't have an onboard request or onboard request is approved, then user is approved
    const isUserApproved = computed(() => {
        return state.user.onboard_request
            ? state.user.onboard_request.status ===
                  onboardRequestStatuses.APPROVED
            : true;
    });

    const isOnboardingRequestRejected = computed(() => {
        return [
            onboardRequestStatuses.REJECTED,
            onboardRequestStatuses.FINAL_REJECTED,
        ].includes(state.user.onboard_request?.status);
    });

    const isSubMerchantsFlow = computed(() => {
        return (
            Boolean(state.user.onboard_request?.super_merchant) &&
            !state.user.onboard_request?.super_merchant?.is_online_aggregator
        );
    });

    const isNafathVerified = computed(() => {
        const eKYC = state.user?.eKYC;
        return Boolean(eKYC?.is_using_nafath && eKYC?.is_national_id_verified);
    });

    return {
        ...toRefs(state),
        isNafathVerified,
        logout,
        setUserInfo,
        isUserApproved,
        isOnboardingRequestRejected,
        isSubMerchantsFlow,
        setUserState,
        hasFeatureEnabled: (featureFlag) =>
            hasFeatureEnabled(featureFlag, state.user),
    };
}

export function clearAuth() {
    axios.defaults.headers.common['Authorization'] = '';
    if (state.isImpersonating) {
        sessionStorage.clear();
    } else {
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem(MODAL_SHOWN_IDENTIFIER_IN_LOCAL_STORAGE);
    }
    state.isAuthenticated = false;
    state.user = {};
    Sentry.setUser(null);
}

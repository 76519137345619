import featureFlagsEnum from '../Enums/featureFlagsEnum';

const BankReconciliationReport = () =>
    import(
        '../Screens/BankReconciliationReport/BankReconciliationReportList.vue'
    );

export default [
    {
        path: '/bank_reconciliation_report',
        name: 'bank_reconciliation_report',
        component: BankReconciliationReport,
        meta: {
            requireAuth: true,
            requireApproval: true,
            pageTitle: 'bank_reconciliation_report',
            activeSidebarItemId: 'bank_reconciliation_report',
            mixpanelPageName: 'Bank Reconciliation Report',
            featureFlag: featureFlagsEnum.BANK_RECONCILIATION_REPORT,
        },
    },
];

const BranchesList = () => import('../Screens/Branches/List/BranchesList.vue');
const BranchView = () => import('../Screens/Branches/View/BranchView.vue');

export default [
    {
        path: '/branches',
        meta: {
            requireAuth: true,
            requireApproval: true,
            activeSidebarItemId: 'branches',
        },
        children: [
            {
                path: '',
                name: 'branches',
                component: BranchesList,
                meta: {
                    mixpanelPageName: 'Branches List',
                    pageTitle: 'branches_list',
                },
            },
            {
                path: ':id',
                name: 'branch-view',
                component: BranchView,
                props: true,
                meta: {
                    mixpanelPageName: 'Branch Details',
                    pageTitle: 'branch_details',
                },
            },
        ],
    },
];

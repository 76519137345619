<script setup>
import { ref } from 'vue';
import { copyToClipboard } from '../utils/string';

const props = defineProps({
    value: {
        type: String,
        required: true,
    },
});

const hasCopied = ref(false);

function onCopy() {
    copyToClipboard(props.value);

    hasCopied.value = true;
    setTimeout(() => {
        hasCopied.value = false;
    }, 3000);
}
</script>

<template>
    <Transition name="slide-down" mode="out-in">
        <div v-if="hasCopied">
            <svg-icon
                class="size-4 cursor-pointer text-positive-500"
                icon="check" />
        </div>

        <div v-else>
            <tooltip :help-text="$t('copy_value')">
                <svg-icon
                    class="size-4 cursor-pointer hover:text-secondary-300"
                    icon="content_copy"
                    @click="onCopy" />
            </tooltip>
        </div>
    </Transition>
</template>

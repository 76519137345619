export function convertToTitleCase(text) {
    return (
        text
            ?.toLowerCase()
            // Replace underscores and dots with spaces
            .replace(/_/g, ' ')
            .replace(/\./g, ' ')
            // Capitalize first letter of each word
            .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase() ?? '')
    );
}

export function copyToClipboard(text) {
    if (navigator.clipboard && window.isSecureContext) {
        // Use the Clipboard API if available and in a secure context
        navigator.clipboard.writeText(text);
    } else {
        // Fallback to the older approach using a temporary textarea
        const textArea = document.createElement('textarea');
        textArea.value = text;

        // Position the textarea off-screen to avoid visual disruptions
        textArea.style.position = 'fixed';
        textArea.style.left = '-9999px';

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        document.execCommand('copy');
        document.body.removeChild(textArea);
    }
}

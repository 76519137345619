<script>
import { Bus } from '../bus';
import { useIsArabic } from '../Composables/useIsArabic';
import { closeAllDropdowns } from '../utils/dom';

export default {
    props: {
        dropdownContentClasses: {
            type: String,
            default: '',
        },
        preventPropagate: {
            type: Boolean,
            default: true,
        },
        position: {
            type: String,
            default: 'bottom',
            validator(value) {
                return ['top', 'bottom', 'start', 'end'].includes(value);
            },
        },
        hideOnScroll: {
            type: Boolean,
            default: true,
        },
        calculatePosition: {
            type: Boolean,
            default: true,
        },
        closeOnClick: {
            type: Boolean,
        },
    },
    emits: ['showing', 'is-shown'],
    setup() {
        const isArabic = useIsArabic();
        return { isArabic };
    },
    data: () => ({
        isDropdownOpen: false,
        scrollableParent: null, // to cache the scrollable parent since the component should not keep looking
        ticking: false,
        spacing: {
            y: 8,
            x: 16,
        },
    }),
    computed: {
        isXAxis() {
            return this.position === 'start' || this.position === 'end';
        },
    },
    watch: {
        isDropdownOpen(val) {
            if (val) {
                this.$emit('showing');
            }
            this.$emit('is-shown', this.isDropdownOpen);
        },
    },
    beforeUnmount() {
        this.removeEvents();
    },
    methods: {
        addEvents(force = false) {
            const scrollableParent = this.findScrollableParent();
            const hideEvent = this.hideOnScroll && force && this.hide;
            const event = !this.hideOnScroll ? this.onChange : hideEvent;

            if (event) {
                window.addEventListener('scroll', event);
                scrollableParent.addEventListener('scroll', event);
                window.addEventListener('resize', event);
            }

            Bus.$on('close-dropdowns', this.handleCloseDropdowns);
        },
        removeEvents() {
            const event = this.hideOnScroll ? this.hide : this.onChange;
            const scrollableParent = this.findScrollableParent();
            window.removeEventListener('scroll', event);
            window.removeEventListener('scroll', event);
            window.removeEventListener('resize', event);
            scrollableParent.removeEventListener('scroll', event);
            Bus.$off('close-dropdowns', this.handleCloseDropdowns);
        },
        scrollIfNeeded() {
            const trigger = this.$refs.dd_trigger;
            if (!trigger) {
                return;
            }
            const scrollableParent = this.findScrollableParent(trigger);
            const parentRect = scrollableParent.getBoundingClientRect();
            const rect = trigger.getBoundingClientRect();
            const isWithinParentBounds =
                rect.top >= parentRect.top &&
                rect.left >= parentRect.left &&
                rect.bottom <= parentRect.bottom &&
                rect.right <= parentRect.right;
            if (!isWithinParentBounds) {
                trigger.scrollIntoView();
            }
        },
        show($event) {
            if (this.preventPropagate) {
                $event.stopPropagation();
            }
            closeAllDropdowns();
            this.scrollIfNeeded();
            requestAnimationFrame(() => {
                this.addEvents(true);
                this.handleShowing();
            });
        },
        handleShowing() {
            this.isDropdownOpen = true;

            this.$nextTick(() => {
                this.setDropdownPosition();
            });
        },
        findScrollableParent(el) {
            if (this.scrollableParent) {
                return this.scrollableParent;
            }
            if (!el) {
                el = this.$refs.dd_trigger;
            }
            let parentNode = el.parentNode;

            while (parentNode && parentNode !== document.body) {
                const overflowY = window.getComputedStyle(parentNode).overflowY;

                if (overflowY === 'auto' || overflowY === 'scroll') {
                    this.scrollableParent = parentNode;
                    return parentNode;
                }
                parentNode = parentNode.parentNode;
            }

            // If no explicit scrollable parent found, return body as default scrollable parent
            return document.body;
        },
        handleCloseDropdowns() {
            this.hide();
        },
        hide() {
            this.isDropdownOpen = false;
            this.removeEvents();
        },
        onChange() {
            // save the postions before request
            if (!this.ticking) {
                requestAnimationFrame(this.setDropdownPosition);
            }
            this.ticking = true;
        },
        handleContentClick() {
            if (this.closeOnClick) {
                this.hide();
            }
        },
        transformsMap(triggerRect, contentRect) {
            const topY = -(
                triggerRect.height +
                contentRect.height +
                this.spacing.y
            );

            const isContentLarger =
                contentRect.width >= triggerRect.width + this.spacing.x;
            const diff = triggerRect.width - contentRect.width;

            const startX = isContentLarger ? diff : -contentRect.width;

            const endX = this.isArabic
                ? isContentLarger
                    ? 0
                    : diff + contentRect.width
                : diff + contentRect.width;

            const transform = {
                bottom: {
                    x: 0,
                    y: 0,
                },
                top: {
                    x: 0,
                    y: topY,
                },
                start: {
                    x: startX,
                    y: 0,
                },
                end: {
                    x: endX,
                    y: 0,
                },
            };

            return transform;
        },
        setDropdownPosition() {
            if (!this.calculatePosition) {
                return;
            }
            this.ticking = false;
            const trigger = this.$refs.dd_trigger;
            const content = this.$refs.dropdown_content;

            if (!trigger || !content) {
                return;
            }

            // Toggle visibility of dropdown content
            const rect = trigger.getBoundingClientRect();
            const contentRect = content.getBoundingClientRect();
            const transform = this.transformsMap(rect, contentRect)[
                this.position
            ];

            // Position dropdown content
            content.style.left = `${rect.left}px`;
            content.style.top = `${rect.bottom}px`;
            content.style.translate = `${transform.x}px ${transform.y}px`;

            if (!this.isXAxis) {
                content.style.setProperty(
                    'width',
                    `${rect.width}px`,
                    'important'
                );
            }
            this.adjustPosition(rect, content, transform);
        },

        adjustY(rect, contentRect, transform) {
            if (contentRect.bottom + this.spacing.y > window.innerHeight) {
                transform.y = rect.top - contentRect.bottom - this.spacing.y;
            } else if (contentRect.y < 0) {
                // out of the doc top
                if (this.isArabic) {
                    transform.x = 0;
                } else {
                    transform.x += transform.x > 0 ? 0 : rect.width;
                }
                transform.y = 0;
            }
        },

        adjustX(rect, contentRect, transform) {
            // adding 32 so it won't stick into the right of doc
            const end = contentRect.right + this.spacing.x * 2;
            const isOutOfBounds =
                contentRect.left < 0 || end > window.innerWidth;

            if (!isOutOfBounds) {
                return;
            }

            if (contentRect.width / 2 < rect.width) {
                transform.x = this.isArabic
                    ? 0
                    : rect.width - contentRect.width;
            } else {
                // the width of the menu is way larger than the trigger and should be adjusted correctly.
                if (this.isArabic) {
                    transform.x = transform.x > 0 ? rect.width : 0;
                } else {
                    transform.x = rect.width - contentRect.width;
                }
            }
        },

        adjustPosition(rect, content, transform) {
            const contentRect = content.getBoundingClientRect();
            this.adjustY(rect, contentRect, transform);
            this.adjustX(rect, contentRect, transform);

            content.style.translate = `${transform.x}px ${transform.y}px`;
        },
    },
};
</script>

<template>
    <div v-click-outside="hide">
        <div ref="dd_trigger" class="flex" @click.prevent="show">
            <slot name="trigger" />
        </div>
        <div
            v-if="isDropdownOpen"
            ref="dropdown_content"
            class="dropdown-content"
            :class="dropdownContentClasses"
            @click="handleContentClick">
            <slot name="content" />
        </div>
    </div>
</template>

import mixpanel from 'mixpanel-browser';
import { useRoute } from 'vue-router';

export const mixpanelTrackEvent = (eventName, payload = {}, route) => {
    // Avoid tracking events if mixpanel is not loaded or route is not provided
    if (!mixpanel || !route) {
        return;
    }
    const { params, query, meta, fullPath } = route;

    mixpanel.track(eventName, {
        params,
        fullPath,
        pageName: meta.mixpanelPageName,
        queryParams: query,
        queryParamsKeys: Object.keys(query),
        ...payload,
    });
};

export function useMixpanel() {
    const route = useRoute();

    function initMixpanel(user) {
        if (typeof mixpanel === 'undefined') {
            return;
        }
        const {
            id,
            email,
            merchant,
            name,
            is_contact_user,
            is_owner,
            is_active,
            gender,
            birth_date,
            is_impersonated,
        } = user;
        mixpanel.identify(id);
        mixpanel.people.set({
            email,
            name,
            merchantReference: merchant?.reference ?? '',
            distinct_id: id,
            isContactUser: is_contact_user,
            isOwner: is_owner,
            isActive: is_active,
            gender: gender === 1 ? 'Male' : 'Female',
            birthDate: birth_date,
            isImpersonated: is_impersonated,
        });
    }

    function resetMixpanel() {
        if (!mixpanel) {
            return;
        }
        mixpanelTrackEvent('Logged out');
        mixpanel.reset();
    }

    return {
        initMixpanel,
        resetMixpanel,
        mixpanelTrackEvent: (eventName, payload) =>
            mixpanelTrackEvent(eventName, payload, route),
    };
}

import { getEnumObject } from '../utilities.js';

export const tidTypes = {
    INTERSOFT: 1,
    ITS: 2,
    ALHAMRANI: 3,
    MOMKN: 4,
    NEARPAY: 6,
    NEAOLEAP: 7,
};

export default getEnumObject(tidTypes);

import { ref, computed, watch } from 'vue';
import { useHttp } from '@/common/Composables/useHttp.js';
import { isEmpty, debounce } from 'lodash';
import { userStoreState } from './useUserStore';
import qs from 'qs';

export const MODAL_SHOWN_IDENTIFIER_IN_LOCAL_STORAGE =
    'is_unreconciled_terminals_modal_shown';

const count = ref(null);
const getCount = debounce(() => {
    const { http } = useHttp();

    http?.()
        .get(`terminals?${qs.stringify({ filter: { unreconciled: true } })}`)
        .then((response) => {
            count.value = response?.data?.meta?.total;
        });
});

const hasReminderEnabled = computed(() => {
    const { user } = userStoreState.value;
    return user.show_unreconciled_terminals_reminder;
});

const setReminderEnabled = (value) => {
    const { http } = useHttp();
    const { user } = userStoreState.value;

    if (!http || isEmpty(user)) {
        return;
    }

    const payload = {
        show_unreconciled_terminals_reminder: value,
    };

    return http().post(`reminders/set_user_reminders`, payload);
};

const isModalShownBefore = ref(
    JSON.parse(localStorage.getItem(MODAL_SHOWN_IDENTIFIER_IN_LOCAL_STORAGE)) ||
        false
);
export const setIsModalShownBefore = () => {
    localStorage.setItem(MODAL_SHOWN_IDENTIFIER_IN_LOCAL_STORAGE, true);
    isModalShownBefore.value = true;
};

const shouldShowModal = computed(() => {
    const { user } = userStoreState.value;
    isModalShownBefore.value =
        JSON.parse(
            localStorage.getItem(MODAL_SHOWN_IDENTIFIER_IN_LOCAL_STORAGE)
        ) || false;

    return Boolean(
        !isModalShownBefore.value &&
            user &&
            count.value > 0 &&
            hasReminderEnabled.value
    );
});

const routePath = computed(() => {
    const filter = { unreconciled: true };
    const queryString = qs.stringify({
        filter,
        showTabs: true,
    });

    return `/terminals?${queryString}`;
});

export const useUnreconciledTerminals = () => {
    const { user } = userStoreState.value;
    watch(
        user,
        () => {
            if (isEmpty(user)) {
                count.value = null;
                return;
            }

            if (count.value) {
                return;
            }

            getCount();
        },
        { immediate: true }
    );

    return {
        shouldShowModal,
        count,
        routePath,
        setReminderEnabled,
    };
};

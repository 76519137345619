<script>
import { useNavigateToPage } from '@/common/Composables/useNavigateToPage';

export default {
    setup() {
        const { navigateToPage } = useNavigateToPage();
        return {
            navigateToPage,
        };
    },
    methods: {
        navigateToVerifyNafath() {
            const route = {
                name: 'verify-nafath',
            };
            this.navigateToPage(route);
        },
    },
};
</script>
<template>
    <div class="pt-5 px-6">
        <div class="card">
            <div class="card-body">
                <div class="flex flex-col gap-2">
                    <span class="text-xl font-semibold text-[#D70015]">
                        {{ $t('your_identity_not_verified') }}
                    </span>
                    <i18n-t
                        keypath="click_here_to_verify_nafaht"
                        scope="global"
                        tag="div"
                        class="font-semibold text-[#3A3A3C]">
                        <template #click_here>
                            <a
                                class="cursor-pointer text-primary hover:underline"
                                @click.stop.prevent="navigateToVerifyNafath">
                                {{ $t('click_here') }}
                            </a>
                        </template>
                    </i18n-t>
                </div>
            </div>
        </div>
    </div>
</template>

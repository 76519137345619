import { getEnumObject } from '../utilities.js';

export const onBoardRequestSources = {
    WEB: 1,
    FOODICS_ONLINE: 2,
    ZOHO: 3,
    ADMIN: 4,
    NEARPAY: 5,
};

export default getEnumObject(onBoardRequestSources);

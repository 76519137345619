const VatStatementsContainer = () =>
    import('../Screens/VatStatements/VatStatementsContainer.vue');

export default [
    {
        path: '/vat-statements',
        name: 'vat-statements',
        component: VatStatementsContainer,
        meta: {
            requireAuth: true,
            requireApproval: true,
            mixpanelPageName: 'VAT Statements',
            activeSidebarItemId: 'vat_statements',
            pageTitle: 'vat_statements',
        },
    },
];

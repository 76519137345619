<script>
import { camelCase, get } from 'lodash';
import { Bus } from '../bus';
import { useMapEnumValue } from '../Composables/useMapEnumValue';
import { localizeAttribute } from '../utils/localization';
import { isObject } from '../utils/object';
import ErrorMessages from '../Validations/ErrorMessages/index.js';
import TimeSlotSelector from './TimeSlotSelector.vue';

export default {
    components: { TimeSlotSelector },
    props: {
        radioButtonsLabels: {
            type: Object,
            default: () => ({}),
        },
        label: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            required: true,
        },
        focus: Boolean,
        // eslint-disable-next-line vue/require-default-prop
        modelValue: [String, Number, Boolean, Array],
        requestFilters: { type: Object, default: () => ({}) },
        url: { type: String, default: '' },
        isRequired: { type: Boolean },
        extraLabelText: { type: String, default: '' },
        placeholder: { type: String, default: '' },
        deviceType: { type: [String, Number, Array], default: '' },
        helpText: { type: String, default: '' },
        enumType: { type: String, default: '' },
        vuelidateObj: {
            type: Object,
            default: () => ({}),
        },
        property: {
            type: String,
            default: '',
        },
        defaultCountryCode: {
            type: String,
            default: null,
        },

        /**
         * Options for a "select" type.
         */
        options: {
            type: Object,
            default: () => ({}),
        },

        /**
         * Data for a "select-input" type.
         */
        data: {
            type: Array,
            default: () => [],
        },

        /**
         * Ignore ids for a "select-input" type.
         */
        ignoredIds: {
            type: Array,
            default: () => [],
        },

        staticValue: {
            type: [String, Number],
            default: null,
        },

        /**
         * For "select" type, determine if it should have the "All" option.
         */
        hasAll: Boolean,

        /**
         * For "select" type, determine if it is a multiselect
         */
        multiSelect: {
            type: Boolean,
        },

        hasDeselect: {
            type: Boolean,
        },

        hasSelectAll: {
            type: Boolean,
        },

        /**
         * for select inputs a filter function to run on options
         */
        customFilter: {
            type: Function,
            default: (options) => options,
        },

        include: {
            type: Array,
            default() {
                return [];
            },
        },
        calenderNeedsPositioning: {
            type: Boolean,
        },

        min: {
            type: Number,
            default: null,
        },
        max: {
            type: Number,
            default: null,
        },
        createIfNotExist: {
            type: Boolean,
        },

        forceRefetch: {
            type: Boolean,
        },
        displayFormatter: {
            type: Function,
            default: null,
        },
        overrideValueFormatter: {
            type: Function,
            default: null,
        },
        largeSize: {
            type: Boolean,
        },
        ignoreAssignedBranches: {
            // if true, the form item will display all branches/warehouses not just the ones assigend to the user.
            type: Boolean,
        },
        customStyle: {
            // any custom CSS propeties can be passed as an object here
            type: Object,
            default: null,
        },
        // used in date picker type
        timeTravel: {
            type: Boolean,
            default: true,
        },
        isDisabled: Boolean,
        selectByTagType: {
            // pass the tag type number if selecting by tags is needed (and supported)
            type: String,
            default: '',
        },
        deselectText: {
            type: String,
            default: 'None',
        },
        maxLength: {
            type: Number,
            default: null,
        },
        // for select-input type
        isOptionDisabled: {
            type: Function,
            default() {
                return false;
            },
        },
        isOptionHidden: {
            type: Function,
            default() {
                return false;
            },
        },
        allowSearch: {
            type: Boolean,
            default: true,
        },
        parentEntityId: {
            type: String,
            default: '',
        },
        startAfter: {
            type: String,
            default: '',
        },
        maxDays: {
            type: Number,
            default: 0,
        },
        hidePresets: {
            type: Boolean,
        },
        loading: {
            type: Boolean,
        },
        groupBy: { type: String, default: 'day' },
        namespace: {
            type: null,
            default: null,
        },
        hideValidationErrorMsg: {
            type: Boolean,
        },
        overrideUrlFilters: {
            type: Function,
            default: (filters) => filters,
        },
        searchTermKey: {
            type: String,
            default: '',
        },
        sortKey: {
            type: String,
            default: '',
        },
        showCustomErrorMsg: {
            type: Boolean,
        },
        customErrorMsg: {
            type: String,
            default: '',
        },
        directionColumn: {
            type: Boolean,
        },
        // used for datepicker
        placement: {
            type: String,
            default: 'bottom_start',
        },
        hasSearchProperty: {
            type: Boolean,
        },
        supplementRequestData: {
            type: Object,
            default: () => ({}),
        },
        disabledDate: { type: Function, default: () => false },
        hideClearIcon: {
            type: Boolean,
        },
    },
    emits: ['update:modelValue', 'options-ready', 'selected-options', 'input'],
    setup() {
        const { mapEnumValue } = useMapEnumValue();
        return {
            mapEnumValue,
        };
    },

    data() {
        return {
            updatedValue: this.multiSelect ? [] : '',
            creating: false,
            errorMessages: ErrorMessages,
        };
    },
    computed: {
        isFieldNotValidAndTouched() {
            return Boolean(
                this.isFieldNotValid &&
                    get(this.vuelidateObj.fields, this.property).$dirty
            );
        },
        isFieldNotValid() {
            return Boolean(
                this.vuelidateObj?.fields &&
                    get(this.vuelidateObj.fields, this.property) &&
                    get(this.vuelidateObj.fields, this.property).$silentErrors
                        ?.length
            );
        },
        selectTypeOptions() {
            const hasAllObj = this.hasAll
                ? {
                      id: null,
                      text: this.placeholder || this.$t('misc.any'),
                  }
                : null;

            const hasDeselectObj = this.hasDeselect
                ? {
                      id: undefined,
                      text: this.$t(this.deselectText || this.$t('misc.none')),
                  }
                : null;

            const formattedOptions = Object.entries(this.options).map(
                ([id, text]) => ({
                    id,
                    text,
                })
            );

            return [hasAllObj, hasDeselectObj, ...formattedOptions].filter(
                (obj) => !!obj
            );
        },
    },

    watch: {
        modelValue(val) {
            if (
                val &&
                this.vuelidateObj?.fields &&
                get(this.vuelidateObj.fields, this.property)
            ) {
                get(this.vuelidateObj.fields, this.property).$touch();
            }
            this.updatedValue = val;
        },

        updatedValue(val) {
            if (this.isDisabled) {
                return;
            }
            const emittedValue =
                ![null, undefined, ''].includes(val) && this.type === 'select'
                    ? String(val)
                    : val;

            this.$emit(
                'update:modelValue',
                this.displayFormatter
                    ? this.displayFormatter(emittedValue)
                    : emittedValue
            );
        },
    },
    mounted() {
        if (this.focus) {
            this.$refs.input.focus();
        }

        this.updatedValue = this.modelValue;
        if (this.createIfNotExist) {
            Bus.$on('done-creating', () => {
                this.creating = false;
                Bus.$emit(`${this.type}:select-input-refetch`);
            });
        }
    },
    methods: {
        enterKeyDown() {
            Bus.$emit('enter-key-down-on-input');
        },
        valueFormatter(option) {
            return option?.text;
        },
        subscriptionValueFormatter(option) {
            if (option) {
                return `${option.reference} - ${
                    option.bank?.name
                } - ${this.mapEnumValue(
                    option.contract_type,
                    'contractTypes'
                )} - ${option.channel?.name}`;
            }
            return null;
        },
        bankChannelValueFormatter(option) {
            if (option) {
                return option?.account_number;
            }
            return null;
        },
        silentErrorsList(fields, property) {
            return get(fields, property);
        },
        localizeAttribute,
        isObject,
        camelCase,
        valueFormatterForMerchantDD(option) {
            if (!option) {
                return '';
            }

            const referencePart = option.reference
                ? `${option.reference} - `
                : '';
            const businessName = this.localizeAttribute(
                option,
                'business_name'
            );

            return `${referencePart}${businessName}`;
        },
    },
};
</script>

<template>
    <div
        class="mb-4 flex flex-wrap items-center form-item-container"
        :class="{ disabled: isDisabled }">
        <template v-if="type === 'radio'">
            <div class="mb-2">
                <span> {{ $t(label) }} </span>
                <span v-if="isRequired" class="text-red ms-1">*</span>
            </div>
            <div
                :class="[
                    'flex gap-2 w-full',
                    directionColumn ? 'flex-row' : 'flex-col',
                ]">
                <label
                    v-for="(val, key) in radioButtonsLabels"
                    :key
                    class="flex flex-row items-center gap-2">
                    <input
                        :id="key"
                        ref="input"
                        v-loading="loading"
                        :data-testid="camelCase(label)"
                        type="radio"
                        :name="label"
                        class="w-5 h-5 mt-[2px]"
                        :class="{ invalid: isFieldNotValidAndTouched }"
                        :value="val"
                        :checked="val === updatedValue"
                        @change="$emit('update:modelValue', val)" />
                    <span>
                        {{ $t(key) }}
                    </span>
                </label>
            </div>
        </template>
        <template v-if="type != 'checkbox' && type !== 'radio'">
            <div
                v-if="type != 's3-image'"
                class="mb-1 w-full flex justify-between items-center">
                <div class="flex items-center">
                    <slot name="custom_label">
                        <label class="text-footnote me-1">
                            <span> {{ $t(label) }} </span>
                            <span v-if="extraLabelText.length"
                                >({{ extraLabelText }})</span
                            >
                            <span v-if="isRequired" class="text-red ms-1"
                                >*</span
                            >
                        </label>
                    </slot>
                    <tooltip v-if="helpText.length" :help-text />
                </div>

                <slot name="labelRight" />
            </div>

            <div class="w-full">
                <div v-if="type == 'static'" class="p-2">
                    <span> {{ staticValue }} </span>
                </div>

                <input
                    v-if="type == 'text'"
                    :id="label"
                    ref="input"
                    v-model="updatedValue"
                    v-loading="loading"
                    :data-testid="camelCase(label)"
                    type="text"
                    :maxlength="maxLength"
                    class="input p-2"
                    :class="{ invalid: isFieldNotValidAndTouched }"
                    :placeholder="$t(placeholder)"
                    @keydown.enter="enterKeyDown" />

                <input
                    v-if="type == 'color'"
                    ref="input"
                    v-model="updatedValue"
                    type="color"
                    class="input" />

                <input
                    v-if="type == 'email'"
                    :id="label"
                    ref="input"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    type="email"
                    class="input p-2"
                    :class="{ invalid: isFieldNotValidAndTouched }"
                    @keydown.enter="enterKeyDown" />

                <input
                    v-if="type == 'number'"
                    :id="label"
                    ref="input"
                    v-model.number="updatedValue"
                    :data-testid="camelCase(label)"
                    type="number"
                    :min
                    :max
                    class="input p-2"
                    :class="{ invalid: isFieldNotValidAndTouched }"
                    onkeydown="return event.keyCode !== 69"
                    @keydown.enter="enterKeyDown" />

                <div
                    v-if="type == 'number_as_string'"
                    class="number-input-no-arrows">
                    <input
                        :id="label"
                        ref="input"
                        v-model="updatedValue"
                        :data-testid="camelCase(label)"
                        type="number"
                        :min
                        class="input p-2"
                        @keydown.enter="enterKeyDown" />
                </div>

                <date-picker
                    v-if="type == 'date'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    input-class="input p-2"
                    :needs-positioning="calenderNeedsPositioning"
                    :time-travel
                    :is-field-not-valid="isFieldNotValidAndTouched"
                    :start-after
                    :hide-presets
                    :placement
                    :group-by />

                <date-picker
                    v-if="type == 'date_range'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    class="inline"
                    :custom-style
                    :needs-positioning="calenderNeedsPositioning"
                    :is-field-not-valid="isFieldNotValidAndTouched"
                    :placeholder="$t(placeholder)"
                    is-range
                    :max-days
                    :hide-presets
                    :placement
                    :disabled-date
                    time-travel
                    :hide-clear-icon />

                <month-range
                    v-if="type == 'month_range'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :custom-style
                    :placeholder="$t(placeholder)"
                    :is-field-not-valid="isFieldNotValidAndTouched" />

                <select-input
                    v-if="type == 'select'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :allow-search="false"
                    :namespace
                    :data="selectTypeOptions"
                    :value-formatter
                    :is-option-hidden
                    :custom-error-msg
                    :is-option-disabled
                    :show-custom-error-msg />

                <select-input
                    v-if="type == 'enum' && isObject(options)"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :value-formatter
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :data="
                        Object.entries(options).map(([k, v]) => {
                            if ($root.mainStore.isCustomerPortal) {
                                return {
                                    id: isNaN(parseInt(k)) ? k : parseInt(k),
                                    text: $t(`enums.${enumType}.${v}`),
                                };
                            } else {
                                return {
                                    id: isNaN(parseInt(k)) ? k : parseInt(k),
                                    text: v
                                        .toLowerCase()
                                        .replace(/_/g, ' ')
                                        .replace(/(^\w|\s\w)/g, (m) =>
                                            m.toUpperCase()
                                        ),
                                };
                            }
                        })
                    "
                    :multi-select
                    :has-deselect
                    :has-select-all
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj
                    :allow-search
                    @selected-options="$emit('selected-options', $event)" />

                <select-input
                    v-if="type == 'enumFilters' && isObject(options)"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :value-formatter
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :data="
                        Object.entries(options).map(([k, v]) => {
                            if ($root.mainStore.isCustomerPortal) {
                                return {
                                    id: k,
                                    text: $t(`enums.${enumType}.${v}`),
                                };
                            } else {
                                return {
                                    id: k,
                                    text: v
                                        .toLowerCase()
                                        .replace(/_/g, ' ')
                                        .replace(/(^\w|\s\w)/g, (m) =>
                                            m.toUpperCase()
                                        ),
                                };
                            }
                        })
                    "
                    :multi-select
                    :has-deselect
                    :has-select-all
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj
                    :allow-search />

                <select-input
                    v-if="type == 'select-input'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        overrideValueFormatter
                            ? overrideValueFormatter
                            : (option) => (option ? option.text : '')
                    "
                    :multi-select
                    :has-deselect
                    :has-select-all
                    :data
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj
                    :allow-search
                    @selected-options="$emit('selected-options', $event)" />

                <select-input
                    v-if="type == 'bank'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        (option) => localizeAttribute(option, 'name')
                    "
                    :sort-key
                    :search-term-key="searchTermKey ? searchTermKey : 'name'"
                    :url="`/banks`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            is_deleted: false,
                            is_active: true,
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />

                <select-input
                    v-if="type == 'recon-available-banks'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        (option) => localizeAttribute(option, 'name')
                    "
                    :sort-key
                    :search-term-key="searchTermKey ? searchTermKey : 'name'"
                    :url
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            is_deleted: false,
                            is_active: true,
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj
                    @selected-options="$emit('selected-options', $event)"
                    @options-ready="
                        ({ options }) => $emit('options-ready', options)
                    " />

                <select-input
                    v-if="type == 'channel'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        (option) => localizeAttribute(option, 'name')
                    "
                    :sort-key
                    :search-term-key="searchTermKey ? searchTermKey : 'name'"
                    :url="`/channels`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            is_deleted: false,
                            is_active: true,
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />

                <select-input
                    v-if="type == 'merchant'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        overrideValueFormatter
                            ? overrideValueFormatter
                            : (option) => valueFormatterForMerchantDD(option)
                    "
                    :sort-key
                    :search-term-key="
                        searchTermKey ? searchTermKey : 'business_name'
                    "
                    :url="`/merchants`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            is_deleted: false,
                            is_active: true,
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />

                <select-input
                    v-if="type == 'tid'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        overrideValueFormatter
                            ? overrideValueFormatter
                            : (option) =>
                                  localizeAttribute(option, 'terminal_id8')
                    "
                    :sort-key
                    :search-term-key="searchTermKey ? searchTermKey : 'search'"
                    :url="`/tids`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj
                    has-search-property
                    @selected-options="
                        (options) => $emit('selected-options', options)
                    " />

                <select-input
                    v-if="type == 'super-merchant'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        (option) => localizeAttribute(option, 'name')
                    "
                    :sort-key
                    :search-term-key="searchTermKey ? searchTermKey : 'name'"
                    :url="`/super_merchants`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            is_active: true,
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />

                <select-input
                    v-if="type == 'branch'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        (option) =>
                            option
                                ? `${localizeAttribute(
                                      option,
                                      'reference'
                                  )} - ${localizeAttribute(option, 'name')}`
                                : ''
                    "
                    :sort-key="sortKey ? sortKey : '-created_at'"
                    :search-term-key="searchTermKey ? searchTermKey : 'name'"
                    :url="`/branches`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            is_deleted: false,
                            is_active: true,
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :supplement-request-data
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />
                <select-input
                    v-if="type == 'terminals-branches-list'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        (option) => localizeAttribute(option, 'name')
                    "
                    :data
                    :sort-key
                    :search-term-key="searchTermKey ? searchTermKey : 'name'"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj
                    @options-ready="
                        ({ options }) => $emit('options-ready', options)
                    " />

                <select-input
                    v-if="type == 'subscription'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :value-formatter="subscriptionValueFormatter"
                    :sort-key
                    :search-term-key="
                        searchTermKey ? searchTermKey : 'reference'
                    "
                    :url="`/subscriptions`"
                    :include="['bank', 'channel']"
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            is_active: true,
                            ...requestFilters,
                        })
                    "
                    :show-custom-error-msg
                    :custom-error-msg
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />

                <select-input
                    v-if="type == 'bank_channel'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :value-formatter="bankChannelValueFormatter"
                    :search-term-key="
                        searchTermKey ? searchTermKey : 'account_number'
                    "
                    :sort-key
                    :show-custom-error-msg
                    :custom-error-msg
                    :url="`/banks/${parentEntityId}/channels`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            is_approved: true,
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />

                <select-input
                    v-if="type == 'device'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        (option) => localizeAttribute(option, 'reference')
                    "
                    :sort-key
                    :search-term-key="
                        searchTermKey ? searchTermKey : 'reference'
                    "
                    :url="`/devices`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            is_active: true,
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />

                <select-input
                    v-if="type == 'terminal'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        overrideValueFormatter
                            ? overrideValueFormatter
                            : (option) => localizeAttribute(option, 'reference')
                    "
                    :sort-key
                    :search-term-key="
                        searchTermKey ? searchTermKey : 'reference'
                    "
                    :url="`/terminals`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :has-search-property
                    :url-filters="
                        overrideUrlFilters({
                            is_active: true,
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />

                <select-input
                    v-if="type == 'city'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        (option) => localizeAttribute(option, 'name')
                    "
                    :sort-key
                    :search-term-key="searchTermKey ? searchTermKey : 'name'"
                    :url="`/cities`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />

                <select-input
                    v-if="type == 'foodics-users'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        (option) => localizeAttribute(option, 'name')
                    "
                    :sort-key
                    :search-term-key="searchTermKey ? searchTermKey : 'name'"
                    :url="`/foodics-users`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />

                <select-input
                    v-if="type == 'merchant-users'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        (option) =>
                            option
                                ? `${localizeAttribute(
                                      option,
                                      'name'
                                  )} - +${localizeAttribute(
                                      option,
                                      'mobile_dial_code'
                                  )} - ${localizeAttribute(
                                      option,
                                      'mobile_number'
                                  )}`
                                : ''
                    "
                    :sort-key
                    :search-term-key="searchTermKey ? searchTermKey : 'name'"
                    :url="`/merchant_users`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj>
                    <template #nothingFound="slotProps">
                        <slot
                            name="nothingFound"
                            :search-query="slotProps.data.q">
                        </slot>
                    </template>
                </select-input>

                <select-input
                    v-if="type == 'payment'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        (option) => localizeAttribute(option, 'reference')
                    "
                    :sort-key
                    :search-term-key="
                        searchTermKey ? searchTermKey : 'reference'
                    "
                    :url="`/payments`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />

                <select-input
                    v-if="type == 'cp-bank'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        (option) => localizeAttribute(option, 'name')
                    "
                    :sort-key="sortKey ? sortKey : '-created_at'"
                    :search-term-key="searchTermKey ? searchTermKey : 'name'"
                    :url="`/onboarding/banks`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />

                <select-input
                    v-if="type == 'cp-city'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        (option) => localizeAttribute(option, 'name')
                    "
                    :sort-key
                    :search-term-key="searchTermKey ? searchTermKey : 'name'"
                    :url="`/onboarding/cities`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />

                <select-input
                    v-if="type == 'cp-branch'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        (option) => localizeAttribute(option, 'name')
                    "
                    :sort-key="sortKey ? sortKey : '-created_at'"
                    :search-term-key="searchTermKey ? searchTermKey : 'name'"
                    :url="`/branches`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            is_active: true,
                            ...requestFilters,
                        })
                    "
                    :supplement-request-data
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />

                <select-input
                    v-if="type == 'cp-income-sources'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        (option) => localizeAttribute(option, 'name')
                    "
                    :sort-key
                    :search-term-key="searchTermKey ? searchTermKey : 'name'"
                    :url="`/onboarding/income_sources`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />

                <select-input
                    v-if="type == 'cp-professions'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        (option) => localizeAttribute(option, 'name')
                    "
                    :sort-key
                    :search-term-key="searchTermKey ? searchTermKey : 'name'"
                    :url="`/onboarding/professions`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />

                <select-input
                    v-if="type == 'cp-focal-countries'"
                    :id="label"
                    v-model="updatedValue"
                    :data-testid="camelCase(label)"
                    :ignored-ids
                    :is-option-disabled
                    :is-option-hidden
                    :show-custom-error-msg
                    :custom-error-msg
                    :value-formatter="
                        (option) => localizeAttribute(option, 'name')
                    "
                    :sort-key
                    :search-term-key="searchTermKey ? searchTermKey : 'name'"
                    :url="`/onboarding/focal_countries`"
                    :include
                    :multi-select
                    :has-select-all
                    :has-deselect
                    :url-filters="
                        overrideUrlFilters({
                            is_active: true,
                            ...requestFilters,
                        })
                    "
                    :custom-filter
                    :placeholder="$t(placeholder)"
                    :property
                    :namespace
                    :vuelidate-obj />

                <time-slot-selector
                    v-if="type === 'time-slot'"
                    v-model="updatedValue" />
            </div>
            <slot name="inputNote" />
        </template>

        <div
            class="transition-[height]"
            :class="
                isFieldNotValidAndTouched && !hideValidationErrorMsg
                    ? 'h-auto'
                    : 'h-0'
            ">
            <transition name="slide-down">
                <template
                    v-if="isFieldNotValidAndTouched && !hideValidationErrorMsg">
                    <div class="mt-1 text-red-600">
                        <ul>
                            <li
                                v-for="error in silentErrorsList(
                                    vuelidateObj.fields,
                                    property
                                ).$silentErrors"
                                :key="error.$uid">
                                {{
                                    $root.mainStore.isCustomerPortal
                                        ? $t(error.$message)
                                        : error.$message
                                          ? error.$message
                                          : errorMessages[error.$validator](
                                                error.$params
                                            )
                                }}
                            </li>
                        </ul>
                    </div>
                </template>
            </transition>
        </div>
    </div>
</template>

<style>
/*overwiting some default styles for the component <vue-phone-number-input>*/
#phoneNumber_phone_number {
    border-width: 0;
}

#phoneNumber_country_selector {
    border-left-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
}

label[for='phoneNumber_country_selector'] {
    color: #969ba4 !important;
}

label[for='phoneNumber_phone_number'] {
    color: #969ba4 !important;
}

.has-value label[for='phoneNumber_phone_number'] {
    display: none;
}

.has-value #phoneNumber_phone_number {
    padding: 0 7px;
}

#phoneNumber_country_selector {
    padding: 7px 0 7px 40px;
}

.flag-container.field-country-flag {
    top: 14px !important;
}

.select-country-container {
    width: 100px !important;
    min-width: 100px !important;
    max-width: 100px !important;
}

.country-selector-arrow {
    top: calc(50% - 11px) !important;
}

label[for='phoneNumber_country_selector'] {
    display: none;
}

.country-selector .country-list-item.selected {
    color: #2d3748 !important;
    background-color: #fff !important;
}
/* Chrome, Safari, Edge, Opera */
.number-input-no-arrows input::-webkit-outer-spin-button,
.number-input-no-arrows input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.number-input-no-arrows input[type='number'] {
    -moz-appearance: textfield;
}
</style>

import { getEnumObject } from '../utilities.js';

export const businessMonthlyTurnover = {
    // From 0 - 70,000
    LEVEL_ONE: 1,
    // From 70,000 - 200,000
    LEVEL_TWO: 2,
    // From 200,000 - 500,000
    LEVEL_THREE: 3,
    // From 500,000 - above
    LEVEL_FOUR: 4,
};

export default getEnumObject(businessMonthlyTurnover);

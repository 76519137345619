export default {
    /**
     * src: https://github.com/sagalbot/vue-select/blob/master/src/mixins/pointerScroll.js
     */
    methods: {
        /**
         * Adjust the scroll position of the dropdown list
         * if the current pointer is outside of the
         * overflow bounds.
         * @returns {*}
         */
        fixScroll(container, index) {
            const pixelsToPointerTop = this.pixelsToPointerTop(
                container,
                index
            );
            const pixelsToPointerBottom = this.pixelsToPointerBottom(
                container,
                index
            );

            if (pixelsToPointerTop <= this.viewport(container).top) {
                return this.scrollTo(pixelsToPointerTop, container);
            } else if (
                pixelsToPointerBottom >= this.viewport(container).bottom
            ) {
                return this.scrollTo(
                    this.viewport(container).top +
                        this.pointerHeight(container, index),
                    container
                );
            }
        },

        /**
         * The distance in pixels from the top of the dropdown
         * list to the top of the current pointer element.
         * @returns {number}
         */
        pixelsToPointerTop(container, index) {
            let pixelsToPointerTop = 0;
            if (container) {
                for (let i = 0; i < index; i++) {
                    pixelsToPointerTop += container.children[i].offsetHeight;
                }
            }
            return pixelsToPointerTop;
        },

        /**
         * The distance in pixels from the top of the dropdown
         * list to the bottom of the current pointer element.
         * @returns {*}
         */
        pixelsToPointerBottom(container, index) {
            return (
                this.pixelsToPointerTop(container, index) +
                this.pointerHeight(container, index)
            );
        },

        /**
         * The offsetHeight of the current pointer element.
         * @returns {number}
         */
        pointerHeight(container, index) {
            const element = container ? container.children[index] : false;
            return element ? element.offsetHeight : 0;
        },

        /**
         * The currently viewable portion of the dropdownMenu.
         * @returns {{top: (string|*|number), bottom: *}}
         */
        viewport(container) {
            return {
                top: container ? container.scrollTop : 0,
                bottom: container
                    ? container.offsetHeight + container.scrollTop
                    : 0,
            };
        },

        /**
         * Scroll the dropdownMenu to a given position.
         * @param position
         * @returns {*}
         */
        scrollTo(position, container) {
            return container ? (container.scrollTop = position) : null;
        },
    },
};

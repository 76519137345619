<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
    color: { type: String, default: 'primary' },
    dismissable: { type: Boolean },
});

const emits = defineEmits(['on-dismiss']);
const isDismissed = ref(false);

const dismiss = () => {
    isDismissed.value = true;
    emits('on-dismiss');
};

const colors = {
    warning: 'bg-warning-400 text-warning-500',
    error: 'bg-negative-400 text-negative-500',
    success: 'bg-positive-400 text-positive-500',
    alert: 'bg-alert-400 text-alert-600',
    secondary: 'bg-secondary-100 text-secondary-500',
    primary: 'bg-primary-100 text-primary-500',
};

const style = computed(() => {
    return colors[props.color] ?? colors.primary;
});
</script>
<template>
    <div v-if="!isDismissed" :class="['fdx-badge', style]">
        <button v-bind="$attrs">
            <slot></slot>
        </button>
        <button v-if="dismissable" class="dismiss-btn" @click="dismiss">
            <svg-icon class="w-2 close-icon" icon="round-cross" />
        </button>
    </div>
</template>
<style scoped>
.fdx-badge {
    @apply text-caption-1 font-medium py-[0.125rem] px-2 rounded-md shadow-sm flex relative;
    box-shadow: 0px 1px 2px 0px #1018280d;
}
.dismiss-btn {
    @apply absolute -top-2 -end-1;
}
.dismiss-btn svg {
    margin: 0 !important;
}
</style>

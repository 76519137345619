import { getEnumObject } from '../utilities.js';

export const merchantAttachmentTypes = {
    'CR_CERTIFICATE_/_FREELANCE_LICENSE': 'cr_certificate',
    ARTICLES_OF_ASSOCIATION: 'article_of_association',
    CUSTOMER_AGREEMENT_CONTRACT: 'customer_agreement',
    SPECIAL_RATE_APPROVAL: 'special_rate_approval',
    FOODICS_ONLINE_CONTRACT: 'foodics_online_contract',
};

export default getEnumObject(merchantAttachmentTypes);

import { getEnumObject } from '../utilities.js';
import { pick } from 'lodash';
export const cpTransactionStatuses = {
    PENDING_RECONCILIATION: 1,
    PROCESSING: 2,
    PAYMENT_INITIATED: 3,
    SETTLED: 4,
    DECLINED: 5,
    PENDING_CAPTURE: 6,
};
export const cpTransactionStatusesEnum = getEnumObject(cpTransactionStatuses);

// Displayed only in the dashboard
export const cpDashboardTransactionStatuses = pick(cpTransactionStatuses, [
    'PENDING_RECONCILIATION',
    'PROCESSING',
    'PAYMENT_INITIATED',
    'SETTLED',
]);
export const cpDashboardTransactionStatusesEnum = getEnumObject(
    cpDashboardTransactionStatuses
);

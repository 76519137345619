const OtpContainer = () =>
    import('../Screens/Auth/OtpVerification/OtpContainer.vue');

export default [
    {
        path: '/otp/:sessionId',
        name: 'otpVerification',
        component: OtpContainer,
        props: true,
        meta: {
            mixpanelPageName: 'OTP',
            transition: 'slide-down',
            pageTitle: 'otp',
        },
    },
];

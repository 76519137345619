import { getEnumObject } from '../utilities.js';

export const contractTypes = {
    SALE: 1,
    LEASED: 2,
    BUNDLE: 3,
    VIP: 4,
    NO_MONTHLY_FEES: 5,
};

export default getEnumObject(contractTypes);

import { Bus } from '../bus.js';

export function alertError(message, subMessage, title = 'alert_label.error') {
    Bus.$emit('alert', {
        type: 'error',
        autoClose: 0,
        message: message,
        subMessage: subMessage,
        title: title,
    });
}

export function alertInfo(message, success, title = 'alert_label.info') {
    Bus.$emit('alert', {
        type: 'info',
        autoClose: 0,
        confirmationProceed: success,
        message: message,
        title: title,
    });
}

export function alertConfirm(
    message,
    success,
    failure,
    isDanger = true,
    confirmationProceedBtnText = 'yes',
    confirmationCancelBtnText = 'cancel',
    cancelOnClose = true,
    title = 'alert_label.confirm'
) {
    Bus.$emit('alert', {
        type: 'confirmation',
        autoClose: 0,
        message: message,
        confirmationProceed: success,
        confirmationCancel: failure,
        isDanger: isDanger,
        confirmationProceedBtnText: confirmationProceedBtnText,
        confirmationCancelBtnText: confirmationCancelBtnText,
        cancelOnClose: cancelOnClose,
        title: title,
    });
}

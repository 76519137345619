const ResetPasswordContainer = () =>
    import('../Screens/Auth/ResetPassword/ResetPasswordContainer.vue');

export default [
    {
        path: '/password/reset/:token',
        name: 'reset-password',
        component: ResetPasswordContainer,
        meta: {
            mixpanelPageName: 'Reset Password',
            transition: 'slide-down',
            pageTitle: 'reset_password',
        },
    },
];

<script>
export default {
    props: {
        widthClass: {
            type: String,
            default: 'max-w-lg min-w-[250px] sm:min-w-[350px] ',
        },
        drawer: {
            type: Boolean,
        },
    },
    emits: ['close'],

    data() {
        return {};
    },

    computed: {
        transitionName() {
            if (!this.drawer) {
                return 'fade-in';
            }

            if (window.innerWidth > 768) {
                return 'slide-right';
            }

            return 'slide-up';
        },
    },

    created() {
        document.addEventListener('keydown', this.handleEscape);
        document.body.classList.add('overflow-hidden');
    },

    unmounted() {
        document.removeEventListener('keydown', this.handleEscape);
        document.body.classList.remove('overflow-hidden');
    },

    methods: {
        handleEscape(e) {
            e.stopPropagation();

            if (e.keyCode == 27) {
                this.close();
            }
        },

        /**
         * Close the modal.
         */
        close() {
            this.$emit('close');
        },
    },
};
</script>

<template>
    <transition appear>
        <div
            class="z-1000000 fixed inset-0 overflow-y-scroll modal-mask flex"
            :class="[
                drawer
                    ? 'items-end md:items-center justify-end'
                    : 'p-4 items-center justify-center',
            ]">
            <transition :name="transitionName" appear>
                <div class="modal-container w-full" :class="[widthClass]">
                    <slot />
                </div>
            </transition>
        </div>
    </transition>
</template>

<style>
.modal-mask {
    background: rgba(0, 0, 0, 0.6);
    transition: opacity 0.2s ease;
}

.modal-container {
    transition: all 0.2s ease;
}
</style>

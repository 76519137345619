export default Object.freeze({
    NEW: {
        title: 'new',
        color: 'success',
    },
    BETA: {
        title: 'beta',
        color: 'primary',
    },
});

export default {
    enums: {
        accountTypes: {
            POOL_ACCOUNT: 'Pool Account',
            COMMISSION_ACCOUNT: 'Commission Account',
            SUBSCRIPTION_ACCOUNT: 'Subscription Account',
        },
        accountStatementTransactionLabels: {
            CREDIT_CARD_SETTLEMENT: 'Credit Card  Settlement',
            CREDIT_CARD_SETTLEMENT_REFUND: 'Credit Card Settlement Refund',
            MADA_CARD_SETTLEMENT: 'MADA Card Settlement',
            MADA_SETTLEMENT_FEES: 'MADA Settlement Fees',
            MADA_SETTLEMENT_VAT: 'MADA Settlement Vat',
            MADA_CARD_SETTLEMENT_REFUND: 'MADA Card Settlement Refund',
            AMEX_SETTLEMENT: 'Amex Settlement',
            MAESTRO_SETTLEMENT: 'Maestro Settlement',
            SETTLEMENT_OUT: 'Settlement Out',
            SARIE_FEES: 'Sarie Fees',
            RETURNED_PAYMENT: 'Returned Payout',
            UNKNOWN: 'Unknown',
        },
        adjustmentStatuses: {
            NEW: 'New',
            APPLIED: 'Applied',
            CANCELLED: 'Cancelled',
            LINKED: 'Linked',
            PARTIALLY_APPLIED: 'Partially Applied',
        },
        subAdjustmentStatuses: {
            LINKED: 'Linked',
            APPLIED: 'Applied',
        },
        adjustmentTypes: {
            SUBSCRIPTION: 'Subscription',
            DEDUCTION: 'Deduction',
            COMPENSATION: 'Compensation',
            LOAN: 'Loan',
            RMS_SUBSCRIPTION: 'RMS Subscription',
            SUPPLY_LOAN: 'Supply Loan',
        },
        loanTypes: {
            CAPTIAL: 'Loan',
            SUPPLY: 'Supply Loan',
        },
        auditableTypes: {
            ACCOUNT_STATEMENT: 'Account Statement',
            ADJUSTMENT: 'Adjustment',
            BANK_CHANNEL: 'Bank Channel',
            BANK: 'Bank',
            BRANCH: 'Branch',
            CARD_SCHEME_FEE: 'Card Scheme Fee',
            CHANNEL: 'Channel',
            DEVICE: 'Device',
            FOODICS_USER: 'Foodics User',
            MERCHANT_BANK_ACCOUNT: 'Merchant Bank Account',
            MERCHANT: 'Merchant',
            MERCHANT_USER: 'Merchant User',
            PAYMENT: 'Payout',
            SUBSCRIPTION: 'Subscription',
            TERMINAL: 'Terminal',
            TRANSACTION: 'Transaction',
        },
        auditLogStatuses: {
            FAILED: 'FAILED',
            SUCCESS: 'SUCCESS',
        },
        businessTypes: {
            FREELANCER: 'Freelancer',
            INDIVIDUAL_RETAILER: 'Individual Retailer',
            COMPANY: 'Company',
            FOREIGN_INVESTMENT: 'Foreign Investment',
        },
        communicationLanguages: {
            ENGLISH: 'English',
            ARABIC: 'Arabic',
        },
        contractTypes: {
            SALE: 'Sale',
            LEASED: 'Leased',
            BUNDLE: 'Bundle',
            VIP: 'VIP',
            NO_MONTHLY_FEES: 'No Monthly Fees',
        },
        deAssignTerminalStatuses: {
            PULL_OUT_REQUESTED: 'Pull Out Requested',
            PULLED_OUT: 'Pulled Out',
        },
        deviceStatuses: {
            ASSIGNED: 'Assigned',
            FREE: 'Free',
            DAMAGED_OR_LOST: 'Damaged or Lost',
            TEMPORARILY_UNAVAILABLE: 'Temporarily Unavailable',
        },
        deviceVendors: {
            INTERSOFT: 'Intersoft',
            ITS: 'ITs',
            ALHAMRANI: 'Alhamrani',
            MOMKN: 'Momkn',
            NEARPAY: 'NearPay',
            FOODICS_ONLINE: 'Foodics Online',
            NEOLEAP: 'Neoleap',
        },
        genders: {
            MALE: 'Male',
            FEMALE: 'Female',
        },
        paymentStatuses: {
            NEW: 'New',
            REQUESTED: 'Requested',
            PROCESSED: 'Processed',
            FAILED: 'Failed',
            FAILED_NO_RESPONSE: 'Failed No Response',
            REGENERATED: 'Regenerated',
            RETURNED: 'Returned',
            CANCELLED: 'Cancelled',
        },
        paymentTypes: {
            SETTLEMENT: 'Settlement',
            TRANSFER_FEE: 'Transfer Fee',
            MONTHLY_SUBSCRIPTION: 'Monthly Subscription',
            LOANS_INSTALLMENTS: 'Loan Installments',
            TRANSFER_FUNDS: 'Transfer Funds',
            RETURN_EMERGENCY_FUNDS: 'Return Emergency Funds',
            FLEX: 'Flex',
        },
        cpPayoutStatuses: {
            FAILED: 'Failed',
            SETTLED: 'Settled',
            PAYOUT_INITIATED: 'Payout Initiated',
        },
        samaIntegrationTypes: {
            ONGOING_CAPITAL: 'Ongoing Capital',
            NET_PROFIT: 'Net Profit',
        },
        terminalStatuses: {
            NEW: 'New',
            PENDING_INSTALLATION: 'Pending Installation',
            PENDING_BANK_REGISTRATION: 'Pending Bank Registration',
            INSTALLED: 'Installed',
            PULL_OUT_REQUESTED: 'Pull Out Requested',
            PULLED_OUT: 'Pulled Out',
            BANK_CHANGED: 'Bank Changed',
            CANCELLED: 'Cancelled',
            PENDING_TMS_REGISTRATION: 'Pending TMS Registration',
            WRITE_OFF: 'Write Off',
            LOST: 'Lost',
        },
        cpTerminalStatuses: {
            PENDING_ACTIVATION: 'Pending Activation',
            ACTIVE: 'Active',
            INACTIVE: 'Inactive',
        },
        ticketTypes: {
            TERMINAL_ISSUE: 'Terminal Issue',
            APP_ISSUE: 'App Issue',
            SUBSCRIPTION: 'Subscriprion ',
            INQUIRY: 'Inquiry',
            COMPLAINT: 'Complaint',
            SUGGESTION: 'Suggestion',
            OTHER: 'Other',
        },
        transactionStatuses: {
            NEW: 'New',
            PAYMENT_NEW: 'Payout New',
            PAYMENT_REQUESTED: 'Payout Requested',
            PAYMENT_FAILED: 'Payout Failed',
            SETTLED: 'Settled',
            PAYMENT_CANCELLED: 'Payout Cancelled',
            PAYMENT_REGENERATED: 'Payout Regenerated',
            UNCONFIRMED: 'Unconfirmed',
            WRITE_OFF: 'Write-off',
            POTENTIAL_FRAUD_SUSPENDED: 'Potential Fraud Suspended',
            FRAUD_SUSPENDED: 'Fraud Suspended',
            FRAUD_LOST: 'Fraud Lost',
            DECLINED: 'Declined',
            PENDING_CAPTURE: 'Pending Capture',
        },
        cardSchemes: {
            UNKNOWN: 'UNKNOWN',
            AMERICAN_EXPRESS: 'AMERICAN EXPRESS',
            DISCOVER: 'Discover',
            MAESTRO: 'MAESTRO',
            GCCNET: 'GCCNET',
            JCB: 'JCB',
            MASTER_CARD: 'MASTER CARD',
            MADA: 'MADA',
            SH: 'SH',
            UNIONPAY: 'UNIONPAY',
            VISA_CREDIT: 'VISA CREDIT',
            VISA_DEBIT: 'VISA DEBIT',
        },
        tmsCardSchemes: {
            MADA: 'MADA',
            CREDIT_CARD: 'Credit Cards',
            AMERICAN_EXPRESS: 'American Express',
            OTHER: 'Others',
        },
        terminalReconStatuses: {
            IN_BALANCE: 'In Balance',
            OUT_OF_BALANCE: 'Out of Balance',
        },
        mcc: {
            '742_VETERINARY_SERVICES': '742 Veterinary Services',
            '763_AGRICULTURAL_CO-OPERATIVE': '763 Agricultural Co-operative',
            '780_LANDSCAPING_AND_HORTICULTURAL':
                '780 Landscaping and Horticultural',
            '1520_GENERAL_CONTRACTORS': '1520 General Contractors',
            '1711_HEATING_PLUMBING_AIRCON': '1711 Heating,Plumbing,AirCon',
            '1731_ELECTRICAL_CONTRACTORS': '1731 Electrical Contractors',
            '1740_MASONRY_AND_INSULATION': '1740 Masonry and Insulation',
            '1750_CARPENTRY': '1750 Carpentry',
            '1761_ROOFING,_SIDING_&_METAL_WORKS':
                '1761 Roofing, Siding & Metal Works',
            '1771_CONTRACTORS_-_CONCRETE_WORK':
                '1771 Contractors - Concrete Work',
            '1799_SPECIAL_TRADE_CONTRACTORS': '1799 Special Trade Contractors',
            '2741_MISC_PUBLISHING_&_PRINTING':
                '2741 Misc Publishing & Printing',
            '2791_TYPESETTING/PLATE_MAKING_ETC':
                '2791 Typesetting/Plate Making Etc',
            '2842_SPECIALTY_CLEANING/POLISHING':
                '2842 Specialty Cleaning/Polishing',
            '3000_UNITED_AIRLINES': '3000 United Airlines',
            '3004_TRANS_WORLD_AIRLINES': '3004 Trans World Airlines',
            '3005_BRITISH_AIRWAYS': '3005 British Airways',
            '3006_JAPAN_AIRLINES': '3006 Japan Airlines',
            '3007_AIR_FRANCE': '3007 Air France',
            '3008_LUFTHANSA': '3008 Lufthansa',
            '3009_AIR_CANADA': '3009 Air Canada',
            '3010_KLM': '3010 KLM',
            '3012_QANTAS': '3012 Qantas',
            '3013_ALITALIA_AIRLINES': '3013 Alitalia Airlines',
            '3014_SAUDI_ARABIAN_AIRLINES': '3014 Saudi Arabian Airlines',
            '3015_SWISSAIR': '3015 Swissair',
            '3016_SAS': '3016 SAS',
            '3020_AIR_INDIA': '3020 Air India',
            '3021_AIR_ALGERIA': '3021 Air Algeria',
            '3022_PHILIPPINE_AIRLINES': '3022 Philippine Airlines',
            '3024_PAKISTAN_INTERNATIONAL_AIRLINES':
                '3024 Pakistan International Airlines',
            '3028_AIR_MALTA': '3028 Air Malta',
            '3031_OLYMPIC_AIRWAYS': '3031 Olympic Airways',
            '3037_EGYPT_AIRLINES': '3037 Egypt Airlines',
            '3038_KUWAIT_AIRWAYS': '3038 Kuwait Airways',
            '3040_GULF_AIR_(BAHRAIN)': '3040 Gulf Air (Bahrain)',
            '3048_ROYAL_AIR_MAROC': '3048 Royal Air Maroc',
            '3049_TUNIS_AIR': '3049 Tunis Air',
            '3051_AUSTRIAN_AIRLINES': '3051 Austrian Airlines',
            '3058_DELTA_AIRLINES': '3058 Delta Airlines',
            '3060_NORTHWEST': '3060 Northwest',
            '3061_CONTINENTAL_AIRLINES': '3061 Continental Airlines',
            '3063_US_AIR': '3063 U.S. Air',
            '3075_SINGAPORE_AIRLINES': '3075 Singapore Airlines',
            '3077_THAI_AIRWAYS': '3077 Thai Airways',
            '3082_KOREAN_AIRLINES': '3082 Korean Airlines',
            '3099_CATHAY_PACIFIC_AIRLINES': '3099 Cathay Pacific Airlines',
            '3100_MALAYSIAN_AIRLINE_SYSTEM': '3100 Malaysian Airline System',
            '3102_IBERIA': '3102 Iberia',
            '3111_BRITISH_MIDLAND': '3111 British Midland',
            '3135_SUDAN_AIRWAYS': '3135 Sudan Airways',
            '3175_MIDDLE_EAST_AIR': '3175 Middle East Air',
            '3192_IRAN_AIR': '3192 Iran Air',
            '3282_AIR_DJIBOUTI': '3282 Air Djibouti',
            '3292_CYPRUS_AIRWAYS': '3292 Cyprus Airways',
            '3294_ETHIOPIAN_AIRLINES': '3294 Ethiopian Airlines',
            '3295_KENYA_AIRWAYS': '3295 Kenya Airways',
            '3357_HERTZ_CORPORATION': '3357 Hertz Corporation',
            '3366_BUDGET_RENT-A-CAR': '3366 Budget Rent-A-Car',
            '3387_ALAMO_RENT-A-CAR': '3387 Alamo Rent-A-Car',
            '3389_AVIS_RENT-A-CAR': '3389 AVIS Rent-A-Car',
            '3390_DOLLAR_RENT-A-CAR': '3390 Dollar Rent-A-Car',
            '3393_NATIONAL_CAR_RENTAL': '3393 National Car Rental',
            '3395_THRIFTY_RENT-A-CAR': '3395 Thrifty Rent-A-Car',
            '3501_HOLIDAY_INN': '3501 Holiday Inn',
            '3503_SHERATON': '3503 Sheraton',
            '3504_HILTON': '3504 Hilton',
            '3505_TRUSTHOUSE_FORTE': '3505 Trusthouse Forte',
            '3509_MARRIOTT': '3509 Marriott',
            '3512_INTER-CONTINENTAL': '3512 Inter-Continental',
            '3520_MERIDIAN': '3520 Meridian',
            '3521_CREST_HOTELS': '3521 Crest Hotels',
            '3535_HILTON_INTERNATIONAL': '3535 Hilton International',
            '3570_FORUM_HOTELS': '3570 Forum Hotels',
            '3612_MOVENPICK_HOTELS': '3612 Movenpick Hotels',
            '3637_RAMADA_INN': '3637 Ramada Inn',
            '3640_HYATT': '3640 Hyatt',
            '3642_NOVOTEL': '3642 Novotel',
            '3649_RADISSON_HOTELS': '3649 Radisson Hotels',
            '4011_RAILROADS': '4011 Railroads',
            '4111_LOCAL_COMMUTER_TRANSPORT': '4111 Local Commuter Transport',
            '4112_PASSENGER_RAILWAYS': '4112 Passenger Railways',
            '4119_AMBULANCE_SERVICES': '4119 Ambulance Services',
            '4121_TAXICABS_/_LIMOUSINES': '4121 Taxicabs / Limousines',
            '4131_BUS_LINES,_TOUR_BUSES': '4131 Bus Lines, Tour Buses',
            '4214_MOTOR_FREIGHT_CARRIERS': '4214 Motor Freight Carriers',
            '4215_COURIER_SERVICES': '4215 Courier Services',
            '4225_PUBLIC_WAREHOUSING_AND_STORAGE':
                '4225 Public Warehousing and Storage',
            '4411_STEAMSHIP_/_CRUISE_LINES': '4411 Steamship / Cruise Lines',
            '4457_BOAT_RENTALS_&_LEASES': '4457 Boat Rentals & Leases',
            '4468_MARINAS': '4468 Marinas',
            '4511_AIRLINES,_AIR_CARRIERS': '4511 Airlines, Air Carriers',
            '4582_AIRPORTS,_FLYING_&_TERMINALS':
                '4582 Airports, Flying & Terminals',
            '4722_TRAVEL_AGENCIES': '4722 Travel Agencies',
            '4761_TRANSPORTATION_ARRANGEMENT':
                '4761 Transportation Arrangement',
            '4784_TOLLS_AND_BRIDGE_FEES': '4784 Tolls and Bridge Fees',
            '4789_TRANSPORTATION_SERVICES': '4789 Transportation Services',
            '4812_TELECOMMUNICATION_EQUIPMENT':
                '4812 Telecommunication Equipment',
            '4814_TELECOMMUNICATION_SERVICES':
                '4814 Telecommunication Services',
            '4816_COMPUTER_NETWORK_/_INFORMATION':
                '4816 Computer Network / Information',
            '4821_TELEGRAPH_SERVICES': '4821 Telegraph Services',
            '4829_MONEY_ORDER_-_REMITTANCE': '4829 Money Order - Remittance',
            '4899_CABLE/PAY_TV_SERVICES': '4899 CABLE/PAY TV SERVICES',
            '4900_UTILITIES,_ELEC,_GAS_&_WATER':
                '4900 Utilities, Elec, Gas & Water',
            '5013_MOTOR_VEHICLE_SUPPLY/NEW_PARTS':
                '5013 Motor Vehicle Supply/New Parts',
            '5021_OFFICE/COMMERCIAL_FURNITURE':
                '5021 Office/Commercial Furniture',
            '5039_CONSTRUCTION_MATERIALS': '5039 Construction Materials',
            '5044_OFFICE/PHOTO_EQUIPMENT': '5044 Office/Photo Equipment',
            '5045_COMPUTERS/PERIPHERALS/SOFTWARE':
                '5045 Computers/Peripherals/Software',
            '5046_COMMERCIAL_EQUIPMENT': '5046 Commercial Equipment',
            '5047_MEDICAL/DENTAL/LAB_EQUIPMENTS':
                '5047 Medical/Dental/Lab Equipments',
            '5051_METAL_SERVICE_CENTERS/OFFICES':
                '5051 Metal Service Centers/Offices',
            '5065_ELECTRICAL_PARTS_AND_EQUIPMENT':
                '5065 Electrical Parts and Equipment',
            '5072_HARDWARE_EQUIPMENT/SUPPLIES':
                '5072 Hardware Equipment/Supplies',
            '5074_PLUMBING/HEATING_EQUIPMENT':
                '5074 Plumbing/Heating Equipment',
            '5085_INDUSTRIAL_SUPPLIES_-_DEF': '5085 Industrial Supplies - DEF',
            '5094_PRECIOUS_STONES/METALS/JEWELRY':
                '5094 Precious Stones/Metals/Jewelry',
            '5099_DURABLE_ODS': '5099 Durable ods',
            '5111_STATIONERY/OFFICE_SUPPLIES':
                '5111 Stationery/Office Supplies',
            '5122_DRUGS/DRUGGIST_SUNDRIES': '5122 Drugs/Druggist Sundries',
            '5131_PIECE_ODS/NOTIONS/DRY_ODS': '5131 Piece ods/Notions/Dry ods',
            '5137_UNIFORMS_&_COMMERCIAL_CLOTHING':
                '5137 Uniforms & Commercial Clothing',
            '5139_COMMERCIAL_FOOTWEAR': '5139 Commercial Footwear',
            '5169_CHEMICALS_AND_ALLIED_PRODUCTS':
                '5169 Chemicals and Allied Products',
            '5172_PETROLEUM_&_PETROLEUM_PRODUCTS':
                '5172 Petroleum & Petroleum Products',
            '5192_BOOKS,_PERIODICALS_&_NEWSPAPERS':
                '5192 Books, Periodicals & Newspapers',
            '5193_FLORISTS_SUPPLIES,_NURSERY_STCK':
                '5193 Florists Supplies, Nursery Stck',
            '5198_PAINTS,_VARNISHES_AND_SUPPLIES':
                '5198 Paints, Varnishes and Supplies',
            '5199_NONDURABLE_ODS': '5199 Nondurable ods',
            '5200_HOME_SUPPLY_WAREHOUSE_STORES':
                '5200 Home Supply Warehouse Stores',
            '5211_BUILDING_MATERIALS_STORES': '5211 Building Materials Stores',
            '5231_GLASS_PAINTS_WALLPAPER_STORES':
                '5231 Glass,Paints & Wallpaper Stores',
            '5251_HARDWARE_STORES': '5251 Hardware Stores',
            '5261_NURSERIES_LAWN_GARDEN_STORES':
                '5261 Nurseries,Lawn & Garden Stores',
            '5271_MOBILE_HOME_DEALERS': '5271 Mobile Home Dealers',
            '5300_WHOLESALE_CLUBS': '5300 Wholesale Clubs',
            '5309_DUTY_FREE_STORES': '5309 Duty Free Stores',
            '5310_DISCOUNT_STORES': '5310 Discount Stores',
            '5311_DEPARTMENT_STORES': '5311 Department Stores',
            '5331_VARIETY_STORES': '5331 Variety Stores',
            '5399_MISCELLANEOUS': '5399 Miscellaneous',
            '5411_GROCERY_STORES,_SUPERMARKETS':
                '5411 Grocery Stores, Supermarkets',
            '5422_FREEZ&_LOCKER_MEAT_PROVISIONERS':
                '5422 Freez& Locker Meat Provisioners',
            '5441_CANDY_&_CONFECTIONERY_STORES':
                '5441 Candy & Confectionery Stores',
            '5451_DAIRY_PRODUCTS_STORES': '5451 Dairy Products Stores',
            '5462_BAKERIES': '5462 Bakeries',
            '5499_MISCELLANEOUS_FOOD_STORES': '5499 Miscellaneous Food Stores',
            '5511_AUTOMOBILE_AND_TRUCK_DEALERS':
                '5511 Automobile and Truck Dealers',
            '5521_AUTO_DEALERS_(USED_ONLY)': '5521 Auto Dealers (Used Only)',
            '5531_AUTO_&_HOME_SUPPLY_STORES': '5531 Auto & Home Supply Stores',
            '5532_AUTOMOTIVE_TIRE_STORES': '5532 Automotive Tire Stores',
            '5533_AUTOMOTIVE_PARTS,_ACCESSORIES':
                '5533 Automotive Parts, Accessories',
            '5541_SERVICE_STATIONS': '5541 Service Stations',
            '5542_AUTOMATED_GASOLINE_DISPENSERS':
                '5542 Automated Gasoline Dispensers',
            '5551_BOAT_DEALERS': '5551 Boat Dealers',
            '5561_RECREATIONAL_&_UTILITY': '5561 Recreational & Utility',
            '5571_MOTORCYCLE_DEALERS': '5571 Motorcycle Dealers',
            '5592_MOTOR_HOME_DEALERS': '5592 Motor Home Dealers',
            '5598_SNOWMOBILE_DEALERS': '5598 Snowmobile Dealers',
            '5599_MISCELLANEOUS_AUTO_DEALERS':
                '5599 Miscellaneous Auto Dealers',
            '5611_MENS_CLOTHING_AND_ACCESSORY':
                '5611 MenS Clothing and Accessory',
            '5621_WOMENS_READY-TO-WEAR_STORES':
                '5621 WomenS Ready-To-Wear Stores',
            '5631_WOMENS_ACCESSORY_&_SPECIALTY':
                '5631 WomenS Accessory & Specialty',
            '5641_CHILDRENS_AND_INFANTS_WEAR':
                "5641 ChildrenS and Infants' Wear",
            '5651_FAMILY_CLOTHING_STORES': '5651 Family Clothing Stores',
            '5655_SPORTS_AND_RIDING_APPAREL_STORE':
                '5655 Sports and Riding Apparel Store',
            '5661_SHOE_STORES': '5661 Shoe Stores',
            '5681_FURRIERS_AND_FUR_SHOPS': '5681 Furriers and Fur Shops',
            '5691_MENS_AND_WOMENS_CLOTHING': '5691 MenS and WomenS Clothing',
            '5697_TAILORS_SEAMSTRESSES_MENDING':
                '5697 Tailors,Seamstresses,Mending',
            '5698_WIG_AND_TOUPEE_STORES': '5698 Wig and Toupee Stores',
            '5699_MISC_APPAREL_&_ACCESSORY_SHOPS':
                '5699 Misc Apparel & Accessory Shops',
            '5712_FURNITURE,_EXCEPT_APPLIANCES':
                '5712 Furniture, except Appliances',
            '5713_FLOOR_COVERING_STORES': '5713 Floor Covering Stores',
            '5714_DRAPERY_&_UPHOLSTERY_STORES':
                '5714 Drapery & Upholstery Stores',
            '5718_FIREPLACES_&_ACCESSORIES_STORES':
                '5718 Fireplaces & Accessories Stores',
            '5719_MISCELLANEOUS_HOME_FURNISHING':
                '5719 Miscellaneous Home Furnishing',
            '5722_HOUSEHOLD_APPLIANCE_STORES':
                '5722 Household Appliance Stores',
            '5731_USED_MERCHANDISE_STORES': '5731 Used Merchandise Stores',
            '5732_RADIO_TELEVISION_STEREO_SALES':
                '5732 Radio,Television,Stereo Sales',
            '5733_MUSIC_STORES': '5733 Music Stores',
            '5734_COMPUTER_SOFTWARE_STORES': '5734 Computer Software Stores',
            '5735_RECORD_SHOPS': '5735 Record Shops',
            '5811_CATERERS': '5811 Caterers',
            '5812_EATING_PLACES,_RESTAURANTS':
                '5812 Eating Places, Restaurants',
            '5814_FAST_FOOD_RESTAURANTS': '5814 Fast Food Restaurants',
            '5912_DRUG_STORES_&_PHARMACIES': '5912 Drug Stores & Pharmacies',
            '5921_PKG_STORES_-_BEER,_WINE,_LIQUOR':
                '5921 Pkg Stores - Beer, Wine, Liquor',
            '5931_USED_&_SECONDHAND_MERCHANDISE':
                '5931 Used & Secondhand Merchandise',
            '5932_ANTIQUE_SHOPS': '5932 Antique Shops',
            '5933_PAWN_SHOPS': '5933 Pawn Shops',
            '5935_WRECKING_AND_SALVAGE_YARDS':
                '5935 Wrecking and Salvage Yards',
            '5937_WRECKING_AND_SALVAGE_YARDS':
                '5937 Wrecking and Salvage Yards',
            '5940_BICYCLE_SHOPS': '5940 Bicycle Shops',
            '5941_SPORTING_ODS_STORES': '5941 Sporting ods Stores',
            '5942_BOOK_STORES': '5942 Book Stores',
            '5943_STATIONERY_STORES': '5943 Stationery Stores',
            '5944_JEWELRY_STORES': '5944 Jewelry Stores',
            '5945_HOBBY,_TOY,_&_GAME_SHOPS': '5945 Hobby, Toy, & Game Shops',
            '5946_CAMERA_&_PHOTOGRAPHIC_SUPPLY':
                '5946 Camera & Photographic Supply',
            '5947_GIFT,_CARD,_NOVELTY,_&_SOUVENIR':
                '5947 Gift, Card, Novelty, & Souvenir',
            '5948_LUGGAGE_&_LEATHER_ODS_STORES':
                '5948 Luggage & Leather ods Stores',
            '5949_SEWING_AND_PIECE_ODS_STORES':
                '5949 Sewing and Piece ods Stores',
            '5950_GLASSWARE/CRYSTAL_STORES': '5950 Glassware/Crystal Stores',
            '5960_DIRECT_MARKETING-INSURANCE_SVC':
                '5960 Direct Marketing-Insurance Svc',
            '5962_DIRECT_MKTG-TRAVEL-RELATED_SVC':
                '5962 Direct Mktg-Travel-Related Svc',
            '5963_DOOR-TO-DOOR_SALES': '5963 Door-To-Door Sales',
            '5964_CATALOG_MERCHANT': '5964 Catalog Merchant',
            '5965_COMBINATION_CATALOG_&_RETAIL':
                '5965 Combination Catalog & Retail',
            '5966_OUTBOUND_TELEMARKETING_MERCHANT':
                '5966 Outbound Telemarketing Merchant',
            '5967_INBOUND_TELEMARKETING_MERCHANT':
                '5967 Inbound Telemarketing Merchant',
            '5968_CONTINUITY/SUBSCRIPTION_MERCHNT':
                '5968 Continuity/Subscription Merchnt',
            '5969_DIRECT_MARKETING_/_MARKETEERS':
                '5969 Direct Marketing / Marketeers',
            '5970_ARTISTS_SUPPLY_AND_CRAFT_SHOPS':
                '5970 Artists Supply and Craft Shops',
            '5971_ART_DEALERS_&_GALLERIES': '5971 Art Dealers & Galleries',
            '5972_STAMP_AND_COIN_STORES': '5972 Stamp and Coin Stores',
            '5973_RELIGIOUS_ODS_STORES': '5973 Religious ods Stores',
            '5975_HEARING_AIDS_-_SALES,_SERVICE':
                '5975 Hearing Aids - Sales, Service',
            '5976_ORTHOPEDIC_ODS': '5976 Orthopedic ods',
            '5977_COSMETIC_STORES': '5977 Cosmetic Stores',
            '5978_TYPEWRITER_STORES': '5978 Typewriter Stores',
            '5983_FUEL_DEALERS': '5983 Fuel Dealers',
            '5992_FLORISTS': '5992 Florists',
            '5993_CIGAR_STORES_AND_STANDS': '5993 Cigar Stores and Stands',
            '5994_NEWS_DEALERS_&_NEWSSTANDS': '5994 News Dealers & Newsstands',
            '5995_PET_SHOPS,_FOODS_AND_SUPPLIES':
                '5995 Pet Shops, Foods and Supplies',
            '5996_SWIMMING_POOLS': '5996 Swimming Pools',
            '5997_ELECTRIC_RAZOR_STORES/SERVICE':
                '5997 Electric Razor Stores/Service',
            '5998_TENT_AND_AWNING_SHOPS': '5998 Tent and Awning Shops',
            '5999_MISCELLANEOUS&_SPECIALTY_RETAIL':
                '5999 Miscellaneous& Specialty Retail',
            '6010_FINANCIAL_INST/MANUAL_CASH_DISB':
                '6010 Financial Inst/Manual Cash Disb',
            '6011_FINANCIAL_INST/AUTO_CASH_DISBRS':
                '6011 Financial Inst/Auto Cash Disbrs',
            '6012_FINANCIAL_INSTITUTIONS-MERCHAND':
                '6012 Financial Institutions-Merchand',
            '6051_NON-FINANCIAL_INST/FC/MO/TC':
                '6051 Non-Financial Inst/FC/MO/TC',
            '6211_SECURITY_BROKERS/DEALERS': '6211 Security Brokers/Dealers',
            '6300_INSURANCE_SERVICES': '6300 Insurance services',
            '6513_REAL_ESTATE_AGENTS_MANAGER-RENT':
                '6513 Real Estate Agents Manager-Rent',
            '7011_HOTELS_&_RESORTS_-_LODGING':
                '7011 Hotels & Resorts - Lodging',
            '7012_TIMESHARES': '7012 Timeshares',
            '7032_SPORTING_&_RECREATIONAL_CAMPS':
                '7032 Sporting & Recreational Camps',
            '7033_TRAILER_PARKS_AND_CAMPGROUNDS':
                '7033 Trailer Parks and Campgrounds',
            '7210_LAUNDRY,_CLEANING,_&_GARMENT':
                '7210 Laundry, Cleaning, & Garment',
            '7211_LAUNDRIES-FAMILY_AND_COMMERCIAL':
                '7211 Laundries-Family and Commercial',
            '7216_DRY_CLEANERS': '7216 Dry Cleaners',
            '7217_CARPET_AND_UPHOLSTERY_CLEANING':
                '7217 Carpet and Upholstery Cleaning',
            '7221_PHOTOGRAPHIC_STUDIOS': '7221 Photographic Studios',
            '7230_BARBER_&_BEAUTY_SHOPS': '7230 Barber & Beauty Shops',
            '7251_SHOE_REPAIR/SHINE/HAT_CLEANING':
                '7251 Shoe Repair/Shine/Hat Cleaning',
            '7261_FUNERAL_SERVICES_&_CREMATORIES':
                '7261 Funeral Services & Crematories',
            '7273_DATING_SERVICES': '7273 Dating Services',
            '7276_TAX_PREPARATION_SERVICES': '7276 Tax Preparation Services',
            '7277_COUNSELING_SERVICES_-_ALL': '7277 Counseling Services - All',
            '7278_BUYING_&_SHOPPING_SERVICES':
                '7278 Buying & Shopping Services',
            '7296_RENTAL_CLOTHING/COSTUMES/UNIFRM':
                '7296 Rental Clothing/Costumes/Unifrm',
            '7297_MASSAGE_PARLORS': '7297 Massage Parlors',
            '7298_HEALTH_AND_BEAUTY_SPAS': '7298 Health and Beauty Spas',
            '7299_REAL_ESTATE_INVESTMENT': '7299 Real Estate Investment',
            '7311_ADVERTISING_SERVICES': '7311 Advertising Services',
            '7321_CONSUMER_CREDIT_REPORT_AGENCIES':
                '7321 Consumer Credit Report Agencies',
            '7333_COMMERCIAL_PHOTO/ART/GRAPHICS':
                '7333 Commercial Photo/Art/Graphics',
            '7338_QUICK_COPY,_REPRODUCTION_AND_BL':
                '7338 Quick Copy, Reproduction and Bl',
            '7339_STENOGRAPHIC/SECRETARIAL_SVC':
                '7339 Stenographic/Secretarial Svc',
            '7342_EXTERMINATING_AND_DISINFECTING':
                '7342 Exterminating and Disinfecting',
            '7349_CLEANING_MAINTENANCE_JANITORIAL':
                '7349 Cleaning Maintenance Janitorial',
            '7361_EMPLOYMENT_AGENCIES_AND_TEMPORA':
                '7361 Employment Agencies and Tempora',
            '7372_COMPUTER_AND_DATA_PROCESSING':
                '7372 Computer and Data Processing',
            '7375_INFORMATION_RETRIEVAL_SERVICES':
                '7375 Information Retrieval Services',
            '7379_COMPUTER_MAINTENANCE_AND_REPAIR':
                '7379 Computer Maintenance and Repair',
            '7392_MANAGEMENT_CONSULTING': '7392 Management,Consulting',
            '7393_DETECTIVE_&_PROTECTIVE_AGENCIES':
                '7393 Detective & Protective Agencies',
            '7394_EQUIPMENT_RENTAL_&_LEASING':
                '7394 Equipment Rental & Leasing',
            '7395_PHOTOFINISHING_LABORATORIES':
                '7395 Photofinishing Laboratories',
            '7399_BUSINESS_SERVICES': '7399 Business Services',
            '7512_AUTO_RENTALS_&_LEASING': '7512 Auto Rentals & Leasing',
            '7513_TRUCK/UTILITY_TRAILER_RENTALS':
                '7513 Truck/Utility Trailer Rentals',
            '7519_MOTOR_HOME/RV_RENTALS': '7519 Motor Home/RV Rentals',
            '7523_PARKING_LOTS_METERS_GARAGES':
                '7523 Parking Lots Meters Garages',
            '7531_AUTOMOTIVE_BODY_REPAIR_SHOPS':
                '7531 Automotive Body Repair Shops',
            '7534_TIRE_RETREADING_&_REPAIR_SHOPS':
                '7534 Tire Retreading & Repair Shops',
            '7535_AUTOMOTIVE_PAINT_SHOPS': '7535 Automotive Paint Shops',
            '7538_AUTOMOTIVE_REPAIR_SHOPS': '7538 Automotive Repair Shops',
            '7542_CAR_WASHES': '7542 Car Washes',
            '7549_TOWING_SERVICES': '7549 Towing Services',
            '7622_RADIO_TELEVISION_STEREO_REPAIR':
                '7622 Radio,Television,Stereo Repair',
            '7623_AIR_CONDITIONING&_REFRIGERATION':
                '7623 Air Conditioning& Refrigeration',
            '7629_ELECTRICAL_REPAIR_SHOPS': '7629 Electrical Repair Shops',
            '7631_WATCH,_CLOCK_AND_JEWELRY_REPAIR':
                '7631 Watch, Clock and Jewelry Repair',
            '7641_REUPHOLSTERY_&_FURNITURE_REPAIR':
                '7641 Reupholstery & Furniture Repair',
            '7692_WELDING_SERVICES': '7692 Welding Services',
            '7699_MISCELLANEOUS_REPAIR_SHOPS':
                '7699 Miscellaneous Repair Shops',
            '7829_FILMS/VIDEO_PRODUCTION_DISTRBTN':
                '7829 Films/Video Production Distrbtn',
            '7832_MOTION_PICTURE_THEATERS': '7832 Motion Picture Theaters',
            '7841_VIDEO_TAPE_RENTAL_STORES': '7841 Video Tape Rental Stores',
            '7911_DANCE_HALLS/STUDIOS/SCHOOLS':
                '7911 Dance Halls/Studios/Schools',
            '7922_THEATRICAL_PRODUCERS/TCKT_AGENT':
                '7922 Theatrical Producers/Tckt Agent',
            '7929_BANDS/ORCHESTRAS/ENTERTAINERS':
                '7929 Bands/Orchestras/Entertainers',
            '7932_BILLIARD_AND_POOL_ESTABLISHMENT':
                '7932 Billiard and Pool Establishment',
            '7933_BOWLING_ALLEYS': '7933 Bowling Alleys',
            '7941_COMMERCIAL/PRO_SPORTS_&_CLUBS':
                '7941 Commercial/Pro Sports & Clubs',
            '7991_TOURIST_ATTRACTIONS_AND_EXHIBIT':
                '7991 Tourist Attractions and Exhibit',
            '7992_PUBLIC_LF_COURSES': '7992 Public lf Courses',
            '7993_VIDEO_AMUSEMENT_GAME_SUPPLIES':
                '7993 Video Amusement Game Supplies',
            '7994_VIDEO_GAME_ARCADES/ESTABLISHMNT':
                '7994 Video Game Arcades/Establishmnt',
            '7995_BETTING/TRACK/CASINO/LOTTO':
                '7995 Betting/Track/Casino/Lotto',
            '7996_AMUSEMENT_PARKS': '7996 Amusement Parks',
            '7997_MEMBERSHIP_CLUBS': '7997 Membership Clubs',
            '7998_AQUARIUMS,_DOLPHINARIUMS,_ZOOS':
                '7998 Aquariums, Dolphinariums, Zoos',
            '7999_RECREATIONAL_FACILITIES': '7999 Recreational Facilities',
            '8011_DOCTORS,_PHYSICIANS': '8011 Doctors, Physicians',
            '8021_DENTISTS,_ORTHODONTISTS': '8021 Dentists, Orthodontists',
            '8031_OSTEOPATHS': '8031 Osteopaths',
            '8041_CHIROPRACTORS': '8041 Chiropractors',
            '8042_OPTOMETRISTS/OPHTHALMOLOGISTS':
                '8042 Optometrists/Ophthalmologists',
            '8043_OPTICIANS': '8043 Opticians',
            '8049_PODIATRISTS_AND_CHIROPODISTS':
                '8049 Podiatrists and Chiropodists',
            '8050_CONVALESCENT_HOMES': '8050 Convalescent Homes',
            '8062_HOSPITALS': '8062 Hospitals',
            '8071_MEDICAL_&_DENTAL_LABORATORIES':
                '8071 Medical & Dental Laboratories',
            '8099_MEDICAL_&_HEALTH_SERVICES': '8099 Medical & Health Services',
            '8111_LEGAL_SERVICES,_ATTORNEYS': '8111 Legal Services, Attorneys',
            '8211_ELEMENTARY_AND_SECONDARY_SCHOOL':
                '8211 Elementary and Secondary School',
            '8220_COLLEGES/UNIV/JC/PROFESSION_SCH':
                '8220 Colleges/Univ/JC/Profession Sch',
            '8241_CORRESPONDENCE_SCHOOLS': '8241 Correspondence Schools',
            '8244_BUSINESS_AND_SECRETARIAL_SCHOOL':
                '8244 Business and Secretarial School',
            '8249_VOCATIONAL_AND_TRADE_SCHOOLS':
                '8249 Vocational and Trade Schools',
            '8299_SCHOOLS_&_EDUCATIONAL_SERVICES':
                '8299 Schools & Educational Services',
            '8351_CHILD_CARE_SERVICES': '8351 Child Care Services',
            '8398_CHARITABLE_AND_SOCIAL_SERVICE_O':
                '8398 Charitable and Social Service O',
            '8641_CIVIC,_SOCIAL,_FRATERNAL_ASSOC':
                '8641 Civic, Social, Fraternal Assoc',
            '8651_POLITICAL_ORGANIZATIONS': '8651 Political Organizations',
            '8661_RELIGIOUS_ORGANIZATIONS': '8661 Religious Organizations',
            '8675_AUTOMOBILE_ASSOCIATIONS': '8675 Automobile Associations',
            '8699_MEMBERSHIP_ORGANIZATIONS': '8699 Membership Organizations',
            '8734_TESTING_LABS_(NON-MEDICAL)':
                '8734 Testing Labs (Non-Medical)',
            '8911_ARCHITECTURAL,_ENGINEERING,':
                '8911 Architectural, Engineering,',
            '8931_ACCOUNTING_AUDITING_BOOKKEEPING':
                '8931 Accounting,Auditing,Bookkeeping',
            '8999_PROFESSIONAL_SERVICES': '8999 Professional Services',
            '9211_COURT_COSTS': '9211 Court Costs',
            '9222_FINES': '9222 Fines',
            '9223_BAIL_AND_BOND_PAYMENTS': '9223 Bail and Bond Payouts',
            '9311_TAX_PAYMENTS': '9311 Tax Payouts',
            '9399_VERNMENT_SERVICES': '9399 vernment Services',
            '9402_POSTAL_SERVICES_-_VT_ONLY': '9402 Postal Services - vt Only',
            '9405_INTRA-VERNMENT_PURCHASES': '9405 Intra-vernment Purchases',
            '9700_AUTOMATED_REFERRAL_SERVICE':
                '9700 Automated Referral Service',
            '9702_EMERGENCY_SERVICES_(GCAS)': '9702 Emergency Services (GCAS)',
            '9950_INTRA-COMPANY_PURCHASES': '9950 Intra-Company Purchases',
        },
        deviceModels: {
            PAXS_900: 'PAXS_900',
            PAXD_200: 'PAXD_200',
            NEWLAND_SP_630: 'NEWLAND_SP_630',
            NEWLAND_SP_930: 'NEWLAND_SP_930',
            AMP_7000: 'AMP_7000',
            MOVE_2500: 'MOVE_2500',
            SOFT_POS: 'SOFT_POS',
            NEWLAND_N_910: 'NEWLAND_N_910',
            IMIN_M2_MAX: 'IMIN_M2_MAX',
            IMIN_FI22: 'IMIN_FI22',
            NEWLAND_X_800: 'NEWLAND_X_800',
            UROVO_I_9100: 'UROVO_I_9100',
        },
        onboardRequestStatuses: {
            NEW: 'New',
            SUBMITTED: 'Submitted',
            PENDING: 'Pending',
            APPROVED: 'Approved',
            REJECTED: 'Rejected',
            FINAL_REJECTED: 'Final Rejected',
        },
        onBoardRequestSources: {
            WEB: 'Web',
            FOODICS_ONLINE: 'Foodics Online',
            ZOHO: 'Zoho',
        },
        cpTransactionStatuses: {
            PENDING_RECONCILIATION: 'Pending Reconciliation',
            PROCESSING: 'Processing',
            PAYMENT_INITIATED: 'Payout Initiated',
            SETTLED: 'Settled',
            DECLINED: 'Declined',
            PENDING_CAPTURE: 'Pending Capture',
        },
        cpDashboardTransactionStatuses: {
            PENDING_RECONCILIATION: 'Pending Reconciliation',
            PROCESSING: 'Processing',
            PAYMENT_INITIATED: 'Payout Initiated',
            SETTLED: 'Settled',
        },
        simCardProviders: {
            STC: 'STC',
            MOBILY: 'MOBILY',
            LIBARA: 'LIBARA',
            SKYBAND: 'SKYBAND',
        },
        loanStatuses: {
            PENDING: 'Pending',
            COLLECTED: 'Collected',
            CANCELED: 'Canceled',
        },
        bankAccountsStatuses: {
            APPROVED: 'Approved',
            PENDING: 'Pending Review',
            REJECTED: 'Rejected',
        },
        pendingRequestsTypes: {
            CardSchemeFee: 'Card Scheme Fee',
            MerchantBankAccount: 'Merchant Bank Account',
            Merchant: 'Merchant',
            Subscription: 'Subscription',
            Adjustment: 'Adjustment',
            BankChannel: 'Bank Channel',
            Payment: 'Payment',
            Transaction: 'Transaction',
            SuperMerchantBankAccount: 'Super Merchant Bank Account',
            SuperMerchantSubscription: 'Super Merchant Subscription',
            CardSchemeCommission: 'Card Scheme Commission',
            SuperMerchant: 'Super Merchant',
            Terminal: 'Terminal',
        },
        tidStatuses: {
            UNREGISTERED: 'Unregistered',
            ASSIGNED: 'Assigned',
            DAMAGED_LOST: 'Damaged Lost',
            REUSABLE: 'Reusable',
            PREREGISTERED: 'Preregistered',
        },
        tidTypes: {
            INTERSOFT: 'Intersoft',
            ITS: 'ITs',
            ALHAMRANI: 'Alhamrani',
            MOMKN: 'Momkn',
            NEARPAY: 'NearPay',
            NEAOLEAP: 'Neoleap',
        },
    },
    color_picker: {
        choose: 'Select Color',
        notEnabledColor: 'Color is already used',
        noOptions: 'No colors available',
    },
    select_input: {
        loading: 'Loading...',
        choose: 'Choose...',
        start_searching: 'Type something to start searching',
        searching: 'Searching...',
        none: 'None',
        select_all: 'Select All',
        nothing_found: 'Nothing was found!',
    },
    count_down: {
        minutes: 'minutes',
        seconds: 'seconds',
        time_remaining: 'Time remaining:',
    },
    phone_number: {
        dial_code: 'Dial Code',
        no_results: 'Nothing was found!',
    },
    button_label: {
        filter: 'Filter',
        next: 'Next',
        previous: 'Previous',
        clear: 'Clear',
        apply: 'Apply',
        back: 'Back',
        close: 'Close',
        disable: 'Disable',
        enable: 'Enable',
        save: 'Save',
        submitting: 'Submitting...',
    },
    activation_button: {
        are_you_sure_you_want_to_disable_this:
            'Are you sure you want to disable this?',
        are_you_sure_you_want_to_enable_this:
            'Are you sure you want to enable this?',
    },
    form_modal: {
        confirmation_message:
            'I have verified all the changes in this request. Any monetary loss incurred due to me entering wrong fees will be deducted from my salary.',
    },
    listing_screen: {
        showing: 'Showing',
        to: 'To',
        out_of: 'Out of',
        selected: 'Selected',
        total: 'Total',
    },
    date_picker: {
        today: 'Today',
        yesterday: 'Yesterday',
        this_week: 'This Week',
        last_week: 'Last Week',
        this_month: 'This Month',
        last_month: 'Last Month',
        custom: 'Custom',
        Su: 'Su',
        Mo: 'Mo',
        Tu: 'Tu',
        We: 'We',
        Th: 'Th',
        Fr: 'Fr',
        Sa: 'Sa',
    },
    misc: {
        import: 'Import',
        export: 'Export',
        any: 'Any',
        none: 'None',
        SAR: 'SAR {number}',
        foodics: 'Foodics',
        foodics_pay: 'Foodics Pay',
        max_export_limit_exceed_message:
            'The amount of data is too large to export.',
        max_export_limit_exceed_submessage:
            'Please use the filter function to reduce the export size to within 300,000 records.',
        background_success_message:
            'Data is being exported in the background and you will receive an email when the export is done',
        yes: 'Yes',
        no: 'No',
        selected: 'Selected',
        clear_all_filters: 'Clear All',
        external: 'External',
        zakat_authority_compliant: 'Zakat Authority Compliant',
        licensed_by_sama: 'Licensed by SAMA',
        pending_review: 'Pending Review',
        enabled: 'Enabled',
        disabled: 'Disabled',
    },

    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',

    alert_label: {
        yes: 'Yes',
        cancel: 'Cancel',
        ok: 'Ok',
        confirm: 'Confirm',
        error: 'Error',
        info: 'Info',
    },

    chip_filters: {
        account_number: 'Account Number',
        action: 'Action',
        auditable_type: 'Auditable Type',
        bank_channel_id: 'Bank Account',
        bank_id: 'Bank',
        branch_id: 'Branch',
        business_name: 'Business Name',
        business_reference: 'F5 Account Number',
        business_type: 'Business Type',
        card_scheme: 'Card Scheme',
        channel_id: 'Channel',
        commercial_registration: 'CR Number',
        created_on: 'Created On',
        created_at: 'Created At',
        creator_type: 'Creator Type',
        credit_bank_id: 'Credit Bank',
        date_range: 'Date Range',
        debit_bank_id: 'Debit Bank',
        device_model: 'Device Model',
        email: 'Email',
        end_date: 'End Date',
        end_of_life_date_after: 'End Of Life Date After',
        end_of_life_date_before: 'End Of Life Date Before',
        has_pending_review_record: 'Pending Review',
        ip_address: 'IP Address',
        is_active: 'Enabled',
        is_approved: 'Approved',
        is_failed: 'Failed',
        is_integrated: 'Integrated',
        is_migrated: 'Migrated',
        is_external: 'External',
        manufacture_date_after: 'Manufacture Date After',
        manufacture_date_before: 'Manufacture Date Before',
        merchant_id: 'Merchant Name',
        mobile_number: 'Mobile Number',
        monthRange: 'Month Range',
        name: 'Name',
        national_id: 'National ID',
        owner_ekyc_completed: 'eKyc Completed',
        payment_id: 'Payout',
        recon_number: 'Recon Number',
        reconciliation_number: 'Reconciliation Number',
        reference: 'Reference',
        sub_adjustment_reference: 'Sub-Adjustment Reference',
        reported_by_bank_on: 'Reported By Bank On',
        serial_number: 'Serial Number',
        settleable: 'Settleable',
        settlement_enabled: 'Settlement Enabled',
        short_code: 'Short Code',
        source: 'Source',
        start_date: 'Start Date',
        statement_date: 'Statement Date',
        status: 'Status',
        subscriptions_bank_id: 'B2B Bank',
        swift_code: 'Swift Code',
        terminal_id8: 'Terminal ID8',
        transaction_date_on: 'Transaction Date On',
        transaction_number: 'Transaction Number',
        transfer_fee_payment_id: 'Transfer Fee Payout',
        type: 'Type',
        vendor: 'Vendor',
        transaction_id: 'Transaction Number',
        request_reference: 'Request Reference',
        statement_id: 'Statement',
        onboard_request_ref: 'Onboarding Reference',
        is_money_received: 'Is Money Received',
        regeneratable: 'Regeneratable',
        is_deleted: 'Deleted',
        deleted: 'Deleted',
        reported_by_bank_range: 'Reported By Bank Range',
        super_merchant_id: 'Super Merchant Name',
        sub_account_payment_id: 'Sub-Account Payout',
        terminal_resolved: 'Terminal Resolved',
        batch_id: 'Action',
        terminal_type: 'Terminal Type',
        request_date: 'Request date',
        created_on_range: 'Created On',
        creator_id: 'Created By',
        is_actionable: 'Requires action',
        eligible_at: 'Eligible At',
        has_guaranteed_settlement: 'Guaranteed Settlement',
        stan: 'STAN',
        activation_otp: 'Activation OTP',
        trsm: 'TRSM',
        active: 'Active',
        city_id: 'City',
        settled: 'Settled',
        is_reversed: 'Reversed',
        order_reference: 'Order Reference',
        user_id: 'User ID',
        order_id: 'Order ID',
        label: 'Status',
        label_settled: 'Settled',
        unverified_bank_accounts: 'Unverified Bank Accounts',
        pending_installation_terminals: 'Pending Installation Terminals',
        customer_agreement_missing: 'Customer Agreement Missing',
        cr_expiring_soon: 'CR Expiring Soon',
        unverified_cr: 'Unverified CR',
        unverified_owner_nids: 'Unverified Owner NIDs',
        unverified_mobile_number: 'Unverified Mobile Number',
        disabled_merchant_transactions: 'Disabled Merchant Transactions',
        alinma_daily_unsettleable_transactions:
            'Alinma Daily Unsettleable Transactions',
        alrajhi_daily_unsettleable_transactions:
            'Alrajhi Daily Unsettleable Transactions',
        other_banks_daily_unsettleable_transactions:
            'Other Banks Daily Unsettleable Transactions',
        alinma_unsuccessful_payments_last_month:
            'Alinma Unsuccessful Payouts Last Month',
        alrajhi_unsuccessful_payments_last_month:
            'Alrajhi Unsuccessful Payouts Last Month',
        other_banks_unsuccessful_payments_last_month:
            'Other Banks Unsuccessful Payouts Last Month',
        integrated_terminal_transactions: 'Integrated Terminal Transactions',
        for_channels: 'Channel',
        default_bank_account_id: 'Bank Account',
        virtual_status: 'Status',
        transaction_fees: 'Transaction Fees',
        miscellaneous_fees: 'Miscellaneous Fees',
        intersoft_v1_terminals: 'Intersoft V1 Terminals',
        settleable_yet_unsettled_transactions:
            'Settleable Yet Unsettled Transactions',
        unresolved_transactions: 'Unresolved Transactions',
    },
    search: {
        search_by: 'Search By',
    },
    only_upload: 'Only JPEG, JPG, PNG and PDF files',
    no_data_to_display: 'No data to display',
    required_add_or_updated_one_field:
        'You need to add or update at least one field before saving changes.',
    not_authorized: 'You are not authorized to perform this action.',
    not_enough_Unregistered_tids:
        'There’s not enough Unregistered TIDs to auto-create the needed terminals. No TIDs will be auto-assigned.',
    after_4th_of_current_month:
        'The report for this month will be available on the 4th of the upcoming month {month}.',
    vat_invoice_is_available_form_4th:
        'The invoice for this month will be available on the 4th of the upcoming month {month}',
    daily_date_range_format: 'yyyy / mm / dd - yyyy / mm / dd',
    monthly_date_range_format: 'yyyy / mm - yyyy / mm',
    somethingWentWrong: 'Something went wrong. Please try again later.',
    managing_card_scheme: {
        card_schemes: 'Card Schemes',
        enabled_card_schemes: 'Enabled Card Schemes',
        disabled_card_schemes: 'Disabled Card Schemes',
        manage_card_schemes: 'Manage Card Schemes',
        request_to_enable: 'Request to Enable',
        request_to_disable: 'Request to Disable',
        request_submitted_message:
            'This change will take effect within 1-2 business days.',
        submit_request: 'Submit Request',
        no_action: 'No Action',
        enable_for_all: 'Enable for All',
        disable_for_all: 'Disable for All',
        enabled_by_default: 'Enabled by Default',
        request_deactivation_message:
            'Are you sure you want to request to disable',
        request_activation_message:
            'Are you sure you want to request to enable',
        request_received: 'Request Received',
        request_failed: 'Request Failed',
        failed_request_message:
            'Please try again later. If the issue persists, please contact ',
        request_received_message_title: 'Your request has been received.',
        selected_terminals_count:
            'No Terminals Selected | One Terminal Selected | {count} Terminals Selected',
        no_action_selected_help_text:
            'Please select an action before submitting the request.',
        pending_activation_review: 'Enabling {card_scheme} pending review',
        pending_deactivation_review: 'Disabling {card_scheme} pending review',
    },
};

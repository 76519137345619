const LoanDetails = () => import('../Screens/Loans/view/LoansDetails.vue');
const SupplyLoanDetails = () =>
    import('../Screens/Loans/view/SupplyLoanDetails.vue');
const LoanList = () => import('../Screens/Loans/list/LoansList.vue');
export default [
    {
        path: '/loans',
        meta: {
            requireAuth: true,
            requireApproval: true,
            activeSidebarItemId: 'loans',
        },
        children: [
            {
                path: '',
                name: 'loans',
                component: LoanList,
                meta: {
                    mixpanelPageName: 'Loans List',
                    pageTitle: 'loans_list',
                },
            },
            {
                path: ':id',
                name: 'loan-details',
                component: LoanDetails,
                props: true,
                meta: {
                    mixpanelPageName: 'Loan Details',
                    pageTitle: 'loan_details',
                },
            },
            {
                path: '/supply_loan/:id',
                name: 'supply-loan-details',
                component: SupplyLoanDetails,
                props: true,
                meta: {
                    mixpanelPageName: 'Supply Loan Details',
                    pageTitle: 'supply_loan_details',
                },
            },
        ],
    },
];

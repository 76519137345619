import { getEnumObject } from '../utilities.js';

export const transactionStatuses = {
    NEW: 1,
    PAYMENT_NEW: 2,
    PAYMENT_REQUESTED: 3,
    PAYMENT_FAILED: 4,
    SETTLED: 5,
    PAYMENT_CANCELLED: 6,
    PAYMENT_REGENERATED: 7,
    WRITE_OFF: 8,
    POTENTIAL_FRAUD_SUSPENDED: 9,
    FRAUD_SUSPENDED: 10,
    FRAUD_LOST: 11,
    DECLINED: 12,
    PENDING_CAPTURE: 13,
};

export default getEnumObject(transactionStatuses);

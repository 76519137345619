import { defineAsyncComponent } from 'vue';
import Breadcrumb from '@/common/Components/Breadcrumb.vue';
import Checkbox from '@/common/Components/Checkbox.vue';
import DatePicker from '@/common/Components/DatePicker.vue';
import MonthRange from '@/common/Components/MonthRange.vue';
import DecoratedModal from '@/common/Components/DecoratedModal.vue';
import DetailsCard from '@/common/Components/DetailsCard.vue';
import DetailsCardItem from '@/common/Components/DetailsCardItem.vue';
import DetailsCardLink from '@/common/Components/DetailsCardLink.vue';
import FormModal from '@/common/Components/FormModal.vue';
import DropDown from '@/common/Components/DropDown.vue';
import FormItem from '@/common/Components/FormItem.vue';
import FormBox from '@/common/Components/FormBox.vue';
import Modal from '@/common/Components/Modal.vue';
import Tooltip from '@/common/Components/Tooltip.vue';
import SvgIcon from '@/common/Components/SvgIcon.vue';
import ListingScreen from '@/common/Components/ListingScreen.vue';
import ScreenLoader from '@/common/Components/ScreenLoader.vue';
import SelectInput from '@/common/Components/SelectInput.vue';
import TwoColumnsLayout from './Components/TwoColumnsLayout.vue';
import FdxBadge from '@/common/Components/FdxBadge.vue';

const deferredComponents = {
    Search: defineAsyncComponent(
        () => import('@/common/Components/Search.vue')
    ),
    OtpFields: defineAsyncComponent(
        () => import('@/common/Components/OtpFields.vue')
    ),
    TableCard: defineAsyncComponent(
        () => import('@/common/Components/TableCard.vue')
    ),
    NewTooltip: defineAsyncComponent(
        () => import('@/common/Components/NewTooltip.vue')
    ),
    TitleDetails: defineAsyncComponent(
        () => import('./Components/TitleDetails.vue')
    ),
    ErrorMessages: defineAsyncComponent(
        () => import('@/common/Components/ErrorMessages.vue')
    ),
    SuccessPage: defineAsyncComponent(
        () => import('./Components/SuccessPage.vue')
    ),
    ThreeColumnsLayout: defineAsyncComponent(
        () => import('./Components/ThreeColumnsLayout.vue')
    ),
    ImportExportAction: defineAsyncComponent(
        () => import('@/common/Components/ImportExportAction.vue')
    ),
    PhoneNumber: defineAsyncComponent(
        () => import('@/common/Components/PhoneNumber.vue')
    ),
    BackNextActions: defineAsyncComponent(
        () => import('@/common/Components/BackNextActions.vue')
    ),
    SharedProgressBar: defineAsyncComponent(
        () => import('@/common/Components/SharedProgressBar.vue')
    ),
    SharedProgressBar2: defineAsyncComponent(
        () => import('@/common/Components/SharedProgressBar2.vue')
    ),
    FiltersChips: defineAsyncComponent(
        () => import('@/common/Components/FiltersChips.vue')
    ),
    ExpandableCard: defineAsyncComponent(
        () => import('@/common/Components/ExpandableCard.vue')
    ),
    QuantityCounter: defineAsyncComponent(
        () => import('@/common/Components/QuantityCounter.vue')
    ),
    HorizontalStepper: defineAsyncComponent(
        () => import('@/common/Components/HorizontalStepper.vue')
    ),
    TooltipElement: defineAsyncComponent(
        () => import('@/common/Components/TooltipElement.vue')
    ),
    FdxTabs: defineAsyncComponent(
        () => import('@/common/Components/FdxTabs.vue')
    ),
    HelpCenterLink: defineAsyncComponent(
        () => import('@/common/Components/HelpCenterLink.vue')
    ),
    FdxLink: defineAsyncComponent(
        () => import('@/common/Components/FdxLink.vue')
    ),
};

const eagerComponents = {
    ListingScreen,
    Tooltip,
    SvgIcon,
    Breadcrumb,
    Checkbox,
    DatePicker,
    MonthRange,
    FormModal,
    FormBox,
    DropDown,
    FormItem,
    Modal,
    TwoColumnsLayout,
    DecoratedModal,
    DetailsCard,
    DetailsCardLink,
    DetailsCardItem,
    ScreenLoader,
    SelectInput,
    FdxBadge,
};

export default {
    ...deferredComponents,
    ...eagerComponents,
};

export default {
    customer_insights_menu: 'Customer Insights',
    page_title: {
        customer_insights: 'Customer Insights',
    },
    customer_insights: {
        help: 'This dashboard currently captures data from payments made on Customer Display Screens. Please be aware that, due to the use of unique card identifiers, the same customer may appear as separate entries if they pay with multiple cards or use different payment methods (such as Apple Pay). We’re actively working on solutions to unify these entries and provide a more comprehensive view of your customers. Additionally, we plan to expand data capture to other payment terminals in the future for broader insights.',
        info: 'Gain deeper insights into your Customer Display Screen transactions. Track customer growth, measure loyalty, and optimize marketing strategies.',
        timeline: {
            week: 'Last 7 Days',
            month: 'Last 30 Days',
            quarter: 'Last 3 Months',
            previous: {
                week: 'previous 7 days',
                month: 'previous 30 days',
                quarter: 'previous 3 months',
            },
        },
        something_went_wrong: 'Something went wrong!',
        unexpected_error: 'Oops! Looks like an unexpected error occurred.',
        not_able_to_fetch: 'We are unable to fetch the information.',
        footfall: {
            title: 'Daily Footfall',
            info: 'This chart visualizes daily trends in customer activity, showing new and repeated customer patterns. Use this to identify the impact of specific days or promotions.',
        },
        trends: {
            increase: 'increase vs. {timeline}',
            decrease: 'decrease vs. {timeline}',
            steady: 'Steady vs. {timeline}',
        },
        stats: {
            overview: {
                title: 'Overview',
                count: 'Total Transaction Count',
                value: 'Total Transaction Value',
                customers: 'Total Customers',
            },
            breakdown: {
                title: 'Detailed Breakdown',
                segment: 'Segment',
                customers: 'Customers',
                transactions_count: 'Transactions Count',
                transactions_value: 'Transactions Value',
                transactions_avg_value: 'Average Transaction Value',
            },
        },
    },
};

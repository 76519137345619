import { getEnumObject } from '../utilities.js';

export const transactionRequestChangesTypes = {
    SETTLEABLITY_CHANGE: 1,
    KNOWN_DELAY: 2,
    SOFT_DELETE: 3,
    RESTORE_DELETE: 4,
    REVERSE: 5,
    MOVED_TO_BRANCH: 6,
    CHECKOUT_REFUND_DECLINED: 7,
    MARK_AS_FRAUD: 8,
    MARK_AS_NOT_FRAUD: 9,
};

export default getEnumObject(transactionRequestChangesTypes);

import { getEnumObject } from '../utilities.js';

export const tidStatuses = {
    UNREGISTERED: 1,
    ASSIGNED: 2,
    DAMAGED_LOST: 3,
    REUSABLE: 4,
    PREREGISTERED: 5,
};

export default getEnumObject(tidStatuses);

<script setup>
import { isEmpty } from 'lodash';
import { computed } from 'vue';

const props = defineProps({
    item: {
        type: Object,
        required: true,
        validator: (value) => {
            return !isEmpty(value.activeSidebarItemId);
        },
    },
    isActive: {
        type: Boolean,
    },
    isChild: {
        type: Boolean,
    },
});

const emit = defineEmits(['click']);

const onClick = () => {
    emit('click', props.item);
};

const padding = computed(() =>
    props.isChild ? 'py-4 ps-12 pe-2 ' : 'py-4 px-6'
);
</script>
<template>
    <router-link
        :to="item.route"
        :class="[
            'flex rounded-lg hover:font-bold will-change-[font-weight]',
            padding,
            {
                'router-link-active': isActive,
            },
        ]"
        @click.stop.prevent="onClick">
        <svg-icon v-if="item.icon" :icon="item.icon" />

        <div class="text-body inline-flex items-center gap-2">
            {{ $t(`${item.name}_menu`) }}

            <fdx-badge v-if="item.meta?.status" :color="item.meta.status.color">
                {{ $t(item.meta.status.title) }}
            </fdx-badge>
        </div>
    </router-link>
</template>

const UsersList = () => import('../Screens/Users/List/UsersList.vue');

const usersRoutes = [
    {
        path: '/users',
        meta: {
            requireAuth: true,
            requireApproval: true,
            isOwner: true,
            activeSidebarItemId: 'users',
        },
        children: [
            {
                path: '',
                name: 'users',
                component: UsersList,
                meta: {
                    mixpanelPageName: 'Users List',
                    pageTitle: 'users_list',
                },
            },
        ],
    },
];

export default usersRoutes;

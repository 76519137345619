export default Object.freeze({
    CardSchemeFee: 'CardSchemeFee',
    MerchantBankAccount: 'MerchantBankAccount',
    Merchant: 'Merchant',
    Subscription: 'Subscription',
    Adjustment: 'Adjustment',
    BankChannel: 'BankChannel',
    Payment: 'Payment',
    Transaction: 'Transaction',
    SuperMerchantBankAccount: 'SuperMerchantBankAccount',
    SuperMerchantSubscription: 'SuperMerchantSubscription',
    CardSchemeCommission: 'CardSchemeCommission',
    SuperMerchant: 'SuperMerchant',
    Terminal: 'Terminal',
});

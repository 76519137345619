const BankAccountsList = () =>
    import('../Screens/BankAccounts/BankAccountsList.vue');

export default [
    {
        path: '/bank-accounts',
        name: 'bank-accounts',
        component: BankAccountsList,
        meta: {
            activeSidebarItemId: 'bank_accounts',
            mixpanelPageName: 'Bank Accounts List',
            pageTitle: 'bank_accounts_list',
            requireAuth: true,
        },
    },
];

const VerifyNafathContainer = () =>
    import('../Screens/Auth/Verify/VerifyNafath/VerifyNafathContainer.vue');

export default [
    {
        path: '/verify/nafath',
        name: 'verify-nafath',
        component: VerifyNafathContainer,
        props: true,
        meta: {
            requireAuth: true,
            mixpanelPageName: 'Nafath Verification',
            pageTitle: 'verify_nafath',
        },
    },
];

const TerminalsList = () =>
    import('../Screens/Terminals/List/TerminalsList.vue');
const TerminalsView = () =>
    import('../Screens/Terminals/View/TerminalsView.vue');

export default [
    {
        path: '/terminals',

        meta: {
            requireAuth: true,
            requireApproval: true,
            activeSidebarItemId: 'terminals',
        },
        children: [
            {
                path: '',
                name: 'terminals',
                component: TerminalsList,
                meta: {
                    mixpanelPageName: 'Terminals List',
                    pageTitle: 'terminals_list',
                },
            },
            {
                path: ':id',
                name: 'terminal-view',
                component: TerminalsView,
                props: true,
                meta: {
                    mixpanelPageName: 'Terminal Details',
                    pageTitle: 'terminal_details',
                },
            },
        ],
    },
];
